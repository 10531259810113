import React, {FC,useState,useEffect} from 'react'
import {fetchFacebookAds} from '../../../app/helpers/remoteData'
import {FacebookCampaign,SimpleFacebookCampaign} from './FacebookWrapper'
import {Stat} from './Stat'

interface ClientFacebook {
    id: string
    secret: string
    adAccountId: string
}

type Props = {
    campaign: SimpleFacebookCampaign,
    facebook: ClientFacebook
}

const Stats: FC<Props> = ({campaign,facebook = {}}) => {
    const [items, setItems] = useState<FacebookCampaign[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchItems = async () => {
            if (campaign.id !== '' && facebook.id && facebook.secret) {
                setIsLoading(true)
                const _items = await fetchFacebookAds(facebook.id, facebook.secret, campaign.id)
                console.log('Items', _items.rows)
                setItems(_items.rows)

                setIsLoading(false)
            }
        }
        fetchItems()
    }, [campaign.id, facebook.id, facebook.secret])

    return (
        <div className='row gy-5 g-xl-8'>
            {
                isLoading ? 
                <div className="text-center pt-5">
                    <p>Facebook Ads are loading...</p>
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <>
                    {
                        items.length > 0 ?
                            <>
                                {
                                    items.map((x, i) => <Stat key={`stat-${i}`} data={x} />)
                                }
                            </>
                        :
                        <div className="text-center pt-5">
                            <p>Facebook Campaigns are loading...</p>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export {Stats}
