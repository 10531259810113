import { useState, useEffect, useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getInstagramSummaryPaid } from '../../helpers/remoteData'
import { ClientContext } from '../../providers/ClientProvider'

function InstagramSummaryPaidWidget({ accountId, accessToken, pageUrl, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const { client } = useContext(ClientContext)

  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<Array<any>>([])

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true)

      try {
        const _rows = await getInstagramSummaryPaid(accountId, accessToken)

        if (_rows) {
          setRows(_rows.data)
        }

        setLoading(false)
      }
      catch (e) {
        console.log('Meta error')
        
        setLoading(false)
      }
    }

    _fetch()
  }, [accountId, accessToken])

  return (
    <div className='col-12 col-md-6 col-xl-4 mb-8 d-inline-block'>
      <div className='card shadow h-100'>
        {
          isAdmin && (
            <div className='card-header justify-content-end'>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveUp(el)}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveDown(el)}
              >
                <ArrowDownwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                data-bs-toggle='modal'
                data-bs-target='#modal_delete_widget'
                onClick={() => setSelectedWidget(el)}
              >
                <DeleteIcon />
              </button>
            </div>
          )
        }
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <div className='card-body'>
              <h4 className='d-flex align-items-center mb-0 fw-boldest'>
                <span className='me-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.045 16.5899C9.525 16.5899 7.47 14.5349 7.47 12.0149C7.47 9.49494 9.525 7.43994 12.045 7.43994C14.565 7.43994 16.62 9.49494 16.62 12.0149C16.62 14.5349 14.565 16.5899 12.045 16.5899ZM12.045 8.80494C10.275 8.80494 8.82 10.2449 8.82 12.0299C8.82 13.7999 10.26 15.2549 12.045 15.2549C13.815 15.2549 15.27 13.8149 15.27 12.0299C15.27 10.2449 13.815 8.80494 12.045 8.80494Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M16.335 21.6598H7.75499C4.79999 21.6598 2.39999 19.2598 2.39999 16.3048V7.73976C2.39999 4.78477 4.79999 2.38477 7.75499 2.38477H16.32C19.275 2.38477 21.675 4.78477 21.675 7.73976V16.3048C21.675 19.2598 19.275 21.6598 16.335 21.6598ZM7.75499 3.73477C5.54999 3.73477 3.74999 5.53477 3.74999 7.73976V16.3048C3.74999 18.5098 5.54999 20.3098 7.75499 20.3098H16.32C18.525 20.3098 20.325 18.5098 20.325 16.3048V7.73976C20.325 5.53477 18.525 3.73477 16.32 3.73477H7.75499Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M17.1 7.93486C17.6385 7.93486 18.075 7.49834 18.075 6.95986C18.075 6.42139 17.6385 5.98486 17.1 5.98486C16.5615 5.98486 16.125 6.42139 16.125 6.95986C16.125 7.49834 16.5615 7.93486 17.1 7.93486Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                  </svg>
                </span>
                {
                  pageUrl ? 
                    <Link to={pageUrl} className='text-dark'>Latest Instagram Ads</Link>
                  :
                    <span>Latest Instagram Ads</span>
                }
              </h4>

              <div>
                {
                  rows.map((r, i) => {
                    return (
                      <div key={`instagram-summary-${i}`} className='mt-8 d-flex align-items-center'>
                        <img src={r.ads.data[0].adcreatives.data[0].thumbnail_url} alt='Thumbnail' className='rounded' height={48} width={48} />
                        <div className='ms-4'>
                          <div className='mb-1'>
                            <span className='badge text-white' style={r.status === 'ACTIVE' ? { backgroundColor: '#009ef7' } : { backgroundColor: '#838383' }}>{r.status.toLowerCase().replace(/(^| )(\w)/g, (x) => x.toUpperCase())}</span>
                            <span className='fw-boldest ms-3'>{r?.stop_time && r?.start_time && moment(r.stop_time).isBefore(moment()) ? moment(r.stop_time).format('MM/DD/YY') : `${moment(r.stop_time).diff(moment(r.start_time), 'days')} days`}</span>
                          </div>
                          <div className='fs-0 fw-bold'>
                            {r?.objective && r?.objective.replace('_', ' ').toLowerCase().replace(/(^| )(\w)/g, (x) => x.toUpperCase())}
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default InstagramSummaryPaidWidget
