import { FC, useEffect, useRef, useState, useContext } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { uploadImage, createClient, fetchMemberships } from '../../helpers/remoteData'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../auth/models/UserModel'
import { ClientContext } from '../../providers/ClientProvider'

type Props = {
  fetchClients: () => void
  setShowClients: (sc: boolean) => void
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Name is required'),
  id: Yup.string()
    .matches(/^[a-z]+$/, 'Must be lowercase')
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(2, 'Minimum 2 characters')
    .max(50, 'Maximum 50 characters')
    .required('ID is required'),
  defaultColor: Yup.string()
    .min(7, 'Minimum 7 characters')
    .max(50, 'Maximum 50 characters')
    .required('Default Color is required'),
  logo: Yup.mixed()
    .required('Logo is required'),
})

const initialValues = {
  name: '',
  id: '',
  defaultColor: '',
  logo: '',
}

const AddClientWizard: FC<Props> = ({ fetchClients, setShowClients }) => {
  // const [step, setStep] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const { setClients: contextSetClients } = useContext(ClientContext)

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  // const prevStep = () => {
  //   if (!stepper.current) {
  //     return
  //   }

  //   stepper.current.goPrev()
  // }

  // const submitStep = () => {
  //   if (!stepper.current) {
  //     return
  //   }

  //   if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
  //     stepper.current.goNext()
  //     setStep(stepper.current.currentStepIndex)
  //   } else {
  //     console.log('Cliend Data', newClient)
  //     _createClient()
  //   }
  // }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        setLoading(true)

        const imageData = await uploadImage(values.logo, (_progress) => setProgress(_progress))

        let _newClient = {
          name: values.name,
          id: values.id,
          defaultColor: values.defaultColor,
          logoUrl: imageData.data,
        }

        await createClient(user.email, values.id, _newClient)
        fetchClients()

        const memberships: any = await fetchMemberships(user.email)

        if (memberships.clients.length > 0) {
          contextSetClients(memberships.clients)
        }

        resetForm()
        toast.success('Client created!')
        setLoading(false)
        setShowClients(true)
      }
      catch (e) {
        setStatus('Unknown Error')
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-4'
      id='kt_create_account_stepper'
    >
      <div className='d-flex bg-white rounded justify-content-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        <div className='px-6 px-lg-10 px-xxl-15 py-4'>
          <h2 className='fw-bolder d-flex align-items-center text-dark mb-8'>
            Requirements
          </h2>

          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-line w-40px'></div>

              <div className='stepper-icon w-40px h-40px'>
                <i className='stepper-check fas fa-check'></i>
                <span className='stepper-number'>1</span>
              </div>

              <div className='stepper-label'>
                <h3 className='stepper-title text-dark'>Basic Details</h3>
                <div className='stepper-desc fw-bold'>Information required to create a client.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
        <form
          className='form w-100 px-5'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='w-100 p-4 pb-7 pl-7 pe-7'>
            {formik.status && (
              <div className='mb-lg-8 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='current' data-kt-stepper-element='content'>
              <div className='w-100'>
                <div className='pb-8'>
                  <h2 className='fw-bolder d-flex align-items-center text-dark mb-3'>
                    Basic Info
                  </h2>
                  <div className='text-gray-400 fw-bold fs-6'>
                    Give the new client a name, identifier, and logo.
                  </div>
                </div>

                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                    <span className='required'>Name</span>
                  </label>
                  <input
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                      { 'is-valid': formik.touched.name && !formik.errors.name },
                    )}
                    name='name'
                    autoComplete='off'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container mt-1'>
                      <span role='alert' className='text-danger'>{formik.errors.name}</span>
                    </div>
                  )}
                </div>

                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                    <span className='required'>Identifier</span>
                  </label>
                  <input
                    {...formik.getFieldProps('id')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': formik.touched.id && formik.errors.id },
                      { 'is-valid': formik.touched.id && !formik.errors.id },
                    )}
                    name='id'
                    autoComplete='off'
                  />
                  {formik.touched.id && formik.errors.id && (
                    <div className='fv-plugins-message-container mt-1'>
                      <span role='alert' className='text-danger'>{formik.errors.id}</span>
                    </div>
                  )}
                </div>

                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                    <span className='required'>Default Color</span>
                  </label>
                  <input
                    {...formik.getFieldProps('defaultColor')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': formik.touched.defaultColor && formik.errors.defaultColor },
                      { 'is-valid': formik.touched.defaultColor && !formik.errors.defaultColor },
                    )}
                    name='defaultColor'
                    autoComplete='off'
                  />
                  {formik.touched.defaultColor && formik.errors.defaultColor && (
                    <div className='fv-plugins-message-container mt-1'>
                      <span role='alert' className='text-danger'>{formik.errors.defaultColor}</span>
                    </div>
                  )}
                </div>

                <div className='mb-10 fv-row'>
                  <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                    <span className='required'>Logo</span>
                  </label>
                  <div className='d-flex flex-wrap mt-5' style={{height: '60px'}}>
                    <div className='logo-input-wrapper border-0' style={{height: '60px', width: '210px'}}>
                      {
                        formik.values.logo !== '' ? 
                          <img src={URL.createObjectURL(formik.values.logo as unknown as Blob)} alt='New Logo' className='h-100 w-100 rounded' />
                        :
                          <span className='d-inline-block bg-secondary h-100 w-100 rounded' style={{verticalAlign: 'middle'}}></span>
                      }
                    </div>
                    <div className='ms-8 d-flex flex-wrap align-items-center h-100'>
                      {
                        formik.isSubmitting ?
                          <div className='progress h-8px bg-secondary w-100px'>
                            <div
                              className='progress-bar bg-primary'
                              role='progressbar'
                              aria-valuemin={0}
                              aria-valuemax={100}
                              aria-valuenow={progress}
                              style={{width: `${progress}%`}}
                            >
                            </div>
                          </div>
                        :
                          <>
                            <label htmlFor='logo-input' className='btn btn-secondary btn-sm'>Choose File</label>
                            <input
                              type='file'
                              className={clsx(
                                'form-control form-control-lg form-control-solid d-none',
                                { 'is-invalid': formik.touched.logo && formik.errors.logo },
                                { 'is-valid': formik.touched.logo && !formik.errors.logo },
                              )}
                              id='logo-input'
                              name='logo'
                              onChange={(e) => {
                                formik.handleChange(e)

                                if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                                  formik.setFieldValue('logo', e.currentTarget.files[0])
                                }
                              }}
                            />
                          </>
                      }
                    </div>
                  </div>
                  {formik.touched.logo && formik.errors.logo && (
                    <div className='fv-plugins-message-container mt-1'>
                      <span role='alert' className='text-danger'>{formik.errors.logo}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end flex-stack pt-10'>
              <div>
                <button
                  type='submit'
                  className='btn btn-primary px-10'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export { AddClientWizard }
