import { useState, useEffect, useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getInstagramSummaryOrganic } from '../../helpers/remoteData'
import { ClientContext } from '../../providers/ClientProvider'

function InstagramSummaryOrganicWidget({ accountId, accessToken, pageUrl, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const { client } = useContext(ClientContext)

  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<Array<any>>([])

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true)

      try {
        const _rows = await getInstagramSummaryOrganic(accountId, accessToken)

        if (_rows) {
          setRows(_rows.data)
        }

        setLoading(false)
      }
      catch (e) {
        console.log('Meta error')
        
        setLoading(false)
      }
    }

    _fetch()
  }, [accountId, accessToken])

  return (
    <div className='col-12 col-md-6 col-xl-4 mb-8 d-inline-block'>
      <div className='card shadow h-100'>
        {
          isAdmin && (
            <div className='card-header justify-content-end'>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveUp(el)}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveDown(el)}
              >
                <ArrowDownwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                data-bs-toggle='modal'
                data-bs-target='#modal_delete_widget'
                onClick={() => setSelectedWidget(el)}
              >
                <DeleteIcon />
              </button>
            </div>
          )
        }
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <div className='card-body'>
              <h4 className='d-flex align-items-center mb-0 fw-boldest'>
                <span className='me-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.045 16.5899C9.525 16.5899 7.47 14.5349 7.47 12.0149C7.47 9.49494 9.525 7.43994 12.045 7.43994C14.565 7.43994 16.62 9.49494 16.62 12.0149C16.62 14.5349 14.565 16.5899 12.045 16.5899ZM12.045 8.80494C10.275 8.80494 8.82 10.2449 8.82 12.0299C8.82 13.7999 10.26 15.2549 12.045 15.2549C13.815 15.2549 15.27 13.8149 15.27 12.0299C15.27 10.2449 13.815 8.80494 12.045 8.80494Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M16.335 21.6598H7.75499C4.79999 21.6598 2.39999 19.2598 2.39999 16.3048V7.73976C2.39999 4.78477 4.79999 2.38477 7.75499 2.38477H16.32C19.275 2.38477 21.675 4.78477 21.675 7.73976V16.3048C21.675 19.2598 19.275 21.6598 16.335 21.6598ZM7.75499 3.73477C5.54999 3.73477 3.74999 5.53477 3.74999 7.73976V16.3048C3.74999 18.5098 5.54999 20.3098 7.75499 20.3098H16.32C18.525 20.3098 20.325 18.5098 20.325 16.3048V7.73976C20.325 5.53477 18.525 3.73477 16.32 3.73477H7.75499Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M17.1 7.93486C17.6385 7.93486 18.075 7.49834 18.075 6.95986C18.075 6.42139 17.6385 5.98486 17.1 5.98486C16.5615 5.98486 16.125 6.42139 16.125 6.95986C16.125 7.49834 16.5615 7.93486 17.1 7.93486Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                  </svg>
                </span>
                {
                  pageUrl ? 
                    <Link to={pageUrl} className='text-dark'>Latest Instagram Posts</Link>
                  :
                    <span>Latest Instagram Posts</span>
                }
              </h4>

              <div>
                {
                  rows.map((r, i) => {
                    const sharesIndex = r?.insights && r.insights?.data && r.insights.data.length > 0 ? r.insights.data.findIndex((i) => i.name === 'shares') : -1
                    
                    return (
                      <div key={`facebook-summary-organic-${i}`} className='mt-8 d-flex align-items-center'>
                        {
                          r?.media_url && (
                            <img src={r.media_url} alt='Thumbnail' className='rounded' height={48} width={48} />
                          )
                        }
                        <div className='ms-4'>
                          <div className='mb-1'>
                            <span className='fw-boldest'>{r?.timestamp && moment(r.timestamp).format('MM/DD/YY')}</span>
                          </div>
                          <div className='fs-0 fw-bold'>
                            <span className='d-inline-flex align-items-center me-5'>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M11.6121 11.3939C11.7818 11.5878 11.8788 11.8302 11.8788 12.0969C11.8788 12.5333 11.5879 12.9212 11.2 13.0666C11.3212 13.2363 11.3939 13.4545 11.3939 13.6727C11.3939 14.2545 10.9333 14.7151 10.3515 14.7151H6.25455C6.25455 14.7151 6.23031 14.7151 6.20607 14.7151C4.12122 14.3999 4.02425 14.3757 3.80607 14.3999V9.11509C3.80607 9.01812 3.78183 8.92115 3.75758 8.82418C4.58183 7.95145 4.65455 7.1757 5.91516 6.78782C6.37576 6.64236 6.76364 6.35145 7.03031 5.96357L8.19395 4.31509C8.26667 4.21812 8.38789 4.19388 8.48486 4.24236C9.50304 4.70297 9.86667 5.96357 9.23637 6.90903L8.60607 7.87873H10.6182C11.1758 7.87873 11.6606 8.31509 11.6849 8.84842C11.7091 9.11509 11.6121 9.35751 11.4667 9.55145C11.8788 9.69691 12.1212 10.0605 12.1212 10.4969C12.1212 10.8848 11.903 11.1999 11.6121 11.3939ZM15.5394 3.68479L14.4485 4.48479L14.8606 5.79388C14.9333 6.01206 14.8606 6.23024 14.6667 6.35145C14.497 6.47267 14.2546 6.47267 14.0849 6.35145L12.9939 5.55145L11.8788 6.35145C11.7091 6.47267 11.4667 6.49691 11.297 6.35145C11.1273 6.23024 11.0546 6.01206 11.103 5.79388L11.5152 4.50903L10.4242 3.68479C10.2546 3.56357 10.1818 3.34539 10.2303 3.12721C10.303 2.90903 10.4727 2.78782 10.7152 2.78782H12.0727L12.5091 1.50297C12.5818 1.28479 12.7758 1.16357 12.9939 1.16357C13.2121 1.16357 13.4061 1.30903 13.4788 1.50297L13.8909 2.78782H15.2485C15.4667 2.78782 15.6606 2.93327 15.7333 3.12721C15.8061 3.34539 15.7333 3.56357 15.5394 3.68479ZM3.32122 9.11509V14.6181C3.32122 14.7393 3.20001 14.8605 3.0788 14.8605H0.484856C0.339401 14.8605 0.242432 14.7393 0.242432 14.6181V9.11509C0.242432 8.96964 0.339401 8.87267 0.484856 8.87267H3.10304C3.22425 8.87267 3.32122 8.96964 3.32122 9.11509Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                                </svg>
                              </span>
                              <span className='ms-2'>{r?.like_count}</span>
                            </span>
                            <span className='d-inline-flex align-items-center me-5'>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path d="M13.1334 3.80021C10.8 0.933544 6.60004 0.533544 3.80004 2.86688C0.933374 5.20021 0.533374 9.40021 2.86671 12.2669L1.53337 13.6002C1.40004 13.6669 1.33337 13.8002 1.33337 14.0002C1.33337 14.4002 1.60004 14.6669 2.00004 14.6669H8.00004C9.53337 14.6669 11 14.1335 12.2 13.1335C15.0667 10.8002 15.4667 6.60021 13.1334 3.80021Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                                </svg>
                              </span>
                              <span className='ms-2'>{r?.comments_count}</span>
                            </span>
                            <span className='d-inline-flex align-items-center'>
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <g clipPath="url(#clip0_1503_225)">
                                    <path d="M14.9865 0.524612C14.923 0.526084 14.8604 0.53966 14.802 0.564612L1.05002 6.45911C0.992214 6.48367 0.939485 6.51877 0.894516 6.56261C0.641516 6.81561 0.525016 7.12311 0.503016 7.45611C0.492516 7.62311 0.507016 7.80261 0.606516 7.99111C0.706016 8.17961 0.929516 8.35461 1.16402 8.39361L6.12302 9.21761C7.56702 7.89411 10.582 5.40111 10.582 5.40111C10.582 5.40111 7.92952 8.26161 6.78302 9.76561L7.63002 14.8596C7.64738 14.9618 7.69614 15.056 7.76952 15.1291C7.99405 15.3511 8.29704 15.4755 8.61277 15.4755C8.92849 15.4755 9.23148 15.3511 9.45602 15.1291C9.50166 15.0848 9.53818 15.032 9.56352 14.9736L15.4585 1.22161C15.4918 1.1446 15.5052 1.06043 15.4974 0.976876C15.4896 0.893326 15.4608 0.813099 15.4137 0.743616C15.3667 0.674134 15.3029 0.617639 15.2282 0.579359C15.1535 0.541079 15.0704 0.522249 14.9865 0.524612Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                                  </g>
                                  <defs>
                                  <clipPath id="clip0_1503_225">
                                    <rect width="16" height="16" fill="white"/>
                                  </clipPath>
                                  </defs>
                                </svg>
                              </span>
                              <span className='ms-2'>{sharesIndex > -1 ? r.insights.data[sharesIndex].values[0].value : 0}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default InstagramSummaryOrganicWidget
