import { FC, useEffect, useState, useRef, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import { fetchPage, updatePage } from '../../helpers/remoteData'

type Props = {
  id: string
  name: string
  handleSubmit: () => Promise<void>
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Name is required'),
})

const EditPageModel: FC<Props> = ({ id, name, handleSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [page, setPage] = useState<any | null>(null)
  const [initialValues, setInitialValues] = useState<any>({
    name,
  })

  const closeRef = useRef<HTMLDivElement>(null)

  const _fetchPage = useCallback(async () => {
    setPageLoading(true)

    if (id !== '') {
      const _page: any = await fetchPage(id)
    
      if (_page) {
        setPage(_page)

        setInitialValues({
          name: _page.name,
        })

        setPageLoading(false)
      }
    }
  }, [id])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true)

        if (page?.id) {
          let _newPage = {
            name: values.name,
          }

          await updatePage(page.id, _newPage)

          await handleSubmit()

          setLoading(false)
          toast.success('Page updated')
          await _fetchPage()
          closeRef.current?.click()
        }
      }
      catch (e) {
        setStatus('Unknown error')
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    _fetchPage()
  }, [_fetchPage])

  return (
    <div className='modal fade' id='modal_edit_page' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          {
            pageLoading ? 
              <div className='modal-body scroll-y py-8 text-center'>
                <div className='spinner-border spinner-border-sm' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            :
              <form
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div className='modal-header'>
                  <h2>Edit Page</h2>
                  <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body scroll-y py-8'>
                  <div className='form-group mb-8'>
                    <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                      Identifier
                    </label>
                    <div>
                      {page?.id}
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                      <span className='required'>Name</span>
                    </label>
                    <input
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.name && formik.errors.name },
                        { 'is-valid': formik.touched.name && !formik.errors.name },
                      )}
                      name='name'
                      autoComplete='off'
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container mt-1'>
                        <span role='alert' className='text-danger'>{formik.errors.name}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='modal-footer'>
                  <button
                    type='submit'
                    className='btn btn-primary px-10'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
          }
        </div>
      </div>
    </div>
  )
}

export {EditPageModel}
