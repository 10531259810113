import React, {FC, useContext,useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Stats} from './Stats'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../modules/auth/models/UserModel'
import {RootState} from '../../../setup'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {SimpleFacebookCampaign} from '../facebook/FacebookWrapper'
import {Dropdown1} from '../../../_metronic/partials'
import {KTSVG} from '../../../_metronic/helpers'

export interface MailCampaign {
    name?: string
    campaign?: string
    target?: string
    householdCount?: number
    dropDate?: string
    art?: string[]
    cost?: number
    id?: string
    doc?: any
    overallDate?: string
    notes?: string
    isArchived?: boolean
    createdAt?: number
    updatedAt?: number
}

const MailWrapper: FC = () => {
    const intl = useIntl()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const { setCurrentMail } = useContext(RemoteDataContext)
    const [archived, setArchived] = useState<boolean>(false)
    const [archivedObj, setArchivedObj] = useState<SimpleFacebookCampaign>({
        id: 'Active',
        name: 'Active'
    })

    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className="col-12 my-8">
                    <div className="card shadow">
                        <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="m-0 fs-3">
                                Mail
                            </h4>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-icon btn-color-primary btn-active-light-primary w-initial p-3 px-4 me-5'
                                    style={{width: 'initial'}}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 me-3' />
                                    {archivedObj.name}
                                </button>
                                <Dropdown1 campaigns={[
                                    {
                                        id: 'Active',
                                        name: 'Active'
                                    },
                                    {
                                        id: 'Archived',
                                        name: 'Archived'
                                    }
                                ]} loading={false} setCampaign={(obj) => {
                                    setArchivedObj(obj)
                                    setArchived(obj.name === 'Archived' ? true : false)
                                }} campaign={archivedObj} />
                                {
                                    user.isAdmin ?
                                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_mail_campaign" onClick={() => {
                                        setCurrentMail({})
                                    }}>
                                        Upload Mail Data
                                    </button>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MAIL'})}</PageTitle>
            <Stats archived={archived} />
        </>
    )
}

export {MailWrapper}
