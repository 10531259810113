import React, {FC,useContext} from 'react'
import {BroadcastCampaign} from './BroadcastWrapper'
import {KTSVG} from '../../../_metronic/helpers'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../modules/auth/models/UserModel'
import {RootState} from '../../../setup'
import {ClientContext} from '../../providers/ClientProvider'
import {updateProject} from '../../helpers/remoteData'

type Props = {
    data?: BroadcastCampaign
}

const Stat: FC<Props> = ({data}) => {
    const { client } = useContext(ClientContext)
    const { setCurrentBroadcast, broadcasts, setBroadcasts } = useContext(RemoteDataContext)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const sendToFront = async () => {
        if (client.broadcast && data?.id) {
            const index = broadcasts.map((d) => d.id).indexOf(data.id)
            await updateProject(client.broadcast, data.id, {})
            let _cables = Array.from(broadcasts)
            _cables[index] = {
                ..._cables[index],
                updatedAt: new Date().getTime()/1000
            }
            _cables.sort((a, b) => {
                return a.updatedAt && b.updatedAt ? b.updatedAt - a.updatedAt : 0
            })
            console.log('Sorted Cables', _cables.map((d) => d.media))
            setBroadcasts(_cables)
        }
    }

    return (
        <div className="col-xl-4">
            <div className="card card-xl-stretch mb-xl-8 shadow">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded w-100 bg-primary">
                        {
                            user.isAdmin ? 
                            <div className="text-right mb-3" style={{textAlign: 'right'}}>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    onClick={() => {
                                        sendToFront()
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/abstract/abs027.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_new_broadcast_campaign"
                                    onClick={() => {
                                        setCurrentBroadcast(data || {
                                            name: '',
                                            grandparent: '',
                                            cost: 0,
                                            spots: 0
                                        })
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen055.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_delete_broadcast_campaign"
                                    onClick={() => {
                                        setCurrentBroadcast(data || {
                                            name: '',
                                            grandparent: '',
                                            cost: 0,
                                            spots: 0
                                        })
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                            </div>
                            : null
                        }

                        <div className="d-flex flex-stack mb-4">
                            <h3 className="m-0 text-white fw-bolder fs-3">{data?.name}</h3>
                        </div>
                        
                        <div style={{paddingBottom: '130px'}}>
                            <div className="text-center h-250px d-flex align-items-center justify-content-center">
                                {
                                    data?.media && data.media.length > 0 ? 
                                        data.media[0].includes('.mp4') ? 
                                        <video key={`cable-media-${data.id}`} height="250" controls style={{maxWidth: '100%'}}>
                                            <source src={data?.media[0]} type="video/mp4" />
                                        </video>
                                        :
                                        <img alt="Project Media" src={data?.media[0]} style={{maxHeight: '250px', maxWidth: '100%'}} />
                                    : null
                                }
                            </div>

                            <div>
                                {
                                    data?.media && data.media.length > 1 ? 
                                    <p data-bs-toggle="modal" data-bs-target="#kt_modal_cable_gallery" className="m-0 mt-6 text-white text-hover-secondary text-center fw-bold" style={{cursor: 'pointer'}} onClick={() => { setCurrentBroadcast(data || {}) }}>Show {data.media.length - 1} More</p>
                                    : null
                                }
                            </div>
                        </div>
                        
                        {/* <div className="d-flex text-left flex-column text-white pt-9 px-6" style={{paddingBottom: '130px'}}>
                            <span className="fw-bolder fs-5">Air Dates</span>
                            <span className="fs-5 pt-1">{data?.date_string}</span>
                        </div> */}
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{marginTop: '-100px'}}>
                        <div className="d-flex align-items-center mb-6">
                            <div className="w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Spots</p>
                                </div>
                                <div className="d-flex align-items-center">
                                <p className="fs-6 text-gray-800 pe-1 m-0">{data?.spots}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-6">
                            <div className="w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Amount</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fs-6 text-gray-800 pe-1 m-0">{data?.cost ? data?.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Market</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fs-6 text-gray-800 pe-1 m-0">{data?.grandparent}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}

export {Stat}
