import { useState, useEffect, useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import { getTopThreeFromWidgetRef } from '../../helpers/remoteData'
import { ClientContext } from '../../providers/ClientProvider'
import AttachmentsModal from './components/AttachmentsModal'

function SmartsheetSummaryTVWidget({ reference, pageUrl, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const { client } = useContext(ClientContext)

  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<Array<any>>([])
  const [attachments, setAttachments] = useState<Array<any>>([])

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true)

      try {
        const _rows = await getTopThreeFromWidgetRef(reference, 'startDate')

        if (_rows) {
          setRows(_rows)
        }

        setLoading(false)
      }
      catch (e) {
        console.log('TV Error')
        
        setRows([])
        setLoading(false)
      }
    }

    _fetch()
  }, [reference])

  return (
    <div className='col-12 col-md-6 col-xl-4 mb-8 d-inline-block'>
      <div className='card shadow h-100'>
        {
          isAdmin && (
            <div className='card-header justify-content-end'>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveUp(el)}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveDown(el)}
              >
                <ArrowDownwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                data-bs-toggle='modal'
                data-bs-target='#modal_delete_widget'
                onClick={() => setSelectedWidget(el)}
              >
                <DeleteIcon />
              </button>
            </div>
          )
        }
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <div className='card-body'>
              <h4 className='d-flex align-items-center mb-0 fw-boldest'>
                <span className='me-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20.0013 6.15125H14.6652C14.5029 5.32625 13.9659 4.63565 13.2396 4.26125L14.0494 2.56625C14.1744 2.30465 14.0637 1.9913 13.8021 1.8662C13.5412 1.74155 13.227 1.8518 13.102 2.11355L12.2151 3.96995C12.144 3.9644 12.0727 3.959 12.0001 3.959C11.9275 3.959 11.8563 3.96425 11.7852 3.96995L10.3915 1.05365C10.2664 0.791902 9.95248 0.681352 9.69148 0.806302C9.42988 0.931252 9.31918 1.24475 9.44413 1.50635L10.7604 4.2614C10.0341 4.6358 9.49708 5.3264 9.33478 6.1514H3.99883C3.08653 6.1514 2.34448 6.8936 2.34448 7.80575V19.1878C2.34448 20.1001 3.08653 20.8421 3.99883 20.8421H4.33048V22.7203C4.33048 23.0102 4.56553 23.2453 4.85548 23.2453H6.12058C6.27958 23.2453 6.43003 23.1733 6.52963 23.0494L8.30593 20.8421H15.6943L17.4706 23.0494C17.5702 23.1731 17.7207 23.2453 17.8797 23.2453H19.1448C19.4347 23.2453 19.6698 23.0102 19.6698 22.7203V20.8421H20.0016C20.9137 20.8421 21.6558 20.1001 21.6558 19.1878V7.80575C21.6555 6.89345 20.9134 6.15125 20.0013 6.15125ZM12 5.00915C12.7357 5.00915 13.3605 5.4887 13.5811 6.15125H10.4188C10.6395 5.4887 11.2642 5.00915 12 5.00915ZM5.86918 22.1952H5.38033V20.8421H6.95803L5.86918 22.1952ZM18.6195 22.1952H18.1308L17.0419 20.8421H18.6195V22.1952ZM20.6055 19.1878C20.6055 19.5211 20.3344 19.7921 20.0013 19.7921H19.1445H15.9456H8.05438H4.85548H3.99883C3.66553 19.7921 3.39448 19.5209 3.39448 19.1878V7.80575C3.39448 7.47245 3.66553 7.2014 3.99883 7.2014H20.0013C20.3346 7.2014 20.6055 7.4726 20.6055 7.80575V19.1878Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M14.4902 8.06299H5.65629C4.92519 8.06299 4.33044 8.65774 4.33044 9.38884V17.6043C4.33044 18.3354 4.92519 18.9302 5.65629 18.9302H14.4902C15.2213 18.9302 15.8162 18.3354 15.8162 17.6043V9.38884C15.8161 8.65774 15.2213 8.06299 14.4902 8.06299ZM14.7661 17.6042C14.7661 17.7563 14.6423 17.88 14.4901 17.88H5.65629C5.50404 17.88 5.38044 17.7563 5.38044 17.6042V9.38869C5.38044 9.23659 5.50404 9.11284 5.65629 9.11284H14.4902C14.6425 9.11284 14.7662 9.23659 14.7662 9.38869L14.7661 17.6042ZM18.2107 10.8836C18.8989 10.8836 19.4587 10.3238 19.4587 9.63559C19.4587 8.94739 18.8989 8.38759 18.2107 8.38759C17.5225 8.38759 16.9627 8.94739 16.9627 9.63559C16.9627 10.3238 17.5225 10.8836 18.2107 10.8836ZM18.2107 9.13744C18.4853 9.13744 18.7087 9.36079 18.7087 9.63544C18.7087 9.91009 18.4853 10.1334 18.2107 10.1334C17.936 10.1334 17.7127 9.91009 17.7127 9.63544C17.7127 9.36079 17.936 9.13744 18.2107 9.13744ZM18.2107 14.1698C18.8989 14.1698 19.4587 13.61 19.4587 12.9218C19.4587 12.2337 18.8989 11.6738 18.2107 11.6738C17.5225 11.6738 16.9627 12.2336 16.9627 12.9218C16.9627 13.6098 17.5225 14.1698 18.2107 14.1698ZM18.2107 12.4238C18.4853 12.4238 18.7087 12.6471 18.7087 12.9218C18.7087 13.1964 18.4853 13.4198 18.2107 13.4198C17.936 13.4198 17.7127 13.1964 17.7127 12.9218C17.7127 12.6471 17.936 12.4238 18.2107 12.4238ZM19.2583 16.049H17.1632C16.9561 16.049 16.7882 16.2168 16.7882 16.424C16.7882 16.6311 16.9561 16.799 17.1632 16.799H19.2583C19.4654 16.799 19.6333 16.6311 19.6333 16.424C19.6333 16.2168 19.4654 16.049 19.2583 16.049ZM19.2583 17.916H17.1632C16.9561 17.916 16.7882 18.0839 16.7882 18.291C16.7882 18.4982 16.9561 18.666 17.1632 18.666H19.2583C19.4654 18.666 19.6333 18.4982 19.6333 18.291C19.6333 18.0839 19.4654 17.916 19.2583 17.916Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                  </svg>
                </span>
                {
                  pageUrl ? 
                    <Link to={pageUrl} className='text-dark'>Latest Broadcasts</Link>
                  :
                    <span>Latest Broadcasts</span>
                }
              </h4>

              <div>
                {
                  rows.length > 0 ?
                    rows.map((r, i) => {
                      return (
                        <div key={`smartsheet-summary-tv-${i}`} className='mt-8 d-flex align-items-center'>
                          <div className='ms-4'>
                            <div className='mb-1'>
                              <span
                                className='fw-boldest cursor-pointer text-hover-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#modal_smartsheet_tv_summary_attachments'
                                onClick={() => {
                                  setAttachments(r?.attachments)
                                }}
                              >
                                {r?.name}
                              </span>
                            </div>
                            <div className='fs-0 fw-bold'>
                              {r?.jobCode && r?.jobCode.split('_')[0]}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  :
                    <div className='fw-bold mt-8'>No recent campaigns.</div>
                }
              </div>
            </div>
        }
      </div>

      <AttachmentsModal widget='smartsheet_tv_summary' attachments={attachments} />
    </div>
  )
}

export default SmartsheetSummaryTVWidget
