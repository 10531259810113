import { FirebaseUserModel } from '../../app/modules/auth/models/UserModel'

export function firebaseUserMapper(doc: any): FirebaseUserModel {
  const { uid, displayName, email, emailVerified, phoneNumber, accessToken } = doc
  return {
    uid,
    displayName,
    email,
    emailVerified,
    phoneNumber,
    accessToken
  }
}
