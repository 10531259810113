import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Carousel } from 'react-responsive-carousel'
import { Document, Page, pdfjs } from 'react-pdf'
import ReactPlayer from 'react-player/file'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

interface AttachmentsMetadata {
  [key: string]: AttachmentMetadata
}

interface AttachmentMetadata {
  numPages: number
  pageNumber: number
}

function AttachmentsModal({ widget, attachments }) {
  const [attachmentsMetadata, setAttachmentsMetadata] = useState<AttachmentsMetadata>({})

  return (
    <div className='modal fade' id={`modal_${widget}_attachments`} aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
            <div className='modal-header'>
              <h2>View Attachments</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <CloseIcon />
              </div>
            </div>

            <div className='modal-body py-8'>
              {
                attachments.length > 0 ? 
                  <Carousel showArrows={true} showIndicators={false} showThumbs={false}>
                    {
                      attachments.map((a, i) => {
                        const fileExtension = a.split('.').pop()

                        if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
                          return (
                            <div key={`attachment-${i}`} className='h-100 d-flex align-items-center justify-content-center px-6'>
                              <img src={a} alt={`Carousel ${i + 1}`} className='w-auto' style={{maxHeight: '100%', maxWidth: '100%'}} />
                            </div>
                          )
                        }
                        else if (fileExtension === 'mp4' || fileExtension === 'mov') {
                          return (
                            <div key={`attachment-${i}`} className='h-100 d-flex align-items-center justify-content-center px-6'>
                              <ReactPlayer url={a} controls={true} />
                            </div>
                          )
                        }
                        else if (fileExtension === 'pdf') {
                          const key = `attachment-${i}`

                          return (
                            <div key={key} className='h-100 d-flex align-items-center justify-content-center px-6'>
                              <div>
                                <Document file={a} renderMode='canvas' onLoadSuccess={(e) => {
                                  setAttachmentsMetadata((_current) => {
                                    const _next = { ..._current }
                                    _next[key] = { numPages: e.numPages, pageNumber: 1 }
                                    return _next
                                  })
                                }}>
                                  <Page pageNumber={attachmentsMetadata[key] ? attachmentsMetadata[key].pageNumber : 1} renderTextLayer={false} renderAnnotationLayer={false} />
                                </Document>
                                {
                                  attachmentsMetadata[key] ? 
                                    <>
                                      <p className='mb-1'>Page {attachmentsMetadata[key].pageNumber} of {attachmentsMetadata[key].numPages}</p>
                                      <div>
                                        {
                                          attachmentsMetadata[key].pageNumber > 1 ?
                                            <button type='button' className='btn btn-link mx-4' onClick={() => {
                                              const attachmentMetadata: AttachmentMetadata = { ...attachmentsMetadata[key], pageNumber: attachmentsMetadata[key].pageNumber - 1 }
                                              const _next = { ...attachmentsMetadata }
                                              _next[key] = attachmentMetadata
                                              setAttachmentsMetadata(_next)
                                            }}>
                                              Prev
                                            </button>
                                          :
                                            null
                                        }
                                        
                                        {
                                          attachmentsMetadata[key].pageNumber < attachmentsMetadata[key].numPages ?
                                            <button type='button' className='btn btn-link mx-4' onClick={() => {
                                              const attachmentMetadata: AttachmentMetadata = { ...attachmentsMetadata[key], pageNumber: attachmentsMetadata[key].pageNumber + 1 }
                                              const _next = { ...attachmentsMetadata }
                                              _next[key] = attachmentMetadata
                                              setAttachmentsMetadata(_next)
                                            }}>
                                              Next
                                            </button>
                                          :
                                            null
                                        }
                                        
                                      </div>
                                    </>
                                  :
                                    null
                                }
                              </div>
                            </div>
                          )
                        }
                        else {
                          return (
                            <h5 key={`attachment-${i}`}>Unsupported file type.</h5>
                          )
                        }
                      })
                    }
                  </Carousel>
                :
                  <div>No media exists for this campaign.</div>
              }
            </div>
        </div>
      </div>
    </div>
  )
}

export default AttachmentsModal
