import React, {FC} from 'react'
import {FacebookCampaign} from './FacebookWrapper'

type Props = {
    data?: FacebookCampaign
}

const Stat: FC<Props> = ({data}) => {
    return (
        <div className="col-xl-4">
            <div className="card card-xl-stretch mb-xl-8 shadow">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded w-100 bg-primary">
                        <div className="d-flex flex-stack">
                            <h3 className="m-0 text-white fw-bolder fs-3 mb-4">{data?.ad_name}</h3>
                        </div>
                        
                        <div>
                            <img alt="Project Media" src={data?.thumbnail_url} style={{maxHeight: '250px', maxWidth: '100%'}} />
                        </div>
                        
                        <div className="d-flex text-center flex-column text-white pt-8" style={{paddingBottom: '130px'}}>
                            <span className="fw-bold fs-7">Data For Dates</span>
                            <span className="fw-bolder fs-2 pt-1">{data?.date_start.toLocaleString('en-US', { month: 'short', day: 'numeric' })} - {data?.date_stop.toLocaleString('en-US', { month: 'short', day: 'numeric' })}</span>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{marginTop: '-100px'}}>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Impressions</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="fw-bolder fs-5 text-gray-800 pe-1">{data?.impressions.toLocaleString()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Reach</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bolder fs-6 text-gray-800 pe-1 m-0">{data?.reach.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Engagement</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bolder fs-6 text-gray-800 pe-1 m-0">{data?.inline_post_engagement.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Clicks</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bolder fs-6 text-gray-800 pe-1 m-0">{data?.clicks.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">CTR</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fw-bolder fs-6 text-gray-800 pe-1 m-0">{data?.ctr.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}

export {Stat}
