import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { submitContactForm } from './contactCRUD'
import { toast } from 'react-toastify'

const contactSchema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  priority: Yup.string().required('Priority is required'),
})

function ContactUs(props) {
  const [loading, setLoading] = useState(false)

  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const initialValues = {
    email: user?.email || '',
    subject: '',
    message: '',
    priority: 'Low',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: contactSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true)
      setTimeout(async () => {
        await submitContactForm(values)
          .then((res) => {
            toast.success('Contact request submitted!')
            resetForm()
            setLoading(false)
          })
          .catch((e) => {
            toast.error('Submission Error.')
            setLoading(false)
          })
      }, 1000)
    },
    enableReinitialize: true,
  })

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        background: `url(${toAbsoluteUrl('/custom/auth_background.svg')}), linear-gradient(180deg, #25AAE1 0%, rgba(37, 170, 225, 0.17) 66.15%, rgba(37, 170, 225, 0.00) 100%)`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='w-lg-500px rounded shadow-sm p-10 p-lg-15 mx-auto'  style={{backgroundColor: '#1B1B27'}}>
            <div className='text-center mb-10'>
              <h1 className='text-white mb-3'>Contact Us</h1>
            </div>

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-white'>Subject</label>
              <input
                placeholder='Subject'
                {...formik.getFieldProps('subject')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.subject && formik.errors.subject },
                  {
                    'is-valid': formik.touched.subject && !formik.errors.subject,
                  }
                )}
                type='text'
                name='subject'
                autoComplete='off'
              />
              {formik.touched.subject && formik.errors.subject && (
                <div className='fv-plugins-message-container mt-1'>
                  <span role='alert' className='text-danger'>{formik.errors.subject}</span>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-white'>Message</label>
              <textarea
                placeholder='Message'
                {...formik.getFieldProps('message')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.message && formik.errors.message },
                  {
                    'is-valid': formik.touched.message && !formik.errors.message,
                  }
                )}
                name='message'
                autoComplete='off'
                rows={5}
              />
              {formik.touched.message && formik.errors.message && (
                <div className='fv-plugins-message-container mt-1'>
                  <span role='alert' className='text-danger'>{formik.errors.message}</span>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-white'>Priority</label>
              <select
                placeholder='Priority'
                {...formik.getFieldProps('priority')}
                className={clsx(
                  'form-select form-select-solid',
                  { 'is-invalid': formik.touched.priority && formik.errors.priority },
                  {
                    'is-valid': formik.touched.priority && !formik.errors.priority,
                  }
                )}
                name='priority'
                autoComplete='off'
              >
                <option value='Low'>Low</option>
                <option value='Medium'>Medium</option>
                <option value='High'>High</option>
              </select>

              {formik.touched.priority && formik.errors.priority && (
                <div className='fv-plugins-message-container mt-1'>
                  <span role='alert' className='text-danger'>{formik.errors.priority}</span>
                </div>
              )}
            </div>

            <div className='text-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary mt-6 mb-5 mx-auto px-10 w-100'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>

        <div className='mt-15'>
          <div className='mb-1 text-center text-primary text-uppercase fw-bolder fs-6'>Powered By</div>
          <img src={toAbsoluteUrl('/media/logos/mighty-logo-full.png')} alt='Powered By Mighty' className='h-70px' />
        </div>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 flex-center p-10'>
        <li className='menu-item'>
          <Link to='/auth/login' className='menu-link pe-0 pe-2'>
            Login
          </Link>
        </li>
        <li className='menu-item'>
          <Link to='/about-us' className='menu-link pe-0 pe-2'>
            About
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default ContactUs
