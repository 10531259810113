import React, { useState, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { getDataFromWidgetRef } from '../../helpers/remoteData'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { fetchInvitation } from '../../helpers/remoteData'

import 'react-datepicker/dist/react-datepicker.css'


function PageFilterWidget({ reference, setSelectedWidget, el, moveUp, moveDown, searchText, currentCampaign, startDate, endDate, setSearchText, setCurrentCampaign, setStartDate, setEndDate }) {
  const [loading, setLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [widgetData, setWidgetData] = useState([])

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    const _fetch = (r) => {
      // @ts-ignore
      const _titles = r.map((d) => d.title || d.campaignName || '')
      const _widgetData = _titles.filter((d, i) => {
        if (!d || d === '') {
          return false
        }

        return _titles.indexOf(d) === i
      })
      
      setWidgetData(_widgetData)
      console.log('R', _widgetData)
      MenuComponent.reinitialization()
      setLoading(false)
    }

    console.log('WIDGET LOADING')
    setSearchText('')
    setCurrentCampaign('All')
    setStartDate(moment().subtract(1, 'months').toDate())
    setEndDate(moment().toDate())

    getDataFromWidgetRef(reference).then(_fetch)
  }, [reference, setSearchText, setCurrentCampaign, setStartDate, setEndDate])

  useEffect(() => {
    const _fetch = async () => {
      if (user.email) {
        const invitation = await fetchInvitation(user.email)
        
        if (invitation) {
          setIsAdmin(invitation.isAdmin)
        }
      }
    }

    _fetch()
  }, [user])

  const handleDateChange = (dates) => {
    const [_start, _end] = dates

    setStartDate(_start)
    setEndDate(_end)
  }

  return (
    <div className='col-12 mb-8 d-inline-block'>
      {
        isAdmin ? 
          <div className='w-100 text-end mb-2' style={{padding: '0 2.25rem'}}>
            <button
              type='button'
              className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
              onClick={() => moveDown(el)}
            >
              <KeyboardArrowDownIcon />
            </button>
            <button
              type='button'
              className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
              onClick={() => moveUp(el)}
            >
              <KeyboardArrowUpIcon />
            </button>
            <button
              type='button'
              className='btn btn-danger btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
              data-bs-toggle='modal'
              data-bs-target='#modal_delete_widget'
              onClick={() => setSelectedWidget(el)}
            >
              <DeleteIcon className='text-white' />
            </button>
          </div>
        :
          null
      }
      <div className='row'>
        <div className='col-12 col-sm-4'>
          <div className='bg-white w-100 d-flex align-items-center rounded shadow h-55px'>
            <input
              type='text'
              className='flex-fill h-30px py-0 border-0 form-control'
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className='d-inline-flex align-items-center justify-content-center border-start border-gray-300 px-3 h-30px'>
              <SearchIcon style={{color: '#AEAEAE'}} />
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-4 d-flex align-items-center'>
          <p className='mb-0 me-5' style={{color: '#AEAEAE'}}>Campaign</p>
          <div className='bg-white shadow flex-fill p-3 rounded h-55px d-flex align-items-center'>
            {
              loading ? 
                <div className="spinner-border spinner-border-sm text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              :
                <select
                  className='form-select form-select-solid bg-white border-0 ps-2'
                  style={{color: '#181c32'}}
                  onChange={(e) => setCurrentCampaign(e.target.value)}
                  value={currentCampaign}
                >
                  <option value='All'>All</option>
                  {
                    widgetData.map((w, i) => (
                      <option value={w} key={`current-campaign-option-${i}`}>{w}</option>
                    ))
                  }
                </select>
            }
          </div>
        </div>
        <div className='col-12 col-sm-4 d-flex align-items-center'>
          <p className='mb-0 me-5' style={{color: '#AEAEAE'}}>Date Range</p>
          <div className='bg-white shadow rounded flex-fill h-55px d-flex align-items-center'>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              className='form-control flex-fill border-0'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageFilterWidget
