import React, { useState, useEffect } from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Collapse } from '@mui/material'
import moment from 'moment'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { getDataFromWidgetRef } from '../../helpers/remoteData'
import { KTSVG } from '../../../_metronic/helpers'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { fetchInvitation } from '../../helpers/remoteData'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F6F9',
    color: '#404253',
    fontSize: 12,
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {},
}))

function SmarsheetDigitalAdWidget({ reference, setSelectedWidget, el, moveUp, moveDown, searchText, currentCampaign, startDate, endDate }) {
  const [widgetData, setWidgetData] = useState([
    {
      title: '',
      startDate: {
        seconds: 0,
      },
      endDate: {
        seconds: 0,
      },
    }
  ])
  const [formattedWidgetData, setFormattedWidgetData] = useState([
    {
      title: '',
      startDate: {
        seconds: 0,
      },
      endDate: {
        seconds: 0,
      },
    }
  ])
  const [loading, setLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [page, setPage] = useState(0)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  
  useEffect(() => {
    const _fetch = (r) => {
      // @ts-ignore
      setWidgetData(r)
      console.log('Response', r)
      MenuComponent.reinitialization()
      setLoading(false)
    }

    setLoading(true)

    getDataFromWidgetRef(reference).then(_fetch)
  }, [reference])

  useEffect(() => {
    const momentStartDate = moment(startDate)
    const momentEndDate = moment(endDate)

    const _formatted = widgetData.filter((w) => {
      if (!w.title) {
        return false
      }

      if (w.title.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
        return false
      }

      if (w.title.toLowerCase().indexOf(currentCampaign.toLowerCase()) === -1 && currentCampaign.toLowerCase() !== 'all') {
        return false
      }

      if (!w.startDate || !w.endDate) {
        return false
      }

      if (!moment.unix(w.startDate.seconds).isBetween(momentStartDate, momentEndDate) && !moment.unix(w.endDate.seconds).isBetween(momentStartDate, momentEndDate)) {
        return false
      }

      return true
    })

    setFormattedWidgetData(_formatted)
  }, [widgetData, searchText, currentCampaign, startDate, endDate])

  useEffect(() => {
    const _fetch = async () => {
      if (user.email) {
        const invitation = await fetchInvitation(user.email)
        
        if (invitation) {
          setIsAdmin(invitation.isAdmin)
        }
      }
    }

    _fetch()
  }, [user])

  const resetFilters = () => {
    setPage(0)
  }

  const handleChangePage = async (event, newPage, isPrevious) => {
    setPage(newPage)
  }

  return (
    <div className='col-12 mb-8 d-inline-block'>
      <div className='card shadow'>
        <div className='card-header' style={{minHeight: 'initial'}}>
          {
            isAdmin ? 
              <div className='w-100 text-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
                  onClick={() => moveDown(el)}
                >
                  <KeyboardArrowDownIcon />
                </button>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
                  onClick={() => moveUp(el)}
                >
                  <KeyboardArrowUpIcon />
                </button>
                <button
                  type='button'
                  className='btn btn-danger btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
                  data-bs-toggle='modal'
                  data-bs-target='#modal_delete_widget'
                  onClick={() => setSelectedWidget(el)}
                >
                  <DeleteIcon className='text-white' />
                </button>
              </div>
            :
              null
          }
        </div>
        <div className='card-body'>
          {
            formattedWidgetData.length > 0 ? 
              <div className='table-responsive'>
                <TableContainer className='rounded'>
                  <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead className='bg-gray-600'>
                      <TableRow>
                        <StyledTableCell className="header-match border-0" style={{borderBottomLeftRadius: '0.475rem', borderTopLeftRadius: '0.475rem'}}>Campaign Name</StyledTableCell>
                        <StyledTableCell className="header-match border-0">Job Code</StyledTableCell>
                        <StyledTableCell className="header-match border-0">Start Date</StyledTableCell>
                        <StyledTableCell className="header-match border-0">End Date</StyledTableCell>
                        <StyledTableCell className="header-match border-0">Impressions</StyledTableCell>
                        <StyledTableCell className="header-match border-0">CTR</StyledTableCell>
                        <StyledTableCell className="header-match border-0">Spend</StyledTableCell>
                        <StyledTableCell className='header-match border-0' style={{borderBottomRightRadius: '0.475rem', borderTopRightRadius: '0.475rem'}} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        formattedWidgetData.map((d, i) => (
                          <Row key={`${reference}-row-${i}`} row={d} page={page} />
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                {
                  widgetData.length >= 10 ? 
                    <div className='my-8 text-center' style={{padding: '0 16px'}}>
                      <button type='button' className='btn btn-link btn-sm text-hover-gray-800 w-125px d-inline-flex align-items-center justify-content-center fs-6' style={{color: '#8A8A8A'}} onClick={(e) => handleChangePage(e, page - 1, true)}>
                        <KTSVG path='/media/icons/duotune/arrows/arr022.svg' className='ms-0 me-3' />
                        <span>
                          Back
                        </span>
                      </button>
                      <button type='button' className='btn btn-link btn-sm text-hover-gray-800 w-125px d-inline-flex align-items-center justify-content-center fs-6' style={{color: '#8A8A8A'}} onClick={(e) => handleChangePage(e, page + 1, false)}>
                        <span>
                          Next
                        </span>
                        <KTSVG path='/media/icons/duotune/arrows/arr023.svg' className='ms-3 me-0' />
                      </button>
                    </div>
                  :
                    null
                }
              </div>
            :
              <p className='mb-0'>No campaigns exist for the selected filters.</p>
          }
        </div>
      </div>
    </div>
  )
}

function Row({ row, page }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(page > -1 ? false : true)
  }, [page])

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.title || 'n/a'}</StyledTableCell>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.jobCode || 'n/a'}</StyledTableCell>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.startDate ? moment.unix(row.startDate.seconds).format('M/D/YYYY') : 'n/a'}</StyledTableCell>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.endDate ? moment.unix(row.endDate.seconds).format('M/D/YYYY') : 'n/a'}</StyledTableCell>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.impressions ? row.impressions.toLocaleString() : 'n/a'}</StyledTableCell>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.ctr || 'n/a'}</StyledTableCell>
        <StyledTableCell component='td' scope='row' className="body-match border-0 fw-bolder fs-5">{row.spend ? `$${row.spend.toLocaleString()}` : 'n/a'}</StyledTableCell>
        <StyledTableCell className='border-0'>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <div className='d-flex px-5 pt-5 pb-10'>
              <div style={{width: '605px', height: '300px', backgroundColor: '#D9D9D9'}}>
                {
                  row.attachments && row.attachments.length > 0 ? 
                    <Carousel showArrows={true} showIndicators={false} showThumbs={false}>
                      {
                        row.attachments.map((a, i) => (
                          <div key={`${row.jobCode}-attachment-${i}`} className='h-100 d-flex align-items-center justify-content-center'>
                            <img src={a} alt={`Carousel ${i + 1}`} className='w-auto' style={{maxHeight: '300px', maxWidth: '100%'}} />
                          </div>
                        ))    
                      }
                    </Carousel>
                  :
                    <h6 className='text-center text-gray-600 pt-10 px-10'>No media for this campaign.</h6>
                }
              </div>
              <div className='flex-fill py-8 px-12'>
                <h6 className='text-secondary text-gray-500 fs-6 mb-3 d-flex align-items-center'>
                  <KTSVG
                    path='/custom/Clock.svg'
                  />
                  <span className='d-inline-block ms-5 w-50px'>
                    Length
                  </span>
                  <span className='d-inline-block ms-8'>
                    {row.length || 'n/a'}
                  </span>
                </h6>
                <h6 className='text-secondary text-gray-500 fs-6 d-flex align-items-center'>
                  <KTSVG
                    path='/custom/Pointer.svg'
                  />
                  <span className='d-inline-block ms-5 w-50px'>
                    Clicks
                  </span>
                  <span className='d-inline-block ms-8'>
                    {row.clicks}
                  </span>
                </h6>
                <p className='fw-bold fs-6 mt-8'>
                  {
                    row.notes ? 
                      row.notes
                    :
                      'No notes for this campaign.'
                  }
                </p>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default SmarsheetDigitalAdWidget
