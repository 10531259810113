/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useRef, useCallback } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { Client } from '../../pages/admin/ManageClientsWrapper'
import { fetchClient, uploadImage, updateClient, updateMembershipsByClient } from '../../helpers/remoteData'

type Props = {
  id: string
  handleSubmit: () => Promise<void>
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Name is required'),
  defaultColor: Yup.string()
    .min(7, 'Minimum 7 characters')
    .max(50, 'Maximum 50 characters')
    .required('Default Color is required'),
  logo: Yup.mixed(),
})

const EditClientModal: FC<Props> = ({ id, handleSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [clientLoading, setClientLoading] = useState<boolean>(false)
  const [client, setClient] = useState<Client | null>(null)
  const [initialValues, setInitialValues] = useState<any>({
    name: '',
    defaultColor: '',
    logo: '',
  })
  const [progress, setProgress] = useState<number>(0)

  const closeRef = useRef<HTMLDivElement>(null)

  const _fetchClient = useCallback(async () => {
    setClientLoading(true)

    if (id !== '') {
      const _client: any = await fetchClient(id)
    
      if (_client) {
        setClient(_client)

        setInitialValues((_current) => ({
          ..._current,
          name: _client.name,
          defaultColor: _client.defaultColor,
        }))

        setClientLoading(false)
      }
    }
  }, [id])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true)

        if (client?.id && client?.name) {
          let logoUrl = client?.logoUrl
          if (values.logo !== '') {
            const imageData = await uploadImage(values.logo, (_progress) => setProgress(_progress))
            logoUrl = imageData.data
          }

          const clientPartial = {
            id: client.id,
            name: client.name,
          }

          await updateMembershipsByClient(clientPartial, values.name)

          let _newClient = {
            name: values.name,
            defaultColor: values.defaultColor,
            logoUrl,
          }

          await updateClient(client.id, _newClient)

          await handleSubmit()

          setLoading(false)
          setProgress(0)
          toast.success('Client updated')
          await _fetchClient()
          closeRef.current?.click()
        }
      }
      catch (e) {
        setStatus('Unknown error')
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    _fetchClient()
  }, [_fetchClient])

  return (
    <div className='modal fade' id='modal_edit_client' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          {
            clientLoading ? 
              <div className='modal-body scroll-y py-8 text-center'>
                <div className='spinner-border spinner-border-sm' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            :
              <form
                className='form'
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div className='modal-header border-gray-300'>
                  <h2>Edit Client</h2>
                  <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
                    <CloseIcon />
                  </div>
                </div>

                <div className='modal-body scroll-y py-8'>
                  <div className='form-group mb-8'>
                    <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                      <span className='required'>Identifier</span>
                    </label>
                    <div>
                      {client?.id}
                    </div>
                  </div>

                  <div className='form-group mb-8'>
                    <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                      <span className='required'>Name</span>
                    </label>
                    <input
                      {...formik.getFieldProps('name')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.name && formik.errors.name },
                        { 'is-valid': formik.touched.name && !formik.errors.name },
                      )}
                      name='name'
                      autoComplete='off'
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container mt-1'>
                        <span role='alert' className='text-danger'>{formik.errors.name}</span>
                      </div>
                    )}
                  </div>

                  <div className='form-group mb-8'>
                    <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                      <span className='required'>Default Color</span>
                    </label>
                    <input
                      {...formik.getFieldProps('defaultColor')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        { 'is-invalid': formik.touched.defaultColor && formik.errors.defaultColor },
                        { 'is-valid': formik.touched.defaultColor && !formik.errors.defaultColor },
                      )}
                      name='defaultColor'
                      autoComplete='off'
                    />
                    {formik.touched.defaultColor && formik.errors.defaultColor && (
                      <div className='fv-plugins-message-container mt-1'>
                        <span role='alert' className='text-danger'>{formik.errors.defaultColor}</span>
                      </div>
                    )}
                  </div>

                  <div className='form-group'>
                    <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                      <span className='required'>Logo</span>
                    </label>
                    <div className='d-flex flex-wrap mt-5' style={{height: '60px'}}>
                      <div className='logo-input-wrapper border-0' style={{height: '60px', width: '210px'}}>
                        {
                          formik.values.logo !== '' ? 
                            <img src={URL.createObjectURL(formik.values.logo as unknown as Blob)} alt='New Logo' className='h-100 w-100 rounded' />
                          :
                            client?.logoUrl ? 
                              <img src={client.logoUrl} alt='New Logo' className='h-100 w-100 rounded' />
                            :
                              <span className='d-inline-block bg-secondary h-100 w-100 rounded' style={{verticalAlign: 'middle'}}></span>
                        }
                      </div>
                      <div className='ms-8 d-flex flex-wrap align-items-center h-100'>
                        {
                          formik.isSubmitting && formik.values.logo !== '' ? 
                            <div className='progress h-8px bg-secondary w-100px'>
                              <div
                                className='progress-bar bg-primary'
                                role='progressbar'
                                aria-valuemin={0}
                                aria-valuemax={100}
                                aria-valuenow={progress}
                                style={{width: `${progress}%`}}
                              >
                              </div>
                            </div>
                          :
                            <>
                              <label htmlFor='logo-input' className='btn btn-secondary btn-sm'>Choose File</label>
                              <input
                                type='file'
                                className={clsx(
                                  'form-control form-control-lg form-control-solid d-none',
                                  { 'is-invalid': formik.touched.logo && formik.errors.logo },
                                  { 'is-valid': formik.touched.logo && !formik.errors.logo },
                                )}
                                id='logo-input'
                                name='logo'
                                onChange={(e) => {
                                  formik.handleChange(e)

                                  if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                                    formik.setFieldValue('logo', e.currentTarget.files[0])
                                  }
                                }}
                              />
                              {
                                formik.values.logo !== '' ? 
                                  <button type='button' className='btn btn-link btn-sm btn-color-danger btn-active-color-gray-800 ms-5' onClick={() => {
                                    formik.setFieldValue('logo', '')
                                  }}>
                                    Cancel
                                  </button>
                                :
                                  null
                              }
                            </>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className='modal-footer border-gray-300'>
                  <button
                    type='submit'
                    className='btn btn-primary px-10'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
          }
        </div>
      </div>
    </div>
  )
}

export { EditClientModal }
