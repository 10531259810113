import { FC, useState, useEffect, useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { fetchClients, fetchClient, fetchMemberships } from '../../helpers/remoteData'
import { AddClientWizard } from '../../modules/wizards/AddClientWizard'
import { DeleteClientModal } from '../../modules/modals/DeleteClientModal'
import { EditClientModal } from '../../modules/modals/EditClientModal'
import { ClientContext } from '../../providers/ClientProvider'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'

interface ClientFacebook {
  adAccountId: string
  id: string
  secret: string
}

export interface Client {
  name: string
  logoUrl: string
  digital?: string
  cable?: string
  mail?: string
  facebook?: ClientFacebook
  id?: string
  pages?: string[]
  defaultColor?: string
}

export const defaultClient = {
  name: '',
  logoUrl: '',
  id: '',
  defaultColor: '',
}

const ManageClientsWrapper: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [rowsLoading, setRowsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [clients, setClients] = useState<any[]>([])
  const [showClients, setShowClients] = useState<boolean>(true)
  const [selectedClient, setSelectedClient] = useState<Client>(defaultClient)

  const { setClient: setContextClient, setClients: setContextClients } = useContext(ClientContext)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const _resetClients = async () => {
    const memberships: any = await fetchMemberships(user.email)
        
    if (memberships.clients.length > 0) {
      setContextClients(memberships.clients)

      if (Object.keys(memberships.clients[0]).length > 0) {
        let _client = memberships.clients[0]
        const storageItem = localStorage.getItem('currentClientName')
        if (storageItem) {
          _client = JSON.parse(storageItem)
        }
        else {
          localStorage.setItem('currentClientName', JSON.stringify(_client))
        }

        const client = await fetchClient(_client.id)
        setContextClient(client)
      }
    }
  }

  const _fetchClients = useCallback(async () => {
    const _page = 0
    setPage(_page)

    setLoading(true)
    setRowsLoading(true)

    const _clients = await fetchClients()
    setClients(_clients)

    setLoading(false)
    setRowsLoading(false)
  }, [])

  const handlePageChange = async (newPage, isPrevious) => {
    setRowsLoading(true)

    const _client = isPrevious ? clients[0] : clients[clients.length - 1]
    const _clients = await fetchClients(_client.doc, isPrevious)

    if (_clients && _clients.length > 0) {
      setClients(_clients)
    }

    setRowsLoading(false)
    setPage(newPage)
  }

  const handleSubmit = async () => {
    await _fetchClients()
    await _resetClients()
  }

  useEffect(() => {
    _fetchClients()

    return () => setClients([])
  }, [_fetchClients])

  return (
    <div className='gy-5 g-xl-8'>
      {
        showClients ? 
          <>
            <h1 className='display-6 mt-0 mb-8 fw-boldest'>Manage Clients</h1>

            <div className='card mb-8 shadow'>
              {
                loading ? 
                  <div className='card-body text-center'>
                    <div className='spinner-border spinner-border-sm' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                :
                  <>
                    <div className='card-header border-0'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-2'>Clients</span>
                      </h3>

                      <div className='card-toolbar'>
                        <button
                          type='button'
                          className='btn btn-primary btn-sm d-flex align-items-center justify-content-center'
                          onClick={() => setShowClients(false)}
                        >
                          <AddIcon fontSize='small' className='me-2' />
                          <span>Create</span>
                        </button>
                      </div>
                    </div>

                    {
                      rowsLoading ? 
                        <div className='card-body'>
                          <div className='text-center py-3'>
                            <div className='spinner-border spinner-border-sm' role='status'>
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          </div>
                        </div>
                      :
                        <div className='card-body py-0'>
                          <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                              <thead>
                                <tr className='fw-bolder text-muted'>
                                  <th className='min-w-150px'>Name</th>
                                  <th>Identifier</th>
                                  <th>Default Color</th>
                                  <th className='text-end'>Actions</th>
                                </tr>
                              </thead>
                              
                              <tbody className='border-0'>
                                {
                                  clients.map((row, i) => (
                                    <tr key={`client-row-${i}`}>
                                      <td>
                                        <Link to={`/clients/${row.id}/pages`}>
                                          <div
                                            className='text-dark fw-bolder d-block fs-6 cursor-pointer text-hover-primary'
                                          >
                                            {row.name}
                                          </div>
                                        </Link>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {row.id}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {row.defaultColor}
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <button
                                          type='button'
                                          className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                                          data-bs-toggle='modal'
                                          data-bs-target='#modal_edit_client'
                                          onClick={() => {
                                            setSelectedClient(row)
                                          }}
                                        >
                                          <EditIcon />
                                        </button>
                                        <button
                                          type='button'
                                          className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between ms-2 my-1'
                                          data-bs-toggle='modal'
                                          data-bs-target='#modal_delete_client'
                                          onClick={() => {
                                            setSelectedClient(row)
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                    }
                  </>
              }

              <div className='card-footer d-flex align-items-center justify-content-between'>
                <div className='text-muted mt-1 fw-bold fs-7'>
                  Page {page + 1}
                </div>
                <div className='text-end'>
                  {
                    page > 0 && (
                      <button type='button' className={`btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-center ${clients.length >= 10 ? 'me-5' : ''}`} onClick={() => handlePageChange(page - 1, true)}>
                        <ArrowBackIcon fontSize='small' />
                      </button>
                    )
                  }
                  {
                    clients.length >= 10 && (
                      <button type='button' className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-center' onClick={() => handlePageChange(page + 1, false)}>
                        <ArrowForwardIcon fontSize='small' />
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </>
        :
          <>
            <h1 className='display-6 mt-0 mb-8 fw-boldest'>Create Client</h1>
            <div className='card shadow mb-5 mb-xl-8'>
              <div className='card-header border-0'>
                <div className='card-toolbar'>
                  <button
                    onClick={() => setShowClients(true)}
                    type='button'
                    className='btn btn-primary btn-sm'
                  >
                    <ArrowBackIcon fontSize='small' className='me-3' />
                    <span>
                      Back
                    </span>
                  </button>
                </div>
              </div>
              <AddClientWizard fetchClients={_fetchClients} setShowClients={setShowClients} />
            </div>
          </>
      }

      <EditClientModal id={selectedClient.id || ''} handleSubmit={handleSubmit} />
      <DeleteClientModal id={selectedClient.id || ''} name={selectedClient.name || ''} handleSubmit={_fetchClients} />
    </div>
  )
}

export {ManageClientsWrapper}
