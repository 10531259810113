import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { Routes } from './routing/Routes'
import { FirebaseInitAuth } from './modules/auth/redux/FirebaseInitAuth'
import './app.css'
import AddPageModel from '../_metronic/layout/components/aside/AddPageModel'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <LayoutProvider>
              <FirebaseInitAuth>
                <Routes />
                <AddPageModel />
              </FirebaseInitAuth>
            </LayoutProvider>
          </LocalizationProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export { App }
