import { FC, useState, useContext, useRef } from 'react'
import { toast } from 'react-toastify'
import {shallowEqual, useSelector} from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import { ClientContext } from '../../providers/ClientProvider'
import { removeClient, removeMembershipsByClient, removePagesByClient, fetchMemberships, fetchClient } from '../../helpers/remoteData'
import { UserModel } from '../auth/models/UserModel'
import { RootState } from '../../../setup'

type Props = {
  id: string
  name: string
  handleSubmit: () => Promise<void>
}

const DeleteClientModal: FC<Props> = ({ id, name, handleSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const closeRef = useRef<HTMLDivElement>(null)

  const { setClient, setClients } = useContext(ClientContext)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const remove = async () => {
    try {
      setLoading(true)

      const clientPartial = {
        id,
        name,
      }

      await removeMembershipsByClient(clientPartial)
      await removePagesByClient(name)
      await removeClient(id)

      const memberships: any = await fetchMemberships(user.email)
      if (memberships.clients.length > 0) {
        setClients(memberships.clients)

        if (Object.keys(memberships.clients[0]).length > 0) {
          let _client = memberships.clients[0]
          const storageItem = localStorage.getItem('currentClientName')
          if (storageItem && _client.id !== id) {
            const __client = JSON.parse(storageItem)

            if (__client.id === id) {
              localStorage.setItem('currentClientName', JSON.stringify(_client))
            }
            else {
              _client = __client
            }
          }
          else {
            localStorage.setItem('currentClientName', JSON.stringify(_client))
          }

          const client = await fetchClient(_client.id)
          setClient(client)
        }
      }

      await handleSubmit()

      setLoading(false)
      toast.success('Client deleted')
      closeRef.current?.click()
    }
    catch (e) {
      toast.error('Delete client error')
      setLoading(false)
    }
  }
  
  return (
    <div className='modal fade' id='modal_delete_client' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Delete Client</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body scroll-y py-8'>
            <div className='form-group mb-8'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Name
              </label>
              <div>
                {name}
              </div>
            </div>

            <div className='form-group'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Identifier
              </label>
              <div>
                {id}
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-danger px-10'
              onClick={() => remove()}
              disabled={loading}
            >
              {!loading && <span className='indicator-label'>Delete</span>}
              {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DeleteClientModal }
