/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useContext,useRef,useState,useEffect} from 'react'
import {Carousel} from 'react-bootstrap-v5'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'

const CableGalleryModal: FC = () => {
    const { currentCable } = useContext(RemoteDataContext)
    const closeRef = useRef<HTMLDivElement>(null)
    const [index, setIndex] = useState<number>(0)

    useEffect(() => {
        if (Object.keys(currentCable).length > 0) {
            setIndex(0)
        }
    }, [currentCable])

    return (
        <div className='modal fade' id='kt_modal_cable_gallery' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-between'>
                        <h5 className="card-title text-gray-600">Cable Gallery</h5>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15 mt-8'>
                        <Carousel slide={false} interval={null} controls={false} indicators={false} activeIndex={index} onSelect={(_index) => setIndex(_index)}>
                            {
                                currentCable.media && currentCable.media.length > 0 ? 
                                currentCable.media.map((a, i) => (
                                    <Carousel.Item key={`media-gallery-item-${i}`}>
                                        <div className="text-center">
                                            {
                                                a.includes('.mp4') ? 
                                                <video height="250" controls style={{maxWidth: '100%'}}>
                                                    <source src={a} type="video/mp4" />
                                                </video>
                                                : 
                                                <img src={a} alt={`Media ${i}`} className="d-inline-block h-250px" style={{maxWidth: '100%'}} />
                                            }
                                        </div>
                                    </Carousel.Item>
                                ))
                                : null
                            }
                        </Carousel>
                        <div className="row mt-8">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                <p className="m-0 me-4">{index + 1} of {currentCable.media?.length}</p>
                                <div className="dataTables_paginate paging_simple_numbers" id="kt_table_users_paginate">
                                    <ul className="pagination">
                                        <li className="paginate_button page-item previous" id="kt_table_users_previous">
                                            <p onClick={() => {
                                                const _index = index - 1
                                                setIndex(currentCable.media && _index < 0 ? currentCable.media.length - 1 : _index)
                                            }} aria-controls="kt_table_users" data-dt-idx="0" tabIndex={0} className="page-link d-flex align-items-center m-0">
                                                <i className="previous"></i>
                                            </p>
                                        </li>
                                        <li className="paginate_button page-item next" id="kt_table_users_next">
                                            <p onClick={() => {
                                                const _index = index + 1
                                                setIndex(currentCable.media && _index > currentCable.media.length - 1 ? 0 : _index)
                                            }} aria-controls="kt_table_users" data-dt-idx="4" tabIndex={0} className="page-link d-flex align-items-center m-0">
                                                <i className="next"></i>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {CableGalleryModal}
