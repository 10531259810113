import axios from 'axios'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth'
import { AuthModel } from '../models/AuthModel'
// import {UserModel} from '../models/UserModel'
import { auth } from '../../../helpers/remoteData'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/accounts:lookup?key=${process.env.REACT_APP_WEB_API_KEY}`
export const LOGIN_URL = `${API_URL}/accounts:signInWithPassword?key=${process.env.REACT_APP_WEB_API_KEY}`
export const REGISTER_URL = `${API_URL}/accounts:signUp?key=${process.env.REACT_APP_WEB_API_KEY}`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const UPDATE_URL = `${API_URL}/accounts:update?key=${process.env.REACT_APP_WEB_API_KEY}`

// Server should return AuthModel
export function login(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password)
}

// Server should return AuthModel
export function register(email: string, displayName: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    displayName,
    password,
  })
}

export function update(idToken?: string, displayName?: string) {
  return axios.post<AuthModel>(UPDATE_URL, {
    idToken,
    displayName,
    returnSecureToken: true,
  })
}

export function updatePassword(idToken: string, password: string) {
  return axios.post<AuthModel>(UPDATE_URL, {
    idToken,
    password,
    returnSecureToken: true,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email })
}

export function getUserByToken(idToken?: string) {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, { idToken })
}

export function firebaseForgotPassword(email: string) {
  return sendPasswordResetEmail(auth, email)
}

export function firebaseSignOut() {
  return signOut(auth)
}
