import { useContext, useEffect } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel, DBUserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { ClientContext } from '../../providers/ClientProvider'

function WelcomeWidget({ isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const { client } = useContext(ClientContext)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const dbUser = useSelector<RootState>(({ auth }) => auth.dbUser, shallowEqual) as DBUserModel

  return (
    <div className='col-12 col-md-6 col-xl-4 mb-8 d-inline-block'>
      <div className='card shadow h-100'>
        {
          isAdmin && (
            <div className='card-header justify-content-end'>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveUp(el)}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveDown(el)}
              >
                <ArrowDownwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                data-bs-toggle='modal'
                data-bs-target='#modal_delete_widget'
                onClick={() => setSelectedWidget(el)}
              >
                <DeleteIcon />
              </button>
            </div>
          )
        }
        <div className='card-header' style={client.defaultColor ? { backgroundColor: client.defaultColor } : {}}></div>
        <div className='card-body text-center'>
          <div className='text-center' style={{marginTop: '-60px'}}>
            {
              dbUser?.photoUrl ?
                <img src={dbUser.photoUrl} alt='User Welcome' className='d-inline-block' style={{height: '72px', width: '72px', verticalAlign: 'middle', borderRadius: '0.475rem'}} />
              :
                <span className="d-inline-block bg-primary" style={{height: '72px', width: '72px', verticalAlign: 'middle', borderRadius: '0.475rem'}}>
                  <p className="h-100 fs-2 fw-bolder d-flex align-items-center justify-content-center m-0">{user.displayName?.substring(0, 1)}</p>
                </span>
            }
          </div>

          <div className='fs-3 fw-boldest mt-5 mb-1'>{user.displayName}</div>
          <div className='fs-4 fw-bold'>{client.name}</div>

          <div className='fs-3 fw-bold mt-10'>Welcome to your Dashboard,<br/>powered by Mighty!</div>

          <div className='text-center mt-8'>
            {
              client.logoUrl && ( 
                <img src={client.logoUrl} alt='Client' height={40} width={160} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeWidget
