import React, { FC, createContext, useState, useCallback } from 'react'

interface IFacebook {
  id: string
  secret: string
  adAccountId: string
}

export interface IClient {
  logoUrl: string
  name: string
  cable?: string
  digital?: string
  facebook?: IFacebook
  mail?: string
  broadcast?: string
  pages?: any
  defaultColor?: string
}

interface ClientHolder {
  client: IClient | any
  setClient: (c: IClient | any) => void
  clients: Array<IClient> | Array<any>
  setClients: (c: IClient[] | any[]) => void
  clientLoading: boolean
  setClientLoading: (l: boolean) => void
}

export const ClientContext = createContext<ClientHolder>({
  client: {
    logoUrl: '',
    name: '',
  },
  setClient: (c: IClient) => {},
  clients: [],
  setClients: (c: IClient[]) => {},
  clientLoading: false,
  setClientLoading: (l: boolean) => {},
})

export const ClientProvider: FC = ({ children }) => {
  const [clientLoading, _setClientLoading] = useState<boolean>(false)
  const [clients, _setClients] = useState<any[]>([])
  const [client, _setClient] = useState({
    logoUrl: '',
    name: '',
  })

  const setClient = useCallback((c: IClient) => _setClient(c), [_setClient])
  const setClients = useCallback((c: IClient[] | any[]) => _setClients(c), [_setClients])
  const setClientLoading = useCallback((l: boolean) => _setClientLoading(l), [_setClientLoading])

  return (
    <ClientContext.Provider
      value={{ client, setClient, clients, setClients, clientLoading, setClientLoading }}
    >
      {children}
    </ClientContext.Provider>
  )
}
