import React, {FC, useContext} from 'react'
import {DigitalCampaign,CampaignStat} from '../digital/DigitalWrapper'
import {KTSVG} from '../../../_metronic/helpers'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../modules/auth/models/UserModel'
import {RootState} from '../../../setup'
import {updateProject} from '../../helpers/remoteData'
import {ClientContext} from '../../providers/ClientProvider'

type Props = {
    data?: DigitalCampaign
    averages?: CampaignStat
}

const Stat: FC<Props> = ({data,averages}) => {
    const { client } = useContext(ClientContext)
    const { setCurrentDigital, digitals, setDigitals } = useContext(RemoteDataContext)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

    const sendToFront = async () => {
        if (client.digital && data?.id) {
            const index = digitals.map((d) => d.id).indexOf(data.id)
            await updateProject(client.digital, data.id, {})
            let _digitals = Array.from(digitals)
            _digitals[index] = {
                ..._digitals[index],
                updatedAt: new Date().getTime()/1000
            }
            _digitals.sort((a, b) => {
                return a.updatedAt && b.updatedAt ? b.updatedAt - a.updatedAt : 0
            })
            console.log('Sorted Digital', _digitals.map((d) => d.updatedAt))
            setDigitals(_digitals)
        }
    }

    return (
        <div className="col-xl-4">
            <div className="card card-xl-stretch mb-xl-8 shadow">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded w-100 bg-primary">
                        {
                            user.isAdmin ? 
                            <div className="text-right mb-3" style={{textAlign: 'right'}}>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    onClick={() => {
                                        sendToFront()
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/abstract/abs027.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_new_digital_campaign"
                                    onClick={() => {
                                        setCurrentDigital(data || {})
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen055.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_delete_digital_campaign"
                                    onClick={() => {
                                        setCurrentDigital(data || {})
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                            </div>
                            : null
                        }
                        <div className="d-flex flex-stack mb-7">
                            <h3 className="m-0 text-white fw-bolder fs-3">{data?.name}</h3>
                        </div>
                        
                        <div className="text-center h-250px d-flex align-items-center justify-content-center">
                            {
                               data?.ad && data.ad.length > 0 && data.ad[0].includes('.mp4') ? 
                                <video height="250" controls style={{maxWidth: '100%'}}>
                                    <source src={`${data?.ad[0]}`} type="video/mp4" />
                                </video>
                                :
                                <img alt="Project Media" src={`${data?.ad && data?.ad.length > 0 ? data.ad[0] : ''}`} style={{maxHeight: '250px', maxWidth: '100%'}} />
                            }
                        </div>

                        <div>
                            {
                                data?.ad && data.ad.length > 1 ? 
                                <p data-bs-toggle="modal" data-bs-target="#kt_modal_digital_gallery" className="m-0 mt-6 text-white text-hover-secondary text-center fw-bold" style={{cursor: 'pointer'}} onClick={() => { setCurrentDigital(data || {}) }}>Show {data.ad.length - 1} More</p>
                                : null
                            }
                        </div>
                        
                        <div className="d-flex text-center flex-column text-white pt-8" style={{paddingBottom: '130px'}}>
                            <span className="fw-bold fs-7">Dates</span>
                            <span className="fw-bolder fs-2 pt-1">{data?.date_string || 'TBD'}</span>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{marginTop: '-100px'}}>
                        {
                            data?.useAverage ? 
                            <>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <p className="fs-5 text-gray-800 fw-bolder m-0">Impressions</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className={`fw-bolder fs-5 pe-1 ${(data?.impressions || 0) >= (averages?.aboveImpressions || 0) ? 'text-success' : ''} ${((data?.impressions || 0) < (averages?.aboveImpressions || 0) && (data?.impressions || 0) >= (averages?.belowImpressions || 0)) ? 'text-warning' : ''} ${(data?.impressions || 0) < (averages?.belowImpressions || 0) ? 'text-danger' : ''}`}>{data?.impressions?.toLocaleString()}</div>
                                            <span className='svg-icon ml-1' style={{marginLeft: '0.25rem'}}>
                                                { (data?.impressions || 0) >= (averages?.aboveImpressions || 0) ? <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-7 svg-icon-success' /> : null }
                                                { ((data?.impressions || 0) < (averages?.aboveImpressions || 0) && (data?.impressions || 0) >= (averages?.belowImpressions || 0)) ? <KTSVG path='/media/icons/duotune/arrows/arr010.svg' className='svg-icon-7 svg-icon-warning' /> : null }
                                                { (data?.impressions || 0) < (averages?.belowImpressions || 0) ? <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-7 svg-icon-danger' /> : null }
                                            </span>
                                            <span style={{minWidth: '30px', textAlign: 'right'}} className={`fs-9 ${(data?.impressions || 0) >= (averages?.aboveImpressions || 0) ? 'text-success' : ''} ${((data?.impressions || 0) < (averages?.aboveImpressions || 0) && (data?.impressions || 0) >= (averages?.belowImpressions || 0)) ? 'text-warning' : ''} ${(data?.impressions || 0) < (averages?.belowImpressions || 0) ? 'text-danger' : ''}`}>{Math.round(((1 - ((data?.impressions || 0) / (averages?.averageImpressions || 0))) * -1) * 100)}%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <p className="fs-5 text-gray-800 fw-bolder m-0">Clicks</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className={`fw-bolder fs-6 pe-1 m-0 ${(data?.clicks || 0) >= (averages?.aboveClicks || 0) ? 'text-success' : ''} ${((data?.clicks || 0) < (averages?.aboveClicks || 0) && (data?.clicks || 0) >= (averages?.belowClicks || 0)) ? 'text-warning' : ''} ${(data?.clicks || 0) < (averages?.belowClicks || 0) ? 'text-danger' : ''}`}>{data?.clicks?.toLocaleString()}</p>
                                            <span className='svg-icon ml-1' style={{marginLeft: '0.25rem'}}>
                                                { (data?.clicks || 0) >= (averages?.aboveClicks || 0) ? <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-7 svg-icon-success' /> : null }
                                                { ((data?.clicks || 0) < (averages?.aboveClicks || 0) && (data?.clicks || 0) >= (averages?.belowClicks || 0)) ? <KTSVG path='/media/icons/duotune/arrows/arr010.svg' className='svg-icon-7 svg-icon-warning' /> : null }
                                                { (data?.clicks || 0) < (averages?.belowClicks || 0) ? <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-7 svg-icon-danger' /> : null }
                                            </span>
                                            <span style={{minWidth: '30px', textAlign: 'right'}} className={`fs-9 ${(data?.clicks || 0) >= (averages?.aboveClicks || 0) ? 'text-success' : ''} ${((data?.clicks || 0) < (averages?.aboveClicks || 0) && (data?.clicks || 0) >= (averages?.belowClicks || 0)) ? 'text-warning' : ''} ${(data?.clicks || 0) < (averages?.belowClicks || 0) ? 'text-danger' : ''}`}>{Math.round(((1 - ((data?.clicks || 0) / (averages?.averageClicks || 0))) * -1) * 100)}%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <p className="fs-5 text-gray-800 fw-bolder m-0">CTR</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className={`fw-bolder fs-6 pe-1 m-0 ${(data?.ctr || 0) >= (averages?.aboveCtr || 0) ? 'text-success' : ''} ${((data?.ctr || 0) < (averages?.aboveCtr || 0) && (data?.ctr || 0) >= (averages?.belowCtr || 0)) ? 'text-warning' : ''} ${(data?.ctr || 0) < (averages?.belowCtr || 0) ? 'text-danger' : ''}`}>{data?.ctr?.toLocaleString()}</p>
                                            <span className='svg-icon svg-icon-primary ml-1' style={{marginLeft: '0.25rem'}}>
                                                { (data?.ctr || 0) >= (averages?.aboveCtr || 0) ? <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-7 svg-icon-success' /> : null }
                                                { ((data?.ctr || 0) < (averages?.aboveCtr || 0) && (data?.ctr || 0) >= (averages?.belowCtr || 0)) ? <KTSVG path='/media/icons/duotune/arrows/arr010.svg' className='svg-icon-7 svg-icon-warning' /> : null }
                                                { (data?.ctr || 0) < (averages?.belowCtr || 0) ? <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-7 svg-icon-danger' /> : null }
                                            </span>
                                            <span style={{minWidth: '30px', textAlign: 'right'}} className={`fs-9 ${(data?.ctr || 0) >= (averages?.aboveCtr || 0) ? 'text-success' : ''} ${((data?.ctr || 0) < (averages?.aboveCtr || 0) && (data?.ctr || 0) >= (averages?.belowCtr || 0)) ? 'text-warning' : ''} ${(data?.ctr || 0) < (averages?.belowCtr || 0) ? 'text-danger' : ''}`}>{Math.round(((1 - ((data?.ctr || 0) / (averages?.averageCtr || 0))) * -1) * 100)}%</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <p className="fs-5 text-gray-800 fw-bolder m-0">Impressions</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className={`fw-bolder fs-5 pe-1`}>{data?.impressions?.toLocaleString()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-6">
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <p className="fs-5 text-gray-800 fw-bolder m-0">Clicks</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className={`fw-bolder fs-6 pe-1 m-0`}>{data?.clicks?.toLocaleString()}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center flex-wrap w-100">
                                        <div className="mb-1 pe-3 flex-grow-1">
                                            <p className="fs-5 text-gray-800 fw-bolder m-0">CTR</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <p className={`fw-bolder fs-6 pe-1 m-0`}>{data?.ctr?.toLocaleString()}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <div className="mx-9 mb-9 px-6">
                        <h4 className="text-gray-800">Overall Dates</h4>
                        <p>{data?.overallDate || 'None'}</p>

                        <h4 className="text-gray-800">Notes</h4>
                        <p>{data?.notes || 'None'}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Stat}
