/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import { Link } from 'react-router-dom'

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column shadow' id='kt_footer'>
      <div
        className={`${classes.footerContainer} d-flex flex-row align-items-center justify-content-between`}
      >
        <div className='text-gray-600'>
          <span className='fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <p className='fw-bold d-inline-block mb-0'>
            Mighty
          </p>
        </div>
        
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold'>
          <li className='menu-item'>
            <Link to='/about-us' className='menu-link ps-0 pe-2'>
              About
            </Link>
          </li>
          <li className='menu-item'>
            <Link to='/contact-us' className='menu-link pe-0 pe-2'>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export { Footer }
