import React, {FC,useState,useEffect,useContext} from 'react'
import {fetchProjects} from '../../../app/helpers/remoteData'
import {DigitalCampaign,CampaignStat} from './DigitalWrapper'
import {Stat} from './Stat'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {ClientContext} from '../../providers/ClientProvider'

type Props = {
    archived: boolean
}

const Stats: FC<Props> = ({archived}) => {
    const { setDigitals, digitals } = useContext(RemoteDataContext)
    const { client } = useContext(ClientContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [_averages, setAverages] = useState<CampaignStat>({
        aboveClicks: 0,
        averageClicks: 0,
        belowClicks: 0,
        aboveImpressions: 0,
        averageImpressions: 0,
        belowImpressions: 0,
        aboveCtr: 0,
        averageCtr: 0,
        belowCtr: 0
    })

    useEffect(() => {
        const fetchDigitalProjects = async () => {
            if (client.digital) {
                setIsLoading(true)
                const _projects: DigitalCampaign[] = await fetchProjects(client.digital || '', archived)
                console.log('PROJECTS', _projects)

                let totalImpressions = 0
                let totalClicks = 0
                let totalCtr = 0
                const totalRows = _projects.length

                _projects.map((p) => {
                    if (p.useAverage) {
                        totalImpressions += p.impressions || 0
                        totalClicks += p.clicks || 0
                        totalCtr += p.ctr || 0
                    }
                    return null
                })

                setAverages({
                    aboveClicks: (totalClicks / totalRows) + ((totalClicks / totalRows) * .1),
                    averageClicks: (totalClicks / totalRows),
                    belowClicks: (totalClicks / totalRows) - ((totalClicks / totalRows) * .1),
                    aboveImpressions: (totalImpressions / totalRows) + ((totalImpressions / totalRows) * .1),
                    averageImpressions: (totalImpressions / totalRows),
                    belowImpressions: (totalImpressions / totalRows) - ((totalImpressions / totalRows) * .1),
                    aboveCtr: (totalCtr / totalRows) + ((totalCtr / totalRows) * .1),
                    averageCtr: (totalCtr / totalRows),
                    belowCtr: (totalCtr / totalRows) - ((totalCtr / totalRows) * .1)
                })

                setDigitals(_projects)
                setIsLoading(false)
            }
        }
        fetchDigitalProjects()
    }, [setDigitals, client.digital, archived])

    return (
        <div className='row gy-5 g-xl-8'>
            {
                isLoading ? 
                <div className="text-center pt-5">
                    <p>Ads are loading...</p>
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <>
                    {
                        digitals.length > 0 ?
                            <>
                                {
                                    digitals.map((x, i) => <Stat key={`stat-${i}`} data={x} averages={_averages} />)
                                }
                            </>
                        :
                        null
                    }
                </>
            }
        </div>
    )
}

export {Stats}
