import { useState, useEffect } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import { Autocomplete, createFilterOptions } from '@mui/material'
import { getColumnSearchFromLocalBigQuery, getLifetimeCTRFromLocalBigQuery, getCampaignCTRFromLocalBigQuery } from '../../helpers/remoteData'

function MocentricCTRAverageWidget({ dataset, table, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const [loading, setLoading] = useState(false)
  const [valueLoading, setValueLoading] = useState(false)
  const [value, setValue] = useState(0)
  const [rows, setRows] = useState<Array<any>>([])
  const [campaignName, setCampaignName] = useState('')
  const [ctr, setCtr] = useState(0)

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true)

      try {
        const _rows = await getColumnSearchFromLocalBigQuery(dataset, table)

        if (_rows && _rows.rows) {
          setRows(_rows.rows)
        }

        const _ctr = await getLifetimeCTRFromLocalBigQuery(dataset, table)

        if (_ctr.rows && _ctr.rows.length > 0) {
          setCtr(parseFloat(_ctr.rows[0].average_ctr.toFixed(4)))
        }

        setLoading(false)
      }
      catch (e) {
        console.log('BigQuery error')
        
        setLoading(false)
      }
    }

    _fetch()
  }, [dataset, table])

  useEffect(() => {
    const _fetch = async () => {
      if (campaignName !== '' && ctr > 0) {
        setValueLoading(true)
  
        const _campaignCtr = await getCampaignCTRFromLocalBigQuery(dataset, table, campaignName)
  
        if (_campaignCtr.rows && _campaignCtr.rows.length > 0) {
          const _value = parseFloat(_campaignCtr.rows[0].average_ctr.toFixed(4))
          const newValue = parseFloat((((_value - ctr) / ctr) * 100).toFixed(2))
          setValue(newValue)
        }
  
        setValueLoading(false)
      }
    }

    _fetch()
  }, [dataset, table, campaignName, ctr])

  return (
    <div className='col-12 col-lg-6 mb-8 d-inline-block'>
      <div className='card shadow'>
        {
          isAdmin ? 
            <div className='text-end px-9 pt-5'>
              <div className='card-toolbar'>
                <button
                  type='button'
                  className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                  onClick={() => moveUp(el)}
                >
                  <ArrowUpwardIcon />
                </button>
                <button
                  type='button'
                  className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                  onClick={() => moveDown(el)}
                >
                  <ArrowDownwardIcon />
                </button>
                <button
                  type='button'
                  className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                  data-bs-toggle='modal'
                  data-bs-target='#modal_delete_widget'
                  onClick={() => setSelectedWidget(el)}
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          :
            null
        }
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <div className='card-body d-flex align-items-center justify-content-between'>
              <div className='flex-fill pe-8'>
                <h3 className='card-title align-items-start flex-column mb-5'>
                  <span className='card-label fw-bolder fs-2'>Average to Lifetime CTR</span>
                </h3>
                <Autocomplete
                  freeSolo
                  id='mocentric-average-ctr-autocomplete'
                  options={rows.map((option) => option.custom_id)}
                  noOptionsText='No widgets exist for this query.'
                  filterOptions={createFilterOptions({
                    limit: 5
                  })}
                  onChange={(e, value) => setCampaignName(value)}
                  blurOnSelect={true}
                  renderInput={(params) => 
                    <div ref={params.InputProps.ref}>
                      <input {...params.inputProps} className='form-control form-control-lg form-control-solid' />
                    </div>
                  }
                />
              </div>
              <div>
                <div className='p-10 fs-2 rounded' style={value > 0 ? { backgroundColor: 'rgba(116, 202, 143, 0.43)', color: '#74CA8F' } : value === 0 ? { backgroundColor: '#FFE8BB', color: '#F2C772' } : { backgroundColor: 'rgba(255, 0, 0, 0.20)', color: '#D04141' }}>
                  {
                    valueLoading ? 
                      <div className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    :
                      campaignName !== '' ? 
                        <div className='d-flex align-items-center fw-bolder'>
                          <span>{value}%</span>
                          {
                            value > 0 ? 
                              <ArrowUpwardIcon className='ms-3' />
                            :
                              value < 0 ? 
                                <ArrowDownwardIcon className='ms-3' />
                              :
                                null
                          }
                        </div>
                      :
                        '- -'
                  }
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default MocentricCTRAverageWidget
