import { FC, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import { removeMemberships, removeInvitation } from '../../helpers/remoteData'

type Props = {
  displayName: string
  email: string
  handleSubmit: () => Promise<void>
}

const DeleteUserModal: FC<Props> = ({ displayName, email, handleSubmit }) => {
  const [loading, setLoading] = useState(false)

  const closeRef = useRef<HTMLDivElement>(null)

  const remove = async () => {
    try {
      setLoading(true)

      if (email !== '') {
        await removeMemberships(email)

        await removeInvitation(email)

        await handleSubmit()

        setLoading(false)
        toast.success('User deleted')
        closeRef.current?.click()
      }
    }
    catch (e) {
      toast.error('Unknown error')
      setLoading(false)
    }
  }
  
  return (
    <div className='modal fade' id='modal_delete_user' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Delete User</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body scroll-y py-8'>
            <div className='form-group mb-8'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Email
              </label>
              <div>
                {email}
              </div>
            </div>

            <div className='form-group'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Display Name
              </label>
              <div>
                {displayName}
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-danger px-10'
              onClick={() => remove()}
              disabled={loading}
            >
              {!loading && <span className='indicator-label'>Delete</span>}
              {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DeleteUserModal }
