import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateInvitation, fetchInvitation } from '../../../helpers/remoteData'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import * as auth from '../../auth/redux/AuthRedux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { getUserByToken } from '../../auth/redux/AuthCRUD'

export const NewPassword: FC = () => {
  console.log('Props', auth.actions)
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [passwords, setPasswords] = useState({
    password: '',
    passwordConfirmation: '',
  })
  const __user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel

  const setNewPassword = async () => {
    setLoading(true)
    if (passwords.password === passwords.passwordConfirmation) {
      const newUserData: any = await updateInvitation(
        __user.idToken || '',
        __user.email,
        passwords.password
      )
      setLoading(false)

      // fulfill user
      const { data: user } = await getUserByToken(newUserData.idToken)
      let _user: UserModel = user.users[0]
      const { isAdmin, hasPassword } = await fetchInvitation(_user.email)
      _user.isAdmin = isAdmin
      _user.hasPassword = hasPassword
      _user.idToken = newUserData.idToken
      //dispatch(auth.actions.fulfillUser(_user))

      history.push('/')
    }
  }

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Create Account Password</h1>

        <div className='text-gray-400 fw-bold fs-4'>
          Create a new password that you will use to login from now on.
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          value={passwords.password}
          onChange={(e) => setPasswords({ ...passwords, password: e.target.value })}
          className={`form-control form-control-lg form-control-solid ${
            passwords.password !== passwords.passwordConfirmation ? 'is-invalid' : ''
          }`}
        />
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Password Confirmation</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          value={passwords.passwordConfirmation}
          onChange={(e) => setPasswords({ ...passwords, passwordConfirmation: e.target.value })}
          className={`form-control form-control-lg form-control-solid ${
            passwords.password !== passwords.passwordConfirmation ? 'is-invalid' : ''
          }`}
        />
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button className='btn btn-lg btn-primary fw-bolder me-4' onClick={() => setNewPassword()}>
          {loading ? (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <span className='indicator-label'>Submit</span>
          )}
        </button>
      </div>
    </div>
  )
}
