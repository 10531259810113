import { FC,  useContext, useState, useRef, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { shallowEqual, useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { fetchClient, fetchPagesByClientId, removePage, fetchMemberships } from '../../helpers/remoteData'
import {EditPageModel} from './EditPageModel'
import { ClientContext } from '../../providers/ClientProvider'
import { UserModel } from '../../modules/auth/models/UserModel'
import { RootState } from '../../../setup'
import { Client } from '../admin/ManageClientsWrapper'

type ManagePagesParams = {
  clientId: string
}

export const defaultPage = {
  id: '',
  name: '',
}

const ManagePages: FC = () => {
  let { clientId } = useParams<ManagePagesParams>()

  const [loading, setLoading] = useState<boolean>(false)
  const [rowsLoading, setRowsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<any>(defaultPage)
  const [pageClient, setPageClient] = useState<Client | null>(null)
  const [pages, setPages] = useState<any[]>([])

  const { setClient, setClients } = useContext(ClientContext)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const _resetClients = async () => {
    const memberships: any = await fetchMemberships(user.email)
        
    if (memberships.clients.length > 0) {
      setClients(memberships.clients)

      if (Object.keys(memberships.clients[0]).length > 0) {
        let _client = memberships.clients[0]
        const storageItem = localStorage.getItem('currentClientName')
        if (storageItem) {
          _client = JSON.parse(storageItem)
        }
        else {
          localStorage.setItem('currentClientName', JSON.stringify(_client))
        }

        const client = await fetchClient(_client.id)
        setClient(client)
      }
    }
  }

  const _fetchPages = useCallback(async () => {
    const _page = 0
    setPage(_page)

    setLoading(true)
    setRowsLoading(true)

    const _client: any = await fetchClient(clientId)
    if (Object.keys(_client).length > 0) {
      setPageClient(_client)
    }

    const _pages: any[] = await fetchPagesByClientId(clientId)
    console.log('_pages', _pages)

    if (_pages.length > 0) {
      setPages(_pages)
    }

    setLoading(false)
    setRowsLoading(false)
  }, [clientId])

  const handlePageChange = async (newPage, isPrevious) => {
    setRowsLoading(true)

    const _page = isPrevious ? pages[0] : pages[pages.length - 1]
    const _pages = await fetchPagesByClientId(clientId, _page.doc, isPrevious)

    if (_pages.length > 0) {
      setPages(_pages)
    }

    setRowsLoading(false)
    setPage(newPage)
  }

  const handleSubmit = async () => {
    await _fetchPages()
    await _resetClients()
  }

  useEffect(() => {
    _fetchPages()

    return () => {
      setPageClient(null)
      setPages([])
    }
  }, [_fetchPages])

  return (
    <div className='gy-5 g-xl-8'>
      <h1 className='display-6 mt-0 mb-8 fw-boldest'>Manage Pages</h1>

      <div className='card mb-8 shadow'>
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-2'>Pages for <span className='fw-boldest'>{pageClient?.name}</span></span>
                </h3>
              </div>

              {
                rowsLoading ? 
                  <div className='card-body'>
                    <div className='text-center py-3'>
                      <div className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </div>
                :
                  <div className='card-body py-0'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='min-w-150px'>Name</th>
                            <th>Identifier</th>
                            <th className='text-end'>Actions</th>
                          </tr>
                        </thead>
                        
                        <tbody className='border-0'>
                          {
                            pages.map((row, i) => (
                              <tr key={`client-row-${i}`}>
                                <td>
                                  <div
                                    className='text-dark fw-bolder d-block fs-6 cursor-pointer text-hover-primary'
                                  >
                                    {row.name}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-dark fw-bolder d-block fs-6'>
                                    {row.id}
                                  </div>
                                </td>
                                <td className='text-end'>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                                    onClick={() => toast.info('Coming soon')}
                                  >
                                    <ArrowUpwardIcon />
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                                    onClick={() => toast.info('Coming soon')}
                                  >
                                    <ArrowDownwardIcon />
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                                    data-bs-toggle='modal'
                                    data-bs-target='#modal_edit_page'
                                    onClick={() => setSelectedPage(row)}
                                  >
                                    <EditIcon />
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between ms-2 my-1'
                                    data-bs-toggle='modal'
                                    data-bs-target='#modal_delete_page'
                                    onClick={() => setSelectedPage(row)}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
              }

              <div className='card-footer d-flex align-items-center justify-content-between'>
                <div className='text-muted mt-1 fw-bold fs-7'>
                  Page {page + 1}
                </div>
                <div className='text-end'>
                  {
                    page > 0 && (
                      <button type='button' className={`btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-center ${pages.length >= 10 ? 'me-5' : ''}`} onClick={() => handlePageChange(page - 1, true)}>
                        <ArrowBackIcon fontSize='small' />
                      </button>
                    )
                  }
                  {
                    pages.length >= 10 && (
                      <button type='button' className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-center' onClick={() => handlePageChange(page + 1, false)}>
                        <ArrowForwardIcon fontSize='small' />
                      </button>
                    )
                  }
                </div>
              </div>
            </>
        }
      </div>

      <EditPageModel id={selectedPage.id} name={selectedPage.name} handleSubmit={handleSubmit} />
      <DeletePageModal id={selectedPage.id} name={selectedPage.name} handleSubmit={handleSubmit} />
    </div>
  )
}

type Props = {
  id: string
  name: string
  handleSubmit: () => Promise<void>
}

const DeletePageModal: FC<Props> = ({ id, name, handleSubmit }) => {
  const [loading, setLoading] = useState(false)

  const closeRef = useRef<HTMLDivElement>(null)

  const remove = async () => {
    try {
      setLoading(true)

      await removePage(id)

      await handleSubmit()

      setLoading(false)
      toast.success('Page deleted')
      closeRef.current?.click()
    }
    catch (e) {
      toast.error('Delete page error')
      setLoading(false)
    }
  }

  return (
    <div className='modal fade' id='modal_delete_page' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Delete Page</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body scroll-y py-8'>
            <div className='form-group mb-8'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Name
              </label>
              <div>
                {name}
              </div>
            </div>

            <div className='form-group'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Identifier
              </label>
              <div>
                {id}
              </div>
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-danger px-10'
              onClick={() => remove()}
              disabled={loading}
            >
              {!loading && <span className='indicator-label'>Delete</span>}
              {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                      <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ManagePages}
