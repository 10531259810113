import { useState, useEffect } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { getPaginatedDataFromWidgetRef } from '../../helpers/remoteData'
import AttachmentsModal from './components/AttachmentsModal'

function SmartsheetTVWidget({ reference, searchText, currentCampaign, startDate, endDate, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const [loading, setLoading] = useState(false)
  const [rowsLoading, setRowsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState<Array<any>>([])
  const [attachments, setAttachments] = useState<Array<any>>([])

  useEffect(() => {
    const _fetch = async () => {
      const _page = 0
      setPage(_page)

      setLoading(true)
      setRowsLoading(true)

      const _rows = await getPaginatedDataFromWidgetRef(reference, [], [{ field: 'startDate', direction: 'desc' }])

      if (_rows && _rows.length > 0) {
        console.log('_ROWS', _rows)
        setRows(_rows)
      }

      setLoading(false)
      setRowsLoading(false)
    }

    _fetch()
  }, [reference])

  const handleChangePage = async (newPage: number, isPrevious: boolean) => {
    setRowsLoading(true)

    const _row = isPrevious ? rows[0] : rows[rows.length - 1]
    const _rows = await getPaginatedDataFromWidgetRef(reference, [], [{ field: 'startDate', direction: 'desc' }], _row, isPrevious)

    if (_rows && _rows.length > 0) {
      setRows(_rows)
    }

    setRowsLoading(false)
    setPage(newPage)
  }

  return (
    <div className='col-12 mb-8 d-inline-block'>
      <div className='card shadow'>
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <>
              {
                isAdmin && (
                  <div className='text-end px-9 pt-5'>
                    <div className='card-toolbar'>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        onClick={() => moveUp(el)}
                      >
                        <ArrowUpwardIcon />
                      </button>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        onClick={() => moveDown(el)}
                      >
                        <ArrowDownwardIcon />
                      </button>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        data-bs-toggle='modal'
                        data-bs-target='#modal_delete_widget'
                        onClick={() => setSelectedWidget(el)}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                )
              }

              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-2'>TV/Broadcast Campaigns</span>
                </h3>
              </div>
              
              {
                rowsLoading ? 
                  <div className='card-body'>
                    <div className='text-center py-3'>
                      <div className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </div>
                :
                  rows.length > 0 ? 
                    <>
                      <div className='card-body py-3'>
                        <div className='table-responsive'>
                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                              <tr className='fw-bolder text-muted'>
                                <th className='min-w-150px'>Title</th>
                                <th>Job Code</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Spend</th>
                              </tr>
                            </thead>
                            
                            <tbody className='border-0'>
                              {
                                rows.map((r: any, i: number) => (
                                  <tr key={`smartsheet-tv-row-${i}`}>
                                    <td>
                                      <div
                                        className='text-dark fw-bolder d-block fs-6 text-hover-primary cursor-pointer'
                                        data-bs-toggle='modal'
                                        data-bs-target='#modal_smartsheet_tv_attachments'
                                        onClick={() => {
                                          if (r?.attachments) {
                                            setAttachments(r.attachments)
                                          }
                                          else {
                                            setAttachments([])
                                          }
                                        }}
                                      >
                                        {r?.campaignName}
                                      </div>
                                    </td>
                                    <td>
                                      <div className='text-dark fw-bolder d-block fs-6'>
                                        {r?.jobCode}
                                      </div>
                                    </td>
                                    <td>
                                      <div className='text-dark fw-bolder d-block fs-6'>
                                        {r?.startDate ? moment.unix(r?.startDate.seconds).format('MM/DD/YYYY') : 'No start date'}
                                      </div>
                                    </td>
                                    <td>
                                      <div className='text-dark fw-bolder d-block fs-6'>
                                        {r?.startDate ? moment.unix(r?.startDate.seconds).format('MM/DD/YYYY') : 'No start date'}
                                      </div>
                                    </td>
                                    <td>
                                      <div className='text-dark fw-bolder d-block fs-6'>
                                        {r?.spend && r?.spend.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='card-footer d-flex align-items-center justify-content-between'>
                        <div className='text-muted mt-1 fw-bold fs-7'>
                          Page {page + 1}
                        </div>
                        <div className='text-end'>
                          {
                            page > 0 && (
                              <button type='button' className='btn btn-secondary btn-sm me-5 w-125px d-inline-flex align-items-center justify-content-center' onClick={() => handleChangePage(page - 1, true)}>
                                <ArrowBackIcon fontSize='small' className='me-3' />
                                <span>
                                  Previous
                                </span>
                              </button>
                            )
                          }
                          {
                            rows.length >= 10 && (
                              <button type='button' className='btn btn-secondary btn-sm w-125px d-inline-flex align-items-center justify-content-center' onClick={() => handleChangePage(page + 1, false)}>
                                <span>
                                  Next
                                </span>
                                <ArrowForwardIcon fontSize='small' className='ms-3' />
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </>
                  :
                    <div className='card-body'>
                      <div className='fw-bold mb-4'>No campaigns exist for the current filters</div>
                    </div>
              }
            </>
        }
      </div>

      <AttachmentsModal widget='smartsheet_tv' attachments={attachments} />
    </div>
  )
}

export default SmartsheetTVWidget
