/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useRef } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, createFilterOptions, Chip } from '@mui/material'
import { createInvitation } from '../../helpers/remoteData'
import { addMembership, fetchAllClients } from '../../helpers/remoteData'
import { toast } from 'react-toastify'

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Must be valid email')
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Email is required'),
    displayName: Yup.string()
        .min(3, 'Minimum 3 characters')
        .max(50, 'Maximum 50 characters')
        .required('Display Name is required'),
    role: Yup.string()
        .required('Role is required'),
})
  
const initialValues = {
    email: '',
    displayName: '',
    role: 'user',
}

type Props = {
    handleSubmit: () => Promise<void>
}

const InviteModal: FC<Props> = ({ handleSubmit }) => {
    const [loading, setLoading] = useState(false)
    const [clientsLoading, setClientsLoading] = useState(false)
    const [clients, setClients] = useState<any[]>([])
    const [memberships, setMemberships] = useState<any[]>([])

    const closeRef = useRef<HTMLDivElement>(null)

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
            try {
                setLoading(true)

                await createInvitation(values.email, values.displayName, values.role)
    
                if (memberships.length > 0) {
                    for (let i = 0; i < memberships.length; i++) {
                        const _membership = memberships[i]
                        let id: string = ''

                        if (i > 0) {
                            id = values.email
                        }

                        await addMembership(values.email, _membership.id, _membership.name, id)
                    }
    
                    setMemberships([])

                    await handleSubmit()
                }

                setLoading(false)
                resetForm()
                setMemberships([])
                toast.success('Invitation sent')
                closeRef.current?.click()
            }
            catch (e) {
                setStatus('Unknown error')
                setLoading(false)
                setSubmitting(false)
            }
        },
    })

    useEffect(() => {
        const _fetchClients = async () => {
            setClientsLoading(true)

            const clients = await fetchAllClients()

            setClients(clients)
            setClientsLoading(false)
        }

        _fetchClients()

        return () => setClients([])
    }, [])

    return (
        <div className='modal fade' id='modal_invite_user' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                <div className='modal-content'>
                    {
                        clientsLoading ? 
                            <div className='spinner-border spinner-border-sm' role='status'>
                                <span className='visually-hidden'>Loading...</span>
                            </div>
                        :
                            <form
                                className='form'
                                onSubmit={formik.handleSubmit}
                                noValidate
                            >
                                <div className='modal-header'>
                                    <h2>Invite User</h2>
                                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
                                        <CloseIcon />
                                    </div>
                                </div>

                                <div className='modal-body scroll-y py-8'>
                                    {formik.status && (
                                        <div className='mb-lg-8 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                        </div>
                                    )}

                                    <div className='form-group mb-8'>
                                        <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                                            <span className='required'>Email</span>
                                        </label>
                                        <input
                                            {...formik.getFieldProps('email')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                { 'is-invalid': formik.touched.email && formik.errors.email },
                                                { 'is-valid': formik.touched.email && !formik.errors.email },
                                            )}
                                            name='email'
                                            autoComplete='off'
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container mt-1'>
                                                <span role='alert' className='text-danger'>{formik.errors.email}</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className='form-group mb-8'>
                                        <label className='d-flex align-items-center fs-6 fw-bolder mb-2'>
                                            <span className='required'>Display Name</span>
                                        </label>
                                        <input
                                            {...formik.getFieldProps('displayName')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid',
                                                { 'is-invalid': formik.touched.displayName && formik.errors.displayName },
                                                { 'is-valid': formik.touched.displayName && !formik.errors.displayName },
                                            )}
                                            name='displayName'
                                            autoComplete='off'
                                        />
                                        {formik.touched.displayName && formik.errors.displayName && (
                                            <div className='fv-plugins-message-container mt-1'>
                                                <span role='alert' className='text-danger'>{formik.errors.displayName}</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className='form-group mb-8'>
                                        <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                                            <span className='required'>Role</span>
                                        </label>
                                        <div className="d-flex fv-row">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    {...formik.getFieldProps('role')}
                                                    className='form-check-input me-3'
                                                    name='user_role'
                                                    type='radio'
                                                    value='admin'
                                                    id='modal_invite_user_role_0'
                                                    checked={formik.values.role === 'admin'}
                                                    onChange={() => formik.setFieldValue('role', 'admin')}
                                                />
                                                <label className="form-check-label" htmlFor="modal_invite_user_role_0">
                                                    <div className="fw-bold text-gray-800">Administrator</div>
                                                    <div className="text-gray-600">Best for company administrators and data managers</div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="separator separator-dashed my-3"></div>
                                        <div className="d-flex fv-row">
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    {...formik.getFieldProps('role')}
                                                    className='form-check-input me-3'
                                                    name='user_role'
                                                    type='radio'
                                                    value='user'
                                                    id='modal_invite_user_role_1'
                                                    checked={formik.values.role === 'user'}
                                                    onChange={() => formik.setFieldValue('role', 'user')}
                                                />
                                                <label className="form-check-label" htmlFor="modal_invite_user_role_1">
                                                    <div className="fw-bold text-gray-800">User</div>
                                                    <div className="text-gray-600">Best for clients and those who consume the dashboard data</div>
                                                </label>
                                            </div>
                                        </div>
                                        {formik.touched.role && formik.errors.role && (
                                            <div className='fv-plugins-message-container mt-3'>
                                                <span role='alert' className='text-danger'>{formik.errors.role}</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className='form-group'>
                                        <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                                            Client Access
                                        </label>
                                        <Autocomplete
                                            multiple
                                            id='modal_invite_user_clients'
                                            options={clients}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.name === value.name}
                                            noOptionsText='No widgets exist for this query.'
                                            filterOptions={createFilterOptions({
                                                limit: 5,
                                            })}
                                            onChange={(e, value) => setMemberships(value)}
                                            value={memberships}
                                            blurOnSelect={true}
                                            renderInput={(params) => 
                                                <div ref={params.InputProps.ref}>
                                                    <input {...params.inputProps} className='form-control form-control-lg form-control-solid' />
                                                </div>
                                            }
                                        />
                                        <div className='mt-3'>
                                            {
                                                memberships.map((m, i) => (
                                                    <Chip key={`invite_user_chip_${i}`} label={m.name} onDelete={() => setMemberships(memberships.filter((x) => x.name !== m.name))} className='me-3 mb-3 fw-bold' />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='modal-footer'>
                                    <button
                                        type='submit'
                                        className='btn btn-primary px-10'
                                        disabled={formik.isSubmitting || !formik.isValid}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                <span className='spinner-border spinner-border-sm align-middle'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    )
}

export { InviteModal }
