/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC,useContext,useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router'
import {PageTitle} from '../../../_metronic/layout/core'
import {ClientContext} from '../../providers/ClientProvider'

export interface CampaignStat {
  aboveClicks: number
  averageClicks: number
  belowClicks: number
  aboveImpressions: number
  averageImpressions: number
  belowImpressions: number
  aboveCtr: number
  averageCtr: number
  belowCtr: number
}

export interface Universe {
  campaign?: string
  universe?: string
  sum_impressions?: number
  sum_clicks?: number
  avg_ctr?: number
}

const DashboardPage: FC = () => {
  const history = useHistory()
  const { client } = useContext(ClientContext)

  useEffect(() => {
    console.log('home client', client)
  }, [client])

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        {
          client.pages && client.pages.length > 0 && client.pages.map((c, i) => (
            <div key={`custom-page-card-${i}`} className='col-12 col-md-6 col-xl-4 col-xxl-3'>
              <div className='card shadow cursor-pointer h-100' style={c.backgroundColor ? { background: c.backgroundColor } : {}} onClick={() => history.push(`/custom-page/${c.id}`)}>
                <div className='card-body text-center d-flex flex-center'>
                  <div>
                    {
                      c.imageUrl && (
                        <div className='mb-8'>
                          <img alt='Custom Page' src={c.imageUrl} width='auto' height={70} />
                        </div>
                      )
                    }
                    <div className='fs-2 fw-boldest' style={c.textColor ? { color: c.textColor } : {}}>{c.name}</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
