import { FC, useState, useEffect, useCallback } from 'react'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { fetchInvitations } from '../../helpers/remoteData'
import { InviteModal } from '../../modules/modals/InviteModal'
import { EditUserModal } from '../../modules/modals/EditUserModal'
import { DeleteUserModal } from '../../modules/modals/DeleteUserModal'

export const defaultInvitation = {
  displayName: '',
  email: '',
  isAdmin: false,
}

const AdminWrapper: FC = () => {
  const [loading, setLoading] = useState(false)
  const [rowsLoading, setRowsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [invitations, setInvitations] = useState<any[]>([])
  const [selectedInvitation, setSelectedInvitation] = useState<any>(defaultInvitation)

  const _fetchInvitations = useCallback(async () => {
    const _page = 0
    setPage(_page)

    setLoading(true)
    setRowsLoading(true)

    const _invitations = await fetchInvitations()
    setInvitations(_invitations)

    setLoading(false)
    setRowsLoading(false)
  }, [])

  useEffect(() => {
    _fetchInvitations()

    return () => setInvitations([])
  }, [_fetchInvitations])

  const handlePageChange = async (newPage, isPrevious) => {
    setRowsLoading(true)

    const _invitation = isPrevious ? invitations[0] : invitations[invitations.length - 1]
    const _invitations = await fetchInvitations(_invitation.doc, isPrevious)

    if (_invitations && _invitations.length > 0) {
      setInvitations(_invitations)
    }

    setRowsLoading(false)
    setPage(newPage)
  }

  return (
    <div className='gy-5 g-xl-8'>
      <h1 className='display-6 mt-0 mb-8 fw-boldest'>Manage Invitations</h1>

      <div className='card mb-8 shadow'>
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <>
              <div className='card-header border-0'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-2'>Invitations</span>
                </h3>

                <div className='card-toolbar'>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm d-flex align-items-center justify-content-center'
                    data-bs-toggle='modal'
                    data-bs-target='#modal_invite_user'
                  >
                    <AddIcon fontSize='small' className='me-2' />
                    <span>Invite</span>
                  </button>
                </div>
              </div>

              {
                rowsLoading ? 
                  <div className='card-body'>
                    <div className='text-center py-3'>
                      <div className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </div>
                :
                  <div className='card-body py-0'>
                    <div className='table-responsive'>
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='min-w-150px'>Display Name</th>
                            <th>Email</th>
                            <th className='text-end'>Actions</th>
                          </tr>
                        </thead>
                        
                        <tbody className='border-0'>
                          {
                            invitations.map((row, i) => (
                              <tr key={`invitation-row-${i}`}>
                                <td>
                                  <div
                                    className='text-dark fw-bolder d-block fs-6 cursor-pointer text-hover-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#modal_edit_user'
                                    onClick={() => setSelectedInvitation({
                                      displayName: row.displayName,
                                      email: row.email,
                                      isAdmin: row.isAdmin,
                                    })}
                                  >
                                    {row.displayName}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-dark fw-bolder d-block fs-6'>
                                    {row.email}
                                  </div>
                                </td>
                                <td className='text-end'>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                                    data-bs-toggle='modal'
                                    data-bs-target='#modal_edit_user'
                                    onClick={() => setSelectedInvitation({
                                      displayName: row.displayName,
                                      email: row.email,
                                      isAdmin: row.isAdmin,
                                    })}
                                  >
                                    <EditIcon />
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between ms-2 my-1'
                                    data-bs-toggle='modal'
                                    data-bs-target='#modal_delete_user'
                                    onClick={() => setSelectedInvitation({
                                      displayName: row.displayName,
                                      email: row.email,
                                      isAdmin: row.isAdmin,
                                    })}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
              }

              <div className='card-footer d-flex align-items-center justify-content-between'>
                <div className='text-muted mt-1 fw-bold fs-7'>
                  Page {page + 1}
                </div>
                <div className='text-end'>
                  {
                    page > 0 && (
                      <button type='button' className={`btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-center ${invitations.length >= 10 ? 'me-5' : ''}`} onClick={() => handlePageChange(page - 1, true)}>
                        <ArrowBackIcon fontSize='small' />
                      </button>
                    )
                  }
                  {
                    invitations.length >= 10 && (
                      <button type='button' className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-center' onClick={() => handlePageChange(page + 1, false)}>
                        <ArrowForwardIcon fontSize='small' />
                      </button>
                    )
                  }
                </div>
              </div>
            </>
        }
      </div>

      <InviteModal handleSubmit={_fetchInvitations} />
      <EditUserModal displayName={selectedInvitation.displayName} email={selectedInvitation.email} isAdmin={selectedInvitation.isAdmin} handleSubmit={_fetchInvitations} />
      <DeleteUserModal displayName={selectedInvitation.displayName} email={selectedInvitation.email} handleSubmit={_fetchInvitations} />
    </div>
  )
}

export { AdminWrapper }
