/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {RequestInvite} from './components/RequestInvite'
import {ResetPassword} from './components/ResetPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid'
      style={{
        background: `url(${toAbsoluteUrl('/custom/auth_background.svg')}), linear-gradient(180deg, #25AAE1 0%, rgba(37, 170, 225, 0.17) 66.15%, rgba(37, 170, 225, 0.00) 100%)`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 overflow-hidden'>
        <div className='position-relative w-100 w-md-500px rounded shadow-sm p-10 p-lg-15 mx-auto' style={{backgroundColor: '#1B1B27'}}>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/request' component={RequestInvite} />
            <Route path='/auth/reset' component={ResetPassword} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>

          <div className='position-absolute d-none d-lg-inline-block' style={{bottom: '-180px', right: '-385px'}}>
            <img src={toAbsoluteUrl('/custom/login_graphic.gif')} alt="Dashboard Animation" width={500} height='auto' />
          </div>
        </div>

        <div className='mt-15'>
          <div className='mb-1 text-center text-primary text-uppercase fw-bolder fs-6'>Powered By</div>
          <img src={toAbsoluteUrl('/media/logos/mighty-logo-full.png')} alt='Powered By Mighty' className='h-70px' />
        </div>
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}
