import React from 'react'
import ReactDOM from 'react-dom'

// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import * as _redux from './setup'
import store, { persistor } from './setup/redux/Store'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'

import 'bootstrap'

import { RemoteDataProvider } from './app/providers/RemoteDataProvider'
import { NotificationProvider } from './app/providers/NotificationProvider'
import { ClientProvider } from './app/providers/ClientProvider'

// Firebase
import { initializeApp } from 'firebase/app'

// Apps
import { App } from './app/App'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

// /* const mock = */ _redux.mockAxios(axios)
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

const firebaseConfig = {
  apiKey: 'AIzaSyDtcG2sDgxOEDaPLbrlkARS4Mm1wuusBXE',
  authDomain: 'mighty-apps.firebaseapp.com',
  projectId: 'mighty-apps',
  storageBucket: 'mighty-apps.appspot.com',
  messagingSenderId: '358735485871',
  appId: '1:358735485871:web:c73ef7cd6f7c3663b86d5a',
  measurementId: 'G-LVMYRCZC2V',
}

initializeApp(firebaseConfig)

ReactDOM.render(
  <MetronicI18nProvider>
    <NotificationProvider>
      <ClientProvider>
        <RemoteDataProvider>
          <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
              <App basename={PUBLIC_URL} />
              <ToastContainer />
            </PersistGate>
          </Provider>
        </RemoteDataProvider>
      </ClientProvider>
    </NotificationProvider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
