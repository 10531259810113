import React, {useContext, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {DeleteDigitalModal} from '../../app/modules/modals/DeleteDigitalModal'
import {DeleteCableModal} from '../../app/modules/modals/DeleteCableModal'
import {DeleteMailModal} from '../../app/modules/modals/DeleteMailModal'
import {MailGalleryModal} from '../../app/modules/modals/MailGalleryModal'
import {CableGalleryModal} from '../../app/modules/modals/CableGalleryModal'
import {DigitalGalleryModal} from '../../app/modules/modals/DigitalGalleryModal'
import {ClientContext} from '../../app/providers/ClientProvider'
import {fetchMemberships,fetchClient, fetchInvitation} from '../../app/helpers/remoteData'
import {UserModel} from '../../app/modules/auth/models/UserModel'
import {RootState} from '../../setup'

const MasterLayout: React.FC = ({children}) => {
  const { setClient, setClients } = useContext(ClientContext)

  const [loading, setLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    const _fetch = async () => {
      if (user) {
        console.log('_USER', user)

        setLoading(true)
        const memberships: any = await fetchMemberships(user.email)
        
        if (memberships.clients.length > 0) {
          setClients(memberships.clients)

          if (Object.keys(memberships.clients[0]).length > 0) {
            let _client = memberships.clients[0]
            const storageItem = localStorage.getItem('currentClientName')
            if (storageItem) {
              _client = JSON.parse(storageItem)
            }
            else {
              localStorage.setItem('currentClientName', JSON.stringify(_client))
            }

            const client = await fetchClient(_client.id)
            setClient(client)

            const invitation = await fetchInvitation(user.email)
      
            if (invitation) {
              setIsAdmin(invitation.isAdmin)
            }
          }
        }

        setLoading(false)
      }
    }
    _fetch()
  }, [user, setClient, setClients])

  return (
    <PageDataProvider>
      {
        loading ? 
          <div className="text-center loading-dots-content">
            <div className="loading-dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        :
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault isAdmin={isAdmin} />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
              <Footer />
            </div>
          </div>
      }

      <DeleteDigitalModal />
      <DeleteCableModal />
      <DeleteMailModal />
      <MailGalleryModal />
      <CableGalleryModal />
      <DigitalGalleryModal />      

      <MasterInit />
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
