import React, {FC,useContext} from 'react'
import {MailCampaign} from './MailWrapper'
import {KTSVG} from '../../../_metronic/helpers'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../modules/auth/models/UserModel'
import {RootState} from '../../../setup'
import {ClientContext} from '../../providers/ClientProvider'
import {updateProject} from '../../helpers/remoteData'

type Props = {
    data?: MailCampaign
}

const Stat: FC<Props> = ({data}) => {
    const { client } = useContext(ClientContext)
    const { setCurrentMail, mails, setMails } = useContext(RemoteDataContext)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    
    const sendToFront = async () => {
        if (client.mail && data?.id) {
            const index = mails.map((d) => d.id).indexOf(data.id)
            await updateProject(client.mail, data.id, {})
            let _mails = Array.from(mails)
            _mails[index] = {
                ..._mails[index],
                updatedAt: new Date().getTime()/1000
            }
            _mails.sort((a, b) => {
                return a.updatedAt && b.updatedAt ? b.updatedAt - a.updatedAt : 0
            })
            console.log('Sorted Digital', _mails.map((d) => d.updatedAt))
            setMails(_mails)
        }
    }

    return (
        <div className="col-xl-4">
            <div className="card card-xl-stretch mb-xl-8 shadow">
                <div className="card-body p-0">
                    <div className="px-9 pt-7 card-rounded w-100 bg-primary">
                        {
                            user.isAdmin ? 
                            <div className="text-right mb-3" style={{textAlign: 'right'}}>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    onClick={() => {
                                        sendToFront()
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/abstract/abs027.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_new_mail_campaign"
                                    onClick={() => {
                                        console.log('MAIL DATA', data)
                                        setCurrentMail(data || {})
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen055.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                                <p
                                    className='btn btn-sm btn-flex btn-primary btn-active-light fw-bolder m-0 p-3'
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_delete_mail_campaign"
                                    onClick={() => {
                                        setCurrentMail(data || {})
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-5 svg-icon-gray-500 m-0'
                                    />
                                </p>
                            </div>
                            : null
                        }

                        <div className="d-flex flex-stack mb-4">
                            <h3 className="m-0 text-white fw-bolder fs-3">{data?.name}</h3>
                        </div>
                        
                        <div className="text-center h-250px d-flex align-items-center justify-content-center">
                            {
                                data?.art && data.art.length > 0 && data.art[0].includes('.mp4') ? 
                                <video height="250" controls style={{maxWidth: '100%'}}>
                                    <source src={data?.art[0]} type="video/mp4" />
                                </video>
                                :
                                <img alt="Project Media" src={data?.art && data.art.length > 0 ? data?.art[0] : ''} style={{maxHeight: '250px', maxWidth: '100%'}} />
                            }
                        </div>

                        <div>
                            {
                                data?.art && data.art.length > 1 ? 
                                <p data-bs-toggle="modal" data-bs-target="#kt_modal_mail_gallery" className="m-0 mt-6 text-white text-hover-secondary text-center fw-bold" style={{cursor: 'pointer'}} onClick={() => { setCurrentMail(data || {}) }}>Show {data.art.length - 1} More</p>
                                : null
                            }
                        </div>
                        
                        <div className="d-flex text-center flex-column text-white pt-8" style={{paddingBottom: '130px'}}>
                            <span className="fw-bold fs-7">Dates</span>
                            <span className="fw-bolder fs-2 pt-1">{data?.dropDate || 'TBD'}</span>
                        </div>
                    </div>
                    <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{marginTop: '-100px'}}>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Household Count</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="fw-bolder fs-5 text-gray-800 pe-1">{data?.householdCount?.toLocaleString()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-6">
                            <div className="d-flex align-items-center flex-wrap w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Cost</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="fw-bolder fs-5 text-gray-800 pe-1">${ data?.cost ? data?.cost?.toLocaleString(undefined, {minimumFractionDigits: 2}) : 0}</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="w-100">
                                <div className="mb-1 pe-3 flex-grow-1">
                                    <p className="fs-5 text-gray-800 fw-bolder m-0">Piece Name</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="fs-6 text-gray-800 pe-1 m-0">{data?.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mx-9 mb-9 px-6">
                        <h4 className="text-gray-800">Overall Dates</h4>
                        <p>{data?.overallDate || 'None'}</p>

                        <h4 className="text-gray-800">Notes</h4>
                        <p>{data?.notes || 'None'}</p>
                    </div>
                </div>
            </div>
    </div>
    )
}

export {Stat}
