import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import CloseIcon from '@mui/icons-material/Close'
import { ClientContext } from '../../../../app/providers/ClientProvider'
import { addPageToClient, fetchClient } from '../../../../app/helpers/remoteData'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Page Name is required'),
})

const initialValues = {
  name: '',
}

function AddPageModel() {
  const [loading, setLoading] = useState(false)

  const { client, setClient } = useContext(ClientContext)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        setLoading(true)

        await addPageToClient(client.id, values.name, '', '', '')

        const _client = await fetchClient(client.id)
        setClient(_client)

        setLoading(false)
        resetForm()
        document?.getElementById('close_modal_add_page')?.click()
        toast.success('Page created')
      }
      catch (e) {
        setStatus('Unknown Error')
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='modal fade' id='modal_add_page_to_client' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <form
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='modal-header border-gray-300'>
              <h2>Add Page</h2>
              <div id='close_modal_add_page' className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <CloseIcon />
              </div>
            </div>

            <div className='modal-body scroll-y py-8'>
              {formik.status && (
                <div className='mb-lg-8 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='form-group mb-8'>
                <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                  <span className='required'>Client Name</span>
                </label>
                <div>
                  {client?.name}
                </div>
              </div>
              
              <div className='form-group'>
                <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                  <span className='required'>Page Name</span>
                </label>
                <input
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    { 'is-invalid': formik.touched.name && formik.errors.name },
                    { 'is-valid': formik.touched.name && !formik.errors.name },
                  )}
                  name='name'
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container mt-1'>
                    <span role='alert' className='text-danger'>{formik.errors.name}</span>
                  </div>
                )}
              </div>
            </div>

            <div className='modal-footer border-gray-300'>
              <button
                type='submit'
                className='btn btn-primary px-10'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddPageModel
