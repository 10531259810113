/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { firebaseSignOut } from '../../../../app/modules/auth/redux/AuthCRUD'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const logout = async () => {
    try {
      await firebaseSignOut()
    } catch (e) {
      alert('Logout error')
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column px-5'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <span>
                {user.displayName}
              </span>
              <KTSVG path='/media/icons/duotune/general/gen026.svg' className='ms-2 svg-icon-2 svg-icon-primary' />
            </div>
            <p className='fw-bold text-muted fs-7 mb-0'>
              {user.email}
            </p>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 my-1 cursor-pointer'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5 cursor-pointer'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
