/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

function AboutUs(props) {
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        background: `url(${toAbsoluteUrl('/custom/auth_background.svg')}), linear-gradient(180deg, #25AAE1 0%, rgba(37, 170, 225, 0.17) 66.15%, rgba(37, 170, 225, 0.00) 100%)`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px rounded shadow-sm p-10 p-lg-15 mx-auto' style={{backgroundColor: '#1B1B27'}}>
          <div className='text-center mb-10'>
            <h1 className='text-white mb-3'>About Us</h1>
            <div>
              <p className='mt-14 text-white'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto
                facere hic labore laboriosam magni repellendus ullam? Adipisci alias, dolorem
                ducimus expedita incidunt ipsa ipsam necessitatibus numquam quas repudiandae sequi!
              </p>
              <p className='text-white'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto
                facere hic labore laboriosam magni repellendus ullam? Adipisci alias, dolorem
                ducimus expedita incidunt ipsa ipsam necessitatibus numquam quas repudiandae sequi!
              </p>
              <p className='mb-0 text-white'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium architecto
                facere hic labore laboriosam magni repellendus ullam? Adipisci alias, dolorem
                ducimus expedita incidunt ipsa ipsam necessitatibus numquam quas repudiandae sequi!
              </p>
            </div>
          </div>
        </div>

        <div className='mt-15'>
          <div className='mb-1 text-center text-primary text-uppercase fw-bolder fs-6'>Powered By</div>
          <img src={toAbsoluteUrl('/media/logos/mighty-logo-full.png')} alt='Powered By Mighty' className='h-70px' />
        </div>
      </div>
      
      <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1 flex-center p-10'>
        <li className='menu-item'>
          <Link to='/auth/login' className='menu-link pe-0 pe-2'>
            Login
          </Link>
        </li>
        <li className='menu-item'>
          <Link to='/contact-us' className='menu-link pe-0 pe-2'>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default AboutUs
