import axios from 'axios'
import { getAuth } from 'firebase/auth'
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  query,
  collection,
  limit,
  limitToLast,
  getDocs,
  updateDoc,
  orderBy,
  startAfter,
  addDoc,
  deleteDoc,
  arrayRemove,
  arrayUnion,
  where,
  serverTimestamp,
  endBefore,
} from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import moment from 'moment'
import { FacebookCampaign } from '../pages/facebook/FacebookWrapper'
import { updatePassword } from '../modules/auth/redux/AuthCRUD'
import { Client } from '../pages/admin/ManageClientsWrapper'
import { DBUserModel } from '../modules/auth/models/UserModel'

const firebaseConfig = {
  apiKey: 'AIzaSyDtcG2sDgxOEDaPLbrlkARS4Mm1wuusBXE',
  authDomain: 'mighty-apps.firebaseapp.com',
  projectId: 'mighty-apps',
  storageBucket: 'mighty-apps.appspot.com',
  messagingSenderId: '358735485871',
  appId: '1:358735485871:web:c73ef7cd6f7c3663b86d5a',
  measurementId: 'G-LVMYRCZC2V',
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)

export const fetchCampaignNames = async () => {
  try {
    const response = await axios.post(process.env.REACT_APP_FETCH_CAMPAIGNS_TRIGGER || '', {
      dataset: process.env.REACT_APP_DATASET,
      table: process.env.REACT_APP_TABLE,
      select: 'name',
      where: 'GROUP BY name',
    })
    return response.data
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const fetchCableNames = async () => {
  try {
    const response = await axios.post(process.env.REACT_APP_FETCH_CAMPAIGNS_TRIGGER || '', {
      dataset: process.env.REACT_APP_DATASET,
      table: process.env.REACT_APP_CABLE_TABLE,
      select: 'dma',
      where: 'GROUP BY dma',
    })
    return response.data
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const fetchCampaignStats = async (campaignName: string) => {
  try {
    const response = await axios.post(process.env.REACT_APP_FETCH_CAMPAIGNS_TRIGGER || '', {
      dataset: process.env.REACT_APP_DATASET,
      table: process.env.REACT_APP_TABLE,
      select:
        'name, universe, SUM(impressions) AS sum_impressions, SUM(clicks) AS sum_clicks, AVG(ctr) AS avg_ctr',
      where: `WHERE name = '${campaignName}' GROUP BY universe, name`,
    })
    return response.data
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const fetchCampaignAds = async (universeName: string) => {
  try {
    const response = await axios.post(process.env.REACT_APP_FETCH_CAMPAIGNS_TRIGGER || '', {
      dataset: process.env.REACT_APP_DATASET,
      table: process.env.REACT_APP_TABLE,
      select: '*',
      where: `WHERE universe = '${universeName}'`,
    })
    return response.data
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const fetchCableMedia = async () => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'commonwealthFoundation', 'cableMedia')
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      console.log('No such document!')
      return {}
    }
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const fetchFacebookCampaigns = async (id: string, secret: string, adAccountId: string) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_FETCH_FACEBOOK_CAMPAIGNS_TRIGGER || '',
      {
        FB_ID: id,
        FB_SECRET: secret,
        AD_ACCOUNT_ID: adAccountId,
      }
    )
    return response.data
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const fetchFacebookAds = async (id: string, secret: string, campaignId: string) => {
  let formatted = [
    {
      clicks: 0,
      impressions: 0,
      ctr: 0,
      reach: 0,
      inline_post_engagement: 0,
      ad_name: '',
      ad_id: '',
      date_start: new Date(),
      date_stop: new Date(),
      thumbnail_url: '',
    },
  ]

  try {
    const response = await axios.post(process.env.REACT_APP_FETCH_FACEBOOK_TRIGGER || '', {
      FB_ID: id,
      FB_SECRET: secret,
      CAMPAIGN_ID: campaignId,
      date_preset: 'last_30d',
    })
    const insights = response.data.insights

    for (let i = 0; i < insights.length; i++) {
      const insight = insights[i]
      let _insight: FacebookCampaign = {
        clicks: 0,
        impressions: 0,
        ctr: 0,
        reach: 0,
        inline_post_engagement: 0,
        ad_name: '',
        ad_id: '',
        date_start: new Date(),
        date_stop: new Date(),
        thumbnail_url: '',
      }

      _insight.clicks = parseInt(insight.clicks)
      _insight.impressions = parseInt(insight.impressions)
      _insight.ctr = parseFloat(insight.ctr)
      _insight.reach = parseInt(insight.reach)
      _insight.inline_post_engagement = parseInt(insight.inline_post_engagement)
      _insight.ad_id = insight.ad_id
      _insight.ad_name = insight.ad_name
      _insight.date_start = new Date(insight.date_start)
      _insight.date_stop = new Date(insight.date_stop)
      _insight.thumbnail_url = insight.thumbnail_url

      if (i === 0) {
        formatted.shift()
      }
      formatted.push(_insight)
    }

    return { rows: formatted }
  } catch (e) {
    console.log('error', e)
    return { rows: formatted }
  }
}

export const updateUser = async (idToken: string, displayName: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts:update?key=${process.env.REACT_APP_WEB_API_KEY}` ||
        '',
      {
        idToken,
        displayName,
      }
    )
    console.log('UPDATE USER', response.data)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const removeUser = async (idToken: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts:delete?key=${process.env.REACT_APP_WEB_API_KEY}` ||
        '',
      {
        idToken,
      }
    )
    console.log('DELETE USER', response.data)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const fetchInvitations = async (_invitation?: any, isPrevious: boolean = false) => {
  try {
    let invitations: any[] = []
    const db = getFirestore(app)

    let q = query(collection(db, 'invitations'), orderBy('createdAt', 'desc'))

    if (_invitation) {
      if (isPrevious) {
        q = query(collection(db, 'invitations'), orderBy('createdAt', 'desc'), endBefore(_invitation), limitToLast(10))
      }
      else {
        q = query(collection(db, 'invitations'), orderBy('createdAt', 'desc'), startAfter(_invitation), limit(10))
      }
    }
    else {
      q = query(collection(db, 'invitations'), orderBy('createdAt', 'desc'), limit(10))
    }

    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc: any) => {
        const _data = doc.data()
        invitations.push({
          ..._data,
          email: doc.id,
          doc: doc,
        })
      })
    }

    return invitations
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const createInvitation = async (email: string, displayName: string, role: string) => {
  try {
    const db = getFirestore(app)
    await setDoc(doc(db, 'invitations', email), {
      hasPassword: false,
      isAdmin: role === 'admin' ? true : false,
      displayName,
      createdAt: serverTimestamp(),
    })

    const tmpPassword = Math.random().toString(36).slice(-8)
    await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts:signUp?key=${process.env.REACT_APP_WEB_API_KEY}`,
      {
        email,
        displayName,
        password: tmpPassword,
      }
    )

    const emailData = {
      email,
      displayName,
      tmpPassword,
      appUrl: process.env.REACT_APP_HOST_URL,
    }
    const email_response = await axios.post(
      `${process.env.REACT_APP_INVITE_TRIGGER}`,
      JSON.stringify(emailData),
      {
        transformRequest: [
          (data, headers) => {
            delete headers.post['Content-Type']
            return data
          },
        ],
      }
    )
    console.log('EMAIL RESPONSE: ', email_response.data)

    console.log('Invitation created and sent!')
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const updateInvitationByEmail = async (email: string, displayName: string, role: string) => {
  try {
    const db = getFirestore(app)
    
    await updateDoc(doc(db, 'invitations', email), {
      displayName,
      isAdmin: role === 'admin' ? true : false,
    })
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const removeInvitation = async (email: string) => {
  try {
    const db = getFirestore(app)
    const docRef = doc(db, 'invitations', email)

    await deleteDoc(docRef)
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const sendResetCode = async (email: string) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts:sendOobCode?key=${process.env.REACT_APP_WEB_API_KEY}`,
      {
        requestType: 'PASSWORD_RESET',
        email,
      }
    )
    return res.data
  } catch (e) {
    console.log('error', e)
    return null
  }
}

export const resetPassword = async (oobCode: string, newPassword?: string) => {
  try {
    let data: any = {
      oobCode,
    }

    if (newPassword) {
      data['newPassword'] = newPassword
    }

    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/accounts:resetPassword?key=${process.env.REACT_APP_WEB_API_KEY}`,
      data
    )
    return res.data
  } catch (e) {
    console.log('error', e)
    return {
      error: e,
    }
  }
}

export const fetchInvitation = async (email: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'invitations', email)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    }
    else {
      console.log('No such document!')
      return {}
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const updateInvitation = async (idToken: string, email: string, password: string) => {
  try {
    const passwordResponse = await updatePassword(idToken, password)
    console.log('P RES', passwordResponse.data)

    const db = getFirestore(app)
    await updateDoc(doc(db, 'invitations', email), {
      hasPassword: true,
    })
    console.log('User updated!')
    return passwordResponse.data
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const sendInviteToAdmin = async (email: string) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_REQUEST_INVITE_TRIGGER || '',
      JSON.stringify({ email }),
      {
        transformRequest: [
          (data, headers) => {
            delete headers.post['Content-Type']
            return data
          },
        ],
      }
    )
    return response.data
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const uploadImage = async (file: any, onUpload: (prog: number, index: number) => void, index: number = -1) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const res = await axios.post('/api/uploads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        console.log('Progress Event', progressEvent)
        const { loaded, total } = progressEvent
        const progress = Math.round((loaded * 100) / total)
        console.log('PROGRESS', progress)
        onUpload(progress, index)
      },
    })

    console.log(res.data)
    return res.data
  }
  catch (e) {
    console.log(e)
  }
}

export const uploadAvatar = async (file: any, onUpload: (prog: number, index: number) => void, index: number = -1) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const res = await axios.post('/api/avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        console.log('Progress Event', progressEvent)
        const { loaded, total } = progressEvent
        const progress = Math.round((loaded * 100) / total)
        console.log('PROGRESS', progress)
        onUpload(progress, index)
      },
    })

    console.log(res.data)
    return res.data
  }
  catch (e) {
    console.log(e)
  }
}

export const uploadMedia = (file: any, onUpload: (prog: number, index: number) => void, index: number = -1) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', file)

    axios.post('/api/uploads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        console.log('Progress Event', progressEvent)
        const { loaded, total } = progressEvent
        const progress = Math.round((loaded * 100) / total)
        console.log('PROGRESS', progress)
        onUpload(progress, index)
      },
    })
    .then((res) => {
      console.log('IMAGE DATA', res.data)
      resolve(res.data)
    })
    .catch((e) => {
      console.log(e)
      reject(e)
    })
  })
}

export const fetchProjects = async (table: string, isArchived: boolean = false) => {
  try {
    const db = getFirestore(app)
    let _docs: any[] = []

    const q = query(
      collection(db, table),
      where('isArchived', '==', isArchived),
      orderBy('updatedAt', 'desc'),
      limit(20)
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc: any) => {
      const _data = doc.data()
      _docs.push({
        ..._data,
        id: doc.id,
        doc: doc,
      })
    })

    return _docs
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const fetchProject = async (table: string, id: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, table, id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      console.log('No such document!')
      return {}
    }
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const createProject = async (table: string, data: any) => {
  try {
    const db = getFirestore(app)

    const docRef = await addDoc(collection(db, table), {
      ...data,
      createdAt: new Date().getTime() / 1000,
      updatedAt: new Date().getTime() / 1000,
    })
    console.log('Document written with ID: ', docRef.id)

    return docRef
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const updateProject = async (table: string, id: string, data: any) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, table, id)
    await updateDoc(docRef, {
      ...data,
      updatedAt: new Date().getTime() / 1000,
    })
    console.log('Updated data!')

    return docRef
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const deleteProject = async (table: string, id: string) => {
  try {
    const db = getFirestore(app)

    await deleteDoc(doc(db, table, id))
    console.log(`Doc ${id} deleted!`)

    return id
  } catch (e) {
    console.log('error', e)
    return ''
  }
}

export const fetchMemberships = async (email: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'memberships', email)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      console.log('No such document!')
      return {}
    }
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const addMembership = async (
  email: string,
  clientId: string,
  clientName: string,
  id?: string
) => {
  try {
    const db = getFirestore(app)
    const docRef = doc(db, 'memberships', email)

    if (id) {
      await updateDoc(docRef, {
        clients: arrayUnion({
          id: clientId,
          name: clientName,
        }),
      })
      console.log('Updated data!')

      return docRef
    }
    else {
      await setDoc(docRef, {
        clients: [
          {
            id: clientId,
            name: clientName,
          },
        ],
      })
      console.log('Document written with ID: ', docRef.id)

      return docRef
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const removeMemberships = async (email: string) => {
  try {
    const db = getFirestore(app)
    const docRef = doc(db, 'memberships', email)

    await deleteDoc(docRef)
  }
  catch (e) {
    console.log('error', e)
  }
}

export const removeMembershipsByClient = async (clientPartial: any) => {
  try {
    const db = getFirestore(app)
    
    const q = query(collection(db, 'memberships'), where('clients', 'array-contains', clientPartial))
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size > 0) {
      querySnapshot.forEach(async (_doc: any) => {
        const _data = _doc.data()
        let _clients = _data.clients
        const _clientIndex = _clients.findIndex((_c) => _c.id === clientPartial.id)

        if (_clientIndex > -1 ) {
          _clients.splice(_clientIndex, 1)

          await updateDoc(doc(db, 'memberships', _doc.id), {
            clients: _clients,
          })
        }
      })
    }
  }
  catch (e) {
    console.log('error', e)
  }
}

export const updateMembershipsByClient = async (clientPartial: any, newClientName: string) => {
  try {
    const db = getFirestore(app)
    
    const q = query(collection(db, 'memberships'), where('clients', 'array-contains', clientPartial))
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size > 0) {
      querySnapshot.forEach(async (_doc: any) => {
        const _data = _doc.data()
        let _clients = _data.clients
        const _clientIndex = _clients.findIndex((_c) => _c.id === clientPartial.id)

        if (_clientIndex > -1 ) {
          const _client = _clients[_clientIndex]
          _client.name = newClientName

          await updateDoc(doc(db, 'memberships', _doc.id), {
            clients: _clients,
          })
        }
      })
    }
  }
  catch (e) {
    console.log('error', e)
  }
}

export const updateMemberships = async (email: string, clients: any[]) => {
  try {
    const db = getFirestore(app)
    const docRef = doc(db, 'memberships', email)

    await setDoc(docRef, {
      clients,
    })
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const fetchPage = async (id: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'pages', id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const data = docSnap.data()

      return {
        ...data,
        id,
      }
    }
    else {
      console.log('No such page')
      return {}
    }
  }
  catch (e) {
    console.log('Fetch Page Error', e)
    return {}
  }
}

export async function updatePage(id: string, data: any) {
  try {
    const db = getFirestore(app)

    await updateDoc(doc(db, 'pages', id), { ...data })
    
    return data
  }
  catch (e) {
    console.log('Update Page Error', e)
    return {}
  }
}

export const fetchPagesByClientId = async (clientId: string, _page?: any, isPrevious: boolean = false) => {
  try {
    let pages: any[] = []
    const db = getFirestore(app)

    let q = query(collection(db, 'pages'), where('client', '==', clientId), orderBy('createdAt', 'desc'))

    if (_page) {
      if (isPrevious) {
        q = query(collection(db, 'pages'), where('client', '==', clientId), orderBy('createdAt', 'desc'), endBefore(_page), limitToLast(10))
      }
      else {
        q = query(collection(db, 'pages'), where('client', '==', clientId), orderBy('createdAt', 'desc'), startAfter(_page), limit(10))
      }
    }
    else {
      q = query(collection(db, 'pages'), where('client', '==', clientId), orderBy('createdAt', 'desc'), limit(10))
    }

    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc: any) => {
        const _data = doc.data()
        pages.push({
          ..._data,
          id: doc.id,
          doc: doc,
        })
      })
    }

    return pages
  } 
  catch (e) {
    console.log('Fetch Pages By Client ID Error', e)
    return []
  }
}

export const fetchClients = async (_client?: any, isPrevious: boolean = false) => {
  try {
    let clients: any[] = []
    const db = getFirestore(app)

    let q = query(collection(db, 'clients'), orderBy('name', 'asc'))

    if (_client) {
      if (isPrevious) {
        q = query(collection(db, 'clients'), orderBy('name', 'asc'), endBefore(_client), limitToLast(10))
      }
      else {
        q = query(collection(db, 'clients'), orderBy('name', 'asc'), startAfter(_client), limit(10))
      }
    }
    else {
      q = query(collection(db, 'clients'), orderBy('name', 'asc'), limit(10))
    }

    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc: any) => {
        const _data = doc.data()
        clients.push({
          ..._data,
          id: doc.id,
          doc: doc,
        })
      })
    }

    return clients
  } 
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const fetchAllClients = async () => {
  try {
    let clients: any[] = []
    const db = getFirestore(app)

    const q = query(collection(db, 'clients'), orderBy('name', 'asc'))

    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      querySnapshot.forEach((doc: any) => {
        const _data = doc.data()
        clients.push({
          ..._data,
          id: doc.id,
          doc: doc,
        })
      })
    }

    return clients
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const fetchClient = async (clientId: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'clients', clientId)
    const docSnap = await getDoc(docRef)

    const q = query(
      collection(db, 'pages'),
      where('client', '==', clientId),
      orderBy('createdAt', 'desc'),
      limit(20)
    )
    const querySnapshot = await getDocs(q)

    let pages: any = []

    querySnapshot.forEach((doc: any) => {
      const _data = doc.data()
      pages.push({
        ..._data,
        id: doc?.id,
      })
    })

    if (docSnap.exists()) {
      const data = docSnap.data()
      return { ...data, pages }
    }
    else {
      console.log('No such document!')
      return {}
    }
  }
  catch (e) {
    console.log('Fetch Client Error', e)
    return {}
  }
}

export const createClient = async (email: string, id: string, data: Client) => {
  try {
    const db = getFirestore(app)

    await setDoc(doc(db, 'clients', id), data)
    await updateDoc(doc(db, 'memberships', email), {
      clients: arrayUnion({
        id: id,
        name: data.name,
      }),
    })

    return data
  }
  catch (e) {
    console.log('Create Client Error', e)
    return {}
  }
}

export const updateClient = async (id: string, data: Client) => {
  try {
    const db = getFirestore(app)

    await updateDoc(doc(db, 'clients', id), { ...data })

    return data
  }
  catch (e) {
    console.log('Update Client Error', e)
    return {}
  }
}

export const removeClient = async (id: string) => {
  try {
    const db = getFirestore(app)
    const docRef = doc(db, 'clients', id)

    await deleteDoc(docRef)
  }
  catch (e) {
    console.log('Remove Client Error', e)
  }
}

export const addPageToClient = async (id: string, pageName: string, imageUrl: string, backgroundColor: string, textColor: string) => {
  try {
    const db = getFirestore(app)
    await addDoc(collection(db, 'pages'), {
      name: pageName,
      client: id,
      imageUrl,
      backgroundColor,
      textColor,
      createdAt: serverTimestamp(),
    })
    //return data
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getPageById = async (pageId: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'pages', pageId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      return { ...data, id: docSnap.id }
    } else {
      console.log('No such document!')
      return {}
    }
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const addWidgetToPage = async (pageId: string, payload: any) => {
  try {
    const db = getFirestore(app)
    await updateDoc(doc(db, 'pages', pageId), {
      widgets: arrayUnion({
        ...payload,
        createdAt: new Date(),
      }),
    })
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const removeWidgetFromPage = async (pageId: string, widget: any) => {
  try {
    const db = getFirestore(app)
    await updateDoc(doc(db, 'pages', pageId), {
      widgets: arrayRemove(widget),
    })
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const removePage = async (pageId: string) => {
  try {
    const db = getFirestore(app)
    
    await deleteDoc(doc(db, 'pages', pageId))
  }
  catch (e) {
    console.log('Remove Page Error', e)
    return {}
  }
}

export const removePagesByClient = async (clientName: string) => {
  try {
    const db = getFirestore(app)
    
    const q = query(collection(db, 'pages'), where('clients', '==', clientName))
    const querySnapshot = await getDocs(q)

    if (querySnapshot.size > 0) {
      querySnapshot.forEach(async (_doc: any) => {
        const docRef = doc(db, 'pages', _doc.id)

        await deleteDoc(docRef)
      })
    }
  }
  catch (e) {
    console.log('error', e)
  }
}

export const editThreeColumn = async (pageId: string, payload: any) => {
  try {
    const db = getFirestore(app)
    await updateDoc(doc(db, 'pages', pageId), {
      widgets: payload,
    })
  } catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getDataFromWidgetRef = async (ref: string, wheres: Array<any> = [], orders: Array<any> = []) => {
  try {
    const db = getFirestore(app)
    let _docs: any[] = []

    let q = query(collection(db, ref), orderBy('updatedAt', 'desc'), limit(20))
    if (wheres.length > 0) {
      const formattedWheres = wheres.map((w) => where(w.field, w.operator, w.value))
      q = query(collection(db, ref), ...formattedWheres, limit(20))
    }
    else {
      q = query(collection(db, ref))
    }

    if (orders.length > 0) {
      const formattedOrders = orders.map((o) => orderBy(o.field, o.direction))
      q = query(collection(db, ref), ...formattedOrders)
    }
    else {
      q = query(collection(db, ref))
    }

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc: any) => {
      const _data = doc.data()
      _docs.push({
        ..._data,
        id: doc.id,
        doc: doc,
      })
    })

    return _docs
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const getDateDataFromWidgetRef = async (
  ref: string,
  dateRef: string,
  wheres: Array<any> = []
) => {
  try {
    const db = getFirestore(app)
    let _docs: any[] = []

    console.log('REF', ref)

    let q = query(collection(db, ref), orderBy(dateRef, 'desc'), limit(20))
    if (wheres.length > 0) {
      const formattedWheres = wheres.map((w) => where(w.field, '==', w.value))
      q = query(collection(db, ref), ...formattedWheres, limit(20))
    }

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc: any) => {
      const _data = doc.data()
      _docs.push({
        ..._data,
        id: doc.id,
        doc: doc,
      })
    })

    return _docs
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const getDataFromBigQuery = async (dataset, table, column, _where, group, order, limit) => {
  try {
    const response = await axios.post(process.env.REACT_APP_FETCH_BIG_QUERY_TRIGGER || '', {
      dataset,
      table,
      column,
      where: _where,
      group,
      order,
      limit,
    })
    return response.data.rows
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getBloomerangDonorDemographicsData = async (ref: string) => {
  try {
    const db = getFirestore(app)
    let _docs: any[] = []

    let q = query(collection(db, ref))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc: any) => {
      const _data = doc.data()
      _docs.push({
        ..._data,
        id: doc.id,
        doc: doc,
      })
    })

    return _docs
  } catch (e) {
    console.log('error', e)
    return []
  }
}

export const getFiltersFromWidgetRef = async (ref: string) => {
  try {
    const db = getFirestore(app)

    const docRef = doc(db, 'filters', ref)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const data = docSnap.data()
      return data
    } else {
      console.log('No such document!')
      return {}
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getTotalFromLocalBigQuery = async (dataset: string, table: string) => {
  try {
    const response = await axios.get(`/api/bigquery/total?dataset=${dataset}&table=${table}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getDataFromLocalBigQuery = async (endpoint: string, dataset: string, table: string, whereIn: string, page: number) => {
  try {
    const response = await axios.get(`/api/bigquery/${endpoint}?dataset=${dataset}&table=${table}&whereIn=${whereIn}&page=${page}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getRecentFromLocalBigQuery = async (dataset: string, table: string) => {
  try {
    const response = await axios.get(`/api/bigquery/recent?dataset=${dataset}&table=${table}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getColumnSearchFromLocalBigQuery = async (dataset: string, table: string) => {
  try {
    const response = await axios.get(`/api/bigquery/column_search?dataset=${dataset}&table=${table}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getLifetimeCTRFromLocalBigQuery = async (dataset: string, table: string) => {
  try {
    const response = await axios.get(`/api/bigquery/lifetime_ctr?dataset=${dataset}&table=${table}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getCampaignCTRFromLocalBigQuery = async (dataset: string, table: string, campaignName: string) => {
  try {
    const response = await axios.get(`/api/bigquery/campaign_ctr?dataset=${dataset}&table=${table}&campaignName=${campaignName}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getPaginatedDataFromWidgetRef = async (ref: string, wheres: Array<any> = [], orders: Array<any> = [], item: any = null, isPrevious: boolean = false) => {
  try {
    const db = getFirestore(app)
    let _docs: any[] = []
    let constraints: any[] = []

    if (wheres.length > 0) {
      wheres.map((w) => constraints.push(where(w.field, w.operator, w.value)))
    }

    if (orders.length > 0) {
      orders.map((o) => constraints.push(orderBy(o.field, o.direction)))
    }

    if (item) {
      if (isPrevious) {
        constraints.push(endBefore(item.doc))
        constraints.push(limitToLast(10))
      }
      else {
        constraints.push(startAfter(item.doc))
        constraints.push(limit(10))
      }
    }
    else {
      constraints.push(limit(10))
    }

    const querySnapshot = await getDocs(query(collection(db, ref), ...constraints))
    querySnapshot.forEach((doc: any) => {
      const _data = doc.data()
      _docs.push({
        ..._data,
        id: doc.id,
        doc: doc,
      })
    })

    return _docs
  }
  catch (e) {
    console.log('error', e)
    return []
  }
}

export const getInstagramPaid = async (accountId: string, accessToken: string, pageUrl: string = '') => {
  try {
    if (pageUrl !== '') {
      const response = await axios.get(pageUrl)
      return response.data
    }
    else {
      const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/ads?access_token=${accessToken}&limit=10&fields=name,campaign.fields(start_time,stop_time,status),insights.level(ad).date_preset(maximum).breakdowns(publisher_platform).filtering([{'field':'publisher_platform','operator':'IN','value':['instagram']}]).fields(ad_name,ad_id,impressions,reach,actions,video_p100_watched_actions,spend,clicks)`)
      return response.data
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getFacebookPaid = async (accountId: string, accessToken: string, pageUrl: string = '') => {
  try {
    if (pageUrl !== '') {
      const response = await axios.get(pageUrl)
      return response.data
    }
    else {
      const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/ads?access_token=${accessToken}&limit=10&fields=name,campaign.fields(start_time,stop_time,status),insights.level(ad).date_preset(maximum).breakdowns(publisher_platform).filtering([{'field':'publisher_platform','operator':'IN','value':['facebook']}]).fields(ad_name,ad_id,impressions,reach,actions,video_p100_watched_actions,spend,clicks)`)
      return response.data
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getInstagramSummaryPaid = async (accountId: string, accessToken: string) => {
  try {
    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/campaigns?access_token=${accessToken}&limit=3&fields=stop_time,start_time,objective,status,ads.limit(1){adcreatives.limit(1){thumbnail_url}}&filtering=[{'field':'objective','operator':'IN','value':['APP_INSTALLS','BRAND_AWARENESS','CONVERSIONS','LEAD_GENERATION','LINK_CLICKS','POST_ENGAGEMENT','PRODUCT_CATALOG_SALES','REACH','VIDEO_VIEWS']}]`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getFacebookSummaryPaid = async (accountId: string, accessToken: string) => {
  try {
    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/campaigns?access_token=${accessToken}&limit=3&fields=stop_time,start_time,objective,status,ads.limit(1){adcreatives.limit(1){thumbnail_url}}`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getTopThreeFromWidgetRef = async (ref: string, fieldName: string) => {
  try {
    const db = getFirestore(app)
    let _docs: any[] = []
    let _startAfter: any = null
    let _jobCodes: any[] = []

    while (_docs.length < 3) {
      let querySnapshot = await getDocs(query(collection(db, ref), orderBy(fieldName, 'desc'), limit(1)))

      if (_startAfter) {
        querySnapshot = await getDocs(query(collection(db, ref), orderBy(fieldName, 'desc'), startAfter(_startAfter), limit(1)))
      }

      if (querySnapshot.size > 0) {
        const _doc = querySnapshot.docs[0]
        const _data = _doc.data()

        if (_jobCodes.indexOf(_data.jobCode.split('_')[0]) === -1) {
          _docs.push({
            ..._data,
            id: _doc.id,
            doc: _doc,
          })
        }

        _startAfter = _doc
        _jobCodes.push(_data.jobCode.split('_')[0])
      }
      else {
        break
      }
    }

    return _docs
  }
  catch (e) {
    console.log('Error getting top three')
    return []
  }
}

export const getFacebookOrganic = async (accountId: string, accessToken: string, pageUrl: string = '') => {
  try {
    if (pageUrl !== '') {
      const response = await axios.get(pageUrl)
      return response.data
    }
    else {
      const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/posts?access_token=${accessToken}&limit=10&fields=insights.metric(post_impressions_unique,post_clicks),reactions.limit(0).summary(total_count),comments.limit(0).summary(total_count),created_time,shares`)
      return response.data
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getInstagramOrganic = async (accountId: string, accessToken: string, pageUrl: string = '') => {
  try {
    if (pageUrl !== '') {
      const response = await axios.get(pageUrl)
      return response.data
    }
    else {
      const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/media?access_token=${accessToken}&limit=10&fields=timestamp,like_count,comments_count,insights.metric(reach,shares),media_type`)
      return response.data
    }
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getFacebookSummaryOrganic = async (accountId: string, accessToken: string) => {
  try {
    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/posts?access_token=${accessToken}&fields=full_picture,shares,created_time,reactions.limit(0).summary(total_count),comments.limit(0).summary(total_count)&limit=3`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

export const getInstagramSummaryOrganic = async (accountId: string, accessToken: string) => {
  try {
    const response = await axios.get(`https://graph.facebook.com/v17.0/${accountId}/media?access_token=${accessToken}&fields=timestamp,like_count,comments_count,media_type,thumbnail_url,media_url,insights.metric(shares)&limit=3`)
    return response.data
  }
  catch (e) {
    console.log('error', e)
    return {}
  }
}

// ----- Promises -----

export async function changeUserDetails(email, userDetails: DBUserModel, photoUrl: string = '') {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      await setDoc(doc(db, 'users', email), {
        ...userDetails,
        photoUrl,
      })
      resolve(true)
    }
    catch (e) {
      console.log(e)
      reject(false)
    }
  })
}

export async function changePageDetails(pageId, pageName: string) {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await updateDoc(doc(db, 'pages', pageId), { name: pageName })
      resolve('')
    }
    catch (e) {
      console.log(e)
      reject('Error occurred while adding user to firestore')
    }
  })
}
