import { useState, useEffect } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { getFacebookOrganic } from '../../helpers/remoteData'

function FacebookOrganicWidget({ accountId, accessToken, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const [loading, setLoading] = useState(false)
  const [rowsLoading, setRowsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState([])
  const [data, setData] = useState<any>({})

  const handleChangePage = async (newPage: number, pageUrl: string) => {
    setRowsLoading(true)

    const _rows = await getFacebookOrganic(accountId, accessToken, pageUrl)

    if (_rows) {
      setData(_rows)
      setRows(_rows.data)
    }

    setRowsLoading(false)
    setPage(newPage)
  }

  useEffect(() => {
    const _fetch = async () => {
      const _page = 0
      setPage(_page)

      setLoading(true)
      setRowsLoading(true)

      try {
        const _rows = await getFacebookOrganic(accountId, accessToken)

        if (_rows) {
          setData(_rows)
          setRows(_rows.data)
        }

        setLoading(false)
        setRowsLoading(false)
      }
      catch (e) {
        console.log('Meta error')
        
        setLoading(false)
        setRowsLoading(false)
      }
    }

    _fetch()
  }, [accountId, accessToken])

  return (
    <div className='col-12 mb-8 d-inline-block'>
      <div className='card shadow'>
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <>
              {
                isAdmin && (
                  <div className='text-end px-9 pt-5'>
                    <div className='card-toolbar'>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        onClick={() => moveUp(el)}
                      >
                        <ArrowUpwardIcon />
                      </button>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        onClick={() => moveDown(el)}
                      >
                        <ArrowDownwardIcon />
                      </button>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        data-bs-toggle='modal'
                        data-bs-target='#modal_delete_widget'
                        onClick={() => setSelectedWidget(el)}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                )
              }

              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-2'>Facebook Posts</span>
                </h3>
              </div>
              
              {
                rowsLoading ? 
                  <div className='card-body'>
                    <div className='text-center py-3'>
                      <div className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </div>
                :
                  rows.length > 0 ? 
                    <>
                      <div className='card-body py-3'>
                        <div className='table-responsive'>
                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                              <tr className='fw-bolder text-muted'>
                                <th className='min-w-150px'>Date</th>
                                <th>Reach</th>
                                <th>Reactions</th>
                                <th>Comments</th>
                                <th>Shares</th>
                                <th>Clicks</th>
                              </tr>
                            </thead>
                            
                            <tbody className='border-0'>
                              {
                                rows.map((r: any, i: number) => {
                                  // const viewsIndex = r?.actions && r.actions.length > 0 ? r.actions.findIndex((a) => a.action_type === 'video_view') : -1
                                  // const reactionIndex = r?.actions && r.actions.length > 0 ? r.actions.findIndex((a) => a.action_type === 'post_reaction') : -1
                                  // const commentIndex = r?.actions && r.actions.length > 0 ? r.actions.findIndex((a) => a.action_type === 'comment') : -1
                                  // const shareIndex = r?.actions && r.actions.length > 0 ? r.actions.findIndex((a) => a.action_type === 'post') : -1
                                  // const videoIndex = r?.video_p100_watched_actions && r.video_p100_watched_actions.length > 0 ? r.video_p100_watched_actions.findIndex((a) => a.action_type === 'video_view') : -1
                                  const impressionsIndex = r?.insights && r.insights?.data && r.insights.data.length > 0 ? r.insights.data.findIndex((i) => i.name === 'post_impressions_unique') : -1
                                  const clicksIndex = r?.insights && r.insights?.data && r.insights.data.length > 0 ? r.insights.data.findIndex((i) => i.name === 'post_clicks') : -1

                                  return (
                                    <tr key={`facebook-organic-row-${i}`}>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.created_time && moment(r.created_time).format('MM/DD/YY')}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {impressionsIndex > -1 ? parseInt(r.insights.data[impressionsIndex].values[0].value).toLocaleString('en-US') : 0}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.reactions?.summary?.total_count && parseInt(r?.reactions?.summary?.total_count).toLocaleString('en-US')}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.comments?.summary?.total_count && parseInt(r?.comments?.summary?.total_count).toLocaleString('en-US')}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.shares?.count ? parseInt(r?.shares?.count).toLocaleString('en-US') : 0}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {clicksIndex > -1 ? parseInt(r.insights.data[clicksIndex].values[0].value).toLocaleString('en-US') : 0}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='card-footer d-flex align-items-center justify-content-between'>
                        <div className='text-muted mt-1 fw-bold fs-7'>
                          Page {page + 1}
                        </div>
                        <div className='text-end'>
                          {
                            page > 0 && data?.paging?.previous && (
                              <button type='button' className='btn btn-secondary btn-sm me-5 w-125px d-inline-flex align-items-center justify-content-center' onClick={() => handleChangePage(page - 1, data?.paging?.previous)}>
                                <ArrowBackIcon fontSize='small' className='me-3' />
                                <span>
                                  Previous
                                </span>
                              </button>
                            )
                          }
                          {
                            data?.paging?.next && (
                              <button type='button' className='btn btn-secondary btn-sm w-125px d-inline-flex align-items-center justify-content-center' onClick={() => handleChangePage(page + 1, data?.paging?.next)}>
                                <span>
                                  Next
                                </span>
                                <ArrowForwardIcon fontSize='small' className='ms-3' />
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </>
                  :
                    <div className='card-body'>
                      <div className='fw-bold mb-4'>No ads exist.</div>
                    </div>
              }
            </>
        }
      </div>
    </div>
  )
}

export default FacebookOrganicWidget
