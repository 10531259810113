import { useContext, useState, useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, createFilterOptions } from '@mui/material'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { ClientContext } from '../../../providers/ClientProvider'
import { addWidgetToPage } from '../../../helpers/remoteData'

const widgetTypes = [
  {
    id: 'Smartsheet Digital Ad Widget',
    name: 'Smartsheet Digital Ad Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Direct Mail Widget',
    name: 'Smartsheet Direct Mail Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Print Widget',
    name: 'Smartsheet Print Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Billboard Widget',
    name: 'Smartsheet Billboard Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet TV Widget',
    name: 'Smartsheet TV Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Total Spend Widget',
    name: 'Total Spend Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Page Filter Widget',
    name: 'Page Filter Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Mocentric Digital Widget',
    name: 'Mocentric Digital Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Text Message Widget',
    name: 'Smartsheet Text Message Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Instagram Paid Widget',
    name: 'Instagram Paid Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Facebook Paid Widget',
    name: 'Facebook Paid Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Instagram Summary Paid Widget',
    name: 'Instagram Summary Paid Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Facebook Summary Paid Widget',
    name: 'Facebook Summary Paid Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Summary Direct Mail Widget',
    name: 'Smartsheet Summary Direct Mail Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Summary Print Widget',
    name: 'Smartsheet Summary Print Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Summary Billboard Widget',
    name: 'Smartsheet Summary Billboard Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Summary TV Widget',
    name: 'Smartsheet Summary TV Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Summary Text Message Widget',
    name: 'Smartsheet Summary Text Message Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Mocentric Summary Digital Widget',
    name: 'Mocentric Summary Digital Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Welcome Widget',
    name: 'Welcome Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Facebook Organic Widget',
    name: 'Facebook Organic Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Instagram Organic Widget',
    name: 'Instagram Organic Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Facebook Summary Organic Widget',
    name: 'Facebook Summary Organic Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Instagram Summary Organic Widget',
    name: 'Instagram Summary Organic Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Mocentric CTR Average Widget',
    name: 'Mocentric CTR Average Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Survey Widget',
    name: 'Smartsheet Survey Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
  {
    id: 'Smartsheet Summary Survey Widget',
    name: 'Smartsheet Summary Survey Widget',
    icon: '/media/icons/duotune/communication/com011.svg',
  },
]

const regularValidationSchema = Yup.object().shape({
  reference: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Reference is required'),
})

const regularInitialValues = {
  reference: '',
}

const regularSummaryValidationSchema = Yup.object().shape({
  reference: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Reference is required'),
  pageUrl: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Page URL is required'),
})

const regularSummaryInitialValues = {
  reference: '',
  pageUrl: '',
}

const regularBigQueryValidationSchema = Yup.object().shape({
  reference: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Reference is required'),
  dataset: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Dataset is required'),
  table: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Table is required'),
})

const regularBigQueryInitialValues = {
  reference: '',
  dataset: '',
  table: '',
}

const bigQueryValidationSchema = Yup.object().shape({
  dataset: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Dataset is required'),
  table: Yup.string()
    .matches(/^\S*$/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Table is required'),
})

const bigQueryInitialValues = {
  dataset: '',
  table: '',
}

// const bigQuerySummaryValidationSchema = Yup.object().shape({
//   dataset: Yup.string()
//     .matches(/^\S*$/, 'Cannot contain spaces')
//     .min(3, 'Minimum 3 characters')
//     .max(50, 'Maximum 50 characters')
//     .required('Dataset is required'),
//   table: Yup.string()
//     .matches(/^\S*$/, 'Cannot contain spaces')
//     .min(3, 'Minimum 3 characters')
//     .max(50, 'Maximum 50 characters')
//     .required('Table is required'),
//   pageUrl: Yup.string()
//     .matches(/^\S*$/, 'Cannot contain spaces')
//     .min(3, 'Minimum 3 characters')
//     .max(50, 'Maximum 50 characters')
//     .required('Page URL is required'),
// })

// const bigQuerySummaryInitialValues = {
//   dataset: '',
//   table: '',
//   pageUrl: '',
// }

const metaValidationSchema = Yup.object().shape({
  accountId: Yup.string()
    .matches(/^(?!\s+$).*/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Account ID is required'),
  accessToken: Yup.string()
    .matches(/^(?!\s+$).*/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Access Token is required'),
})

const metaInitialValues = {
  accountId: '',
  accessToken: '',
}

const metaSummaryValidationSchema = Yup.object().shape({
  accountId: Yup.string()
    .matches(/^(?!\s+$).*/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Account ID is required'),
  accessToken: Yup.string()
    .matches(/^(?!\s+$).*/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Access Token is required'),
  pageUrl: Yup.string()
    .matches(/^(?!\s+$).*/, 'Cannot contain spaces')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Page URL is required'),
})

const metaSummaryInitialValues = {
  accountId: '',
  accessToken: '',
  pageUrl: '',
}

const welcomeValidationSchema = Yup.object().shape({})

const welcomeInitialValues = {}

const AddWidgetModel = ({ pageDetails, fetchThisPage }) => {
  const [selectedInitialValues, setSelectedInitialValues] = useState<any>(regularInitialValues)
  const [selectedValidationSchema, setSelectedValidationSchema] = useState<any>(regularValidationSchema)
  const [selectedWidgetType, setSelectedWidgetType] = useState<any>('')

  const { client } = useContext(ClientContext)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: selectedInitialValues,
    validationSchema: selectedValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        setLoading(true)

        let payload: any = {}

        if (
          selectedWidgetType === 'Facebook Charity Overview Widget' ||
          selectedWidgetType === 'Cable Widget' ||
          selectedWidgetType === 'Broadcast Widget' ||
          selectedWidgetType === 'Mail Widget' ||
          selectedWidgetType === 'Squarespace Commerce Orders Overview Widget' ||
          selectedWidgetType === 'Bloomerang Donor Demographics Widget' ||
          selectedWidgetType === 'Bloomerang Donation Counter Widget' ||
          selectedWidgetType === 'Bloomerang Money Overview Widget' ||
          selectedWidgetType === 'Billboard Widget' ||
          selectedWidgetType === 'Smartsheet Digital Ad Widget' ||
          selectedWidgetType === 'Smartsheet Direct Mail Widget' ||
          selectedWidgetType === 'Smartsheet Print Widget' ||
          selectedWidgetType === 'Smartsheet Billboard Widget' ||
          selectedWidgetType === 'Smartsheet TV Widget' ||
          selectedWidgetType === 'Total Spend Widget' ||
          selectedWidgetType === 'Page Filter Widget' ||
          selectedWidgetType === 'Smartsheet Text Message Widget' ||
          selectedWidgetType === 'Smartsheet Survey Widget'
        )
        {
          payload = {
            reference: values.reference,
          }
        }
        else if (
          selectedWidgetType === 'Smartsheet Summary Direct Mail Widget' ||
          selectedWidgetType === 'Smartsheet Summary Print Widget' ||
          selectedWidgetType === 'Smartsheet Summary Billboard Widget' ||
          selectedWidgetType === 'Smartsheet Summary TV Widget' ||
          selectedWidgetType === 'Smartsheet Summary Text Message Widget' ||
          selectedWidgetType === 'Smartsheet Summary Survey Widget' ||
          selectedWidgetType === 'Mocentric Summary Digital Widget'
        )
        {
          payload = {
            reference: values.reference,
            pageUrl: values.pageUrl,
          }
        }
        else if (
          selectedWidgetType === 'Mocentric Digital Widget'
        )
        {
          payload = {
            reference: values.reference,
            dataset: values.dataset,
            table: values.table,
          }
        }
        else if (
          selectedWidgetType === 'Mocentric CTR Average Widget'
        )
        {
          payload = {
            dataset: values.dataset,
            table: values.table,
          }
        }
        else if (
          selectedWidgetType === 'Instagram Paid Widget' ||
          selectedWidgetType === 'Facebook Paid Widget' ||
          selectedWidgetType === 'Facebook Organic Widget' ||
          selectedWidgetType === 'Instagram Organic Widget'
        )
        {
          payload = {
            accountId: values.accountId,
            accessToken: values.accessToken,
          }
        }
        else if (
          selectedWidgetType === 'Instagram Summary Paid Widget' ||
          selectedWidgetType === 'Facebook Summary Paid Widget' ||
          selectedWidgetType === 'Facebook Summary Organic Widget' ||
          selectedWidgetType === 'Instagram Summary Organic Widget'
        )
        {
          payload = {
            accountId: values.accountId,
            accessToken: values.accessToken,
            pageUrl: values.pageUrl,
          }
        }
        else if (
          selectedWidgetType === 'Welcome Widget'
        )
        {
          payload = {}
        }

        payload.type = selectedWidgetType

        await addWidgetToPage(pageDetails.id, payload)

        setLoading(false)

        setSelectedWidgetType('')
        setSelectedInitialValues(regularInitialValues)
        setSelectedValidationSchema(regularValidationSchema)

        resetForm()
        fetchThisPage()
        document?.getElementById('close_modal_add_widget')?.click()
      }
      catch (e) {
        setStatus('Unknown Error')
        setLoading(false)
        setSubmitting(false)
      }
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (selectedWidgetType !== '') {
      if (
        selectedWidgetType === 'Facebook Charity Overview Widget' ||
        selectedWidgetType === 'Cable Widget' ||
        selectedWidgetType === 'Broadcast Widget' ||
        selectedWidgetType === 'Mail Widget' ||
        selectedWidgetType === 'Squarespace Commerce Orders Overview Widget' ||
        selectedWidgetType === 'Bloomerang Donor Demographics Widget' ||
        selectedWidgetType === 'Bloomerang Donation Counter Widget' ||
        selectedWidgetType === 'Bloomerang Money Overview Widget' ||
        selectedWidgetType === 'Billboard Widget' ||
        selectedWidgetType === 'Smartsheet Digital Ad Widget' ||
        selectedWidgetType === 'Smartsheet Direct Mail Widget' ||
        selectedWidgetType === 'Smartsheet Print Widget' ||
        selectedWidgetType === 'Smartsheet Billboard Widget' ||
        selectedWidgetType === 'Smartsheet TV Widget' ||
        selectedWidgetType === 'Total Spend Widget' ||
        selectedWidgetType === 'Page Filter Widget' ||
        selectedWidgetType === 'Smartsheet Text Message Widget' ||
        selectedWidgetType === 'Smartsheet Survey Widget'
      )
      {
        setSelectedInitialValues(regularInitialValues)
        setSelectedValidationSchema(regularValidationSchema)
      }
      else if (
        selectedWidgetType === 'Smartsheet Summary Direct Mail Widget' ||
        selectedWidgetType === 'Smartsheet Summary Print Widget' ||
        selectedWidgetType === 'Smartsheet Summary Billboard Widget' ||
        selectedWidgetType === 'Smartsheet Summary TV Widget' ||
        selectedWidgetType === 'Smartsheet Summary Text Message Widget' ||
        selectedWidgetType === 'Smartsheet Summary Survey Widget' ||
        selectedWidgetType === 'Mocentric Summary Digital Widget'
      )
      {
        setSelectedInitialValues(regularSummaryInitialValues)
        setSelectedValidationSchema(regularSummaryValidationSchema)
      }
      else if (
        selectedWidgetType === 'Mocentric Digital Widget'
      )
      {
        setSelectedInitialValues(regularBigQueryInitialValues)
        setSelectedValidationSchema(regularBigQueryValidationSchema)
      }
      else if (
        selectedWidgetType === 'Mocentric CTR Average Widget'
      )
      {
        setSelectedInitialValues(bigQueryInitialValues)
        setSelectedValidationSchema(bigQueryValidationSchema)
      }
      else if (
        selectedWidgetType === 'Instagram Paid Widget' ||
        selectedWidgetType === 'Facebook Paid Widget' ||
        selectedWidgetType === 'Facebook Organic Widget' ||
        selectedWidgetType === 'Instagram Organic Widget'
      )
      {
        setSelectedInitialValues(metaInitialValues)
        setSelectedValidationSchema(metaValidationSchema)
      }
      else if (
        selectedWidgetType === 'Instagram Summary Paid Widget' ||
        selectedWidgetType === 'Facebook Summary Paid Widget' ||
        selectedWidgetType === 'Facebook Summary Organic Widget' ||
        selectedWidgetType === 'Instagram Summary Organic Widget'
      )
      {
        setSelectedInitialValues(metaSummaryInitialValues)
        setSelectedValidationSchema(metaSummaryValidationSchema)
      }
      else if (
        selectedWidgetType === 'Welcome Widget'
      )
      {
        setSelectedInitialValues(welcomeInitialValues)
        setSelectedValidationSchema(welcomeValidationSchema)
      }
    }
  }, [selectedWidgetType])

  const renderRegularInputs = () => {
    return (
      <div className='form-group'>
        <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
          <span className='required'>Reference Name</span>
        </label>
        <input
          {...formik.getFieldProps('reference')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.reference && formik.errors.reference },
            { 'is-valid': formik.touched.reference && !formik.errors.reference },
          )}
          name='reference'
          autoComplete='off'
        />
        {formik.touched.reference && formik.errors.reference && (
          <div className='fv-plugins-message-container mt-1'>
            <span role='alert' className='text-danger'>{formik.errors.reference}</span>
          </div>
        )}
      </div>
    )
  }

  const renderRegularSummaryInputs = () => {
    return (
      <>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Reference Name</span>
          </label>
          <input
            {...formik.getFieldProps('reference')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.reference && formik.errors.reference },
              { 'is-valid': formik.touched.reference && !formik.errors.reference },
            )}
            name='reference'
            autoComplete='off'
          />
          {formik.touched.reference && formik.errors.reference && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.reference}</span>
            </div>
          )}
        </div>
        <div className='form-group'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Page URL</span>
          </label>
          <input
            {...formik.getFieldProps('pageUrl')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.pageUrl && formik.errors.pageUrl },
              { 'is-valid': formik.touched.pageUrl && !formik.errors.pageUrl },
            )}
            name='pageUrl'
            autoComplete='off'
          />
          {formik.touched.pageUrl && formik.errors.pageUrl && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.pageUrl}</span>
            </div>
          )}
        </div>
      </>
    )
  }

  const renderRegularBigQueryInputs = () => {
    return (
      <>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Reference Name</span>
          </label>
          <input
            {...formik.getFieldProps('reference')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.reference && formik.errors.reference },
              { 'is-valid': formik.touched.reference && !formik.errors.reference },
            )}
            name='reference'
            autoComplete='off'
          />
          {formik.touched.reference && formik.errors.reference && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.reference}</span>
            </div>
          )}
        </div>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Dataset</span>
          </label>
          <input
            {...formik.getFieldProps('dataset')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.dataset && formik.errors.dataset },
              { 'is-valid': formik.touched.dataset && !formik.errors.dataset },
            )}
            name='dataset'
            autoComplete='off'
          />
          {formik.touched.dataset && formik.errors.dataset && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.dataset}</span>
            </div>
          )}
        </div>
        <div className='form-group'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Table</span>
          </label>
          <input
            {...formik.getFieldProps('table')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.table && formik.errors.table },
              { 'is-valid': formik.touched.table && !formik.errors.table },
            )}
            name='table'
            autoComplete='off'
          />
          {formik.touched.table && formik.errors.table && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.table}</span>
            </div>
          )}
        </div>
      </>
    )
  }

  const renderBigQueryInputs = () => {
    return (
      <>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Dataset</span>
          </label>
          <input
            {...formik.getFieldProps('dataset')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.dataset && formik.errors.dataset },
              { 'is-valid': formik.touched.dataset && !formik.errors.dataset },
            )}
            name='dataset'
            autoComplete='off'
          />
          {formik.touched.dataset && formik.errors.dataset && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.dataset}</span>
            </div>
          )}
        </div>
        <div className='form-group'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Table</span>
          </label>
          <input
            {...formik.getFieldProps('table')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.table && formik.errors.table },
              { 'is-valid': formik.touched.table && !formik.errors.table },
            )}
            name='table'
            autoComplete='off'
          />
          {formik.touched.table && formik.errors.table && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.table}</span>
            </div>
          )}
        </div>
      </>
    )
  }

  // const renderBigQuerySummaryInputs = () => {
  //   return (
  //     <>
  //       <div className='form-group mb-8'>
  //         <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
  //           <span className='required'>Dataset</span>
  //         </label>
  //         <input
  //           {...formik.getFieldProps('dataset')}
  //           className={clsx(
  //             'form-control form-control-lg form-control-solid',
  //             { 'is-invalid': formik.touched.dataset && formik.errors.dataset },
  //             { 'is-valid': formik.touched.dataset && !formik.errors.dataset },
  //           )}
  //           name='dataset'
  //           autoComplete='off'
  //         />
  //         {formik.touched.dataset && formik.errors.dataset && (
  //           <div className='fv-plugins-message-container mt-3'>
  //             <span role='alert' className='text-danger'>{formik.errors.dataset}</span>
  //           </div>
  //         )}
  //       </div>
  //       <div className='form-group mb-8'>
  //         <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
  //           <span className='required'>Table</span>
  //         </label>
  //         <input
  //           {...formik.getFieldProps('table')}
  //           className={clsx(
  //             'form-control form-control-lg form-control-solid',
  //             { 'is-invalid': formik.touched.table && formik.errors.table },
  //             { 'is-valid': formik.touched.table && !formik.errors.table },
  //           )}
  //           name='table'
  //           autoComplete='off'
  //         />
  //         {formik.touched.table && formik.errors.table && (
  //           <div className='fv-plugins-message-container mt-3'>
  //             <span role='alert' className='text-danger'>{formik.errors.table}</span>
  //           </div>
  //         )}
  //       </div>
  //       <div className='form-group mb-8'>
  //         <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
  //           <span className='required'>Page URL</span>
  //         </label>
  //         <input
  //           {...formik.getFieldProps('pageUrl')}
  //           className={clsx(
  //             'form-control form-control-lg form-control-solid',
  //             { 'is-invalid': formik.touched.pageUrl && formik.errors.pageUrl },
  //             { 'is-valid': formik.touched.pageUrl && !formik.errors.pageUrl },
  //           )}
  //           name='pageUrl'
  //           autoComplete='off'
  //         />
  //         {formik.touched.pageUrl && formik.errors.pageUrl && (
  //           <div className='fv-plugins-message-container mt-3'>
  //             <span role='alert' className='text-danger'>{formik.errors.pageUrl}</span>
  //           </div>
  //         )}
  //       </div>
  //     </>
  //   )
  // }

  const renderMetaInputs = () => {
    return (
      <>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Account ID</span>
          </label>
          <input
            {...formik.getFieldProps('accountId')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.accountId && formik.errors.accountId },
              { 'is-valid': formik.touched.accountId && !formik.errors.accountId },
            )}
            name='accountId'
            autoComplete='off'
          />
          {formik.touched.accountId && formik.errors.accountId && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.accountId}</span>
            </div>
          )}
        </div>
        <div className='form-group'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Access Token</span>
          </label>
          <input
            {...formik.getFieldProps('accessToken')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.accessToken && formik.errors.accessToken },
              { 'is-valid': formik.touched.accessToken && !formik.errors.accessToken },
            )}
            name='accessToken'
            autoComplete='off'
          />
          {formik.touched.accessToken && formik.errors.accessToken && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.accessToken}</span>
            </div>
          )}
        </div>
      </>
    )
  }

  const renderMetaSummaryInputs = () => {
    return (
      <>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Account ID</span>
          </label>
          <input
            {...formik.getFieldProps('accountId')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.accountId && formik.errors.accountId },
              { 'is-valid': formik.touched.accountId && !formik.errors.accountId },
            )}
            name='accountId'
            autoComplete='off'
          />
          {formik.touched.accountId && formik.errors.accountId && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.accountId}</span>
            </div>
          )}
        </div>
        <div className='form-group mb-8'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Access Token</span>
          </label>
          <input
            {...formik.getFieldProps('accessToken')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.accessToken && formik.errors.accessToken },
              { 'is-valid': formik.touched.accessToken && !formik.errors.accessToken },
            )}
            name='accessToken'
            autoComplete='off'
          />
          {formik.touched.accessToken && formik.errors.accessToken && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.accessToken}</span>
            </div>
          )}
        </div>
        <div className='form-group'>
          <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
            <span className='required'>Page URL</span>
          </label>
          <input
            {...formik.getFieldProps('pageUrl')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.pageUrl && formik.errors.pageUrl },
              { 'is-valid': formik.touched.pageUrl && !formik.errors.pageUrl },
            )}
            name='pageUrl'
            autoComplete='off'
          />
          {formik.touched.pageUrl && formik.errors.pageUrl && (
            <div className='fv-plugins-message-container mt-1'>
              <span role='alert' className='text-danger'>{formik.errors.pageUrl}</span>
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div className='modal fade' id='modal_add_widget_to_page' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <form
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='modal-header'>
              <h2>Add Widget To <span className='fw-boldest'>{pageDetails.name}</span></h2>
              <div id='close_modal_add_widget' className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <CloseIcon />
              </div>
            </div>

            <div className='modal-body scroll-y py-8'>
              {formik.status && (
                <div className='mb-lg-8 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='form-group mb-8'>
                <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                  <span className='required'>Choose Widget</span>
                </label>
                <Autocomplete
                  freeSolo
                  id='add-widget-autocomplete'
                  options={widgetTypes.map((option) => option.name)}
                  noOptionsText='No widgets exist for this query.'
                  filterOptions={createFilterOptions({
                    limit: 5
                  })}
                  onChange={(e, value) => setSelectedWidgetType(value)}
                  blurOnSelect={true}
                  renderInput={(params) => 
                    <div ref={params.InputProps.ref}>
                      <input {...params.inputProps} className='form-control form-control-lg form-control-solid' placeholder='Search Widgets' />
                    </div>
                  }
                />
              </div>

              {(formik.initialValues === regularInitialValues) && selectedWidgetType !== '' && renderRegularInputs()}

              {(formik.initialValues === regularSummaryInitialValues) && selectedWidgetType !== '' && renderRegularSummaryInputs()}

              {(formik.initialValues === regularBigQueryInitialValues) && selectedWidgetType !== '' && renderRegularBigQueryInputs()}
              
              {(formik.initialValues === bigQueryInitialValues) && selectedWidgetType !== '' && renderBigQueryInputs()}

              {(formik.initialValues === metaInitialValues) && selectedWidgetType !== '' && renderMetaInputs()}

              {(formik.initialValues === metaSummaryInitialValues) && selectedWidgetType !== '' && renderMetaSummaryInputs()}
            </div>

            <div className='modal-footer'>
              <button
                type='submit'
                className='btn btn-primary px-10'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <span className='spinner-border spinner-border-sm align-middle'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddWidgetModel
