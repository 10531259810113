/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useState,useContext,useEffect,useRef} from 'react'
import {DigitalCampaign} from '../../pages/digital/DigitalWrapper'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {NotificationContext} from '../../providers/NotificationProvider'
import {deleteProject} from '../../helpers/remoteData'
import {ClientContext} from '../../providers/ClientProvider'

const DeleteDigitalModal: FC = () => {
    const { setText } = useContext(NotificationContext)
    const { client } = useContext(ClientContext)
    const { currentDigital, setDigitals, digitals } = useContext(RemoteDataContext)
    const [digital, setDigital] = useState<DigitalCampaign>({})
    const [loading, setLoading] = useState<boolean>(false)
    const closeRef = useRef<HTMLDivElement>(null)

    const _deleteProject = async () => {
        if (digital.id && client.digital) {
            setLoading(true)
            const index = digitals.map((d) => d.id).indexOf(digital.id)
            await deleteProject(client.digital, digital.id)

            let _digitals: any[] = Array.from(digitals)
            delete _digitals[index]
            setDigitals(_digitals)
            setText('Project deleted!')
            setLoading(false)
            closeRef.current?.click()
        }
    }

    useEffect(() => {
        if (Object.keys(currentDigital).length > 0) {
            setDigital(currentDigital)
        }
    }, [currentDigital])

    return (
        <div className='modal fade' id='kt_modal_delete_digital_campaign' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Delete Digital Campaign</h1>
                        </div>

                        <p>Are you sure you want to delete this project? This action cannot be undone.</p>

                        <div className="text-center pt-15">
                            <button data-bs-dismiss='modal' className="btn btn-light me-3">Cancel</button>
                            <button className="btn btn-primary" onClick={() => _deleteProject()} disabled={loading}>
                                {
                                    !loading ? 
                                    <span className="indicator-label">Delete</span>
                                    :
                                    <span className="indicator-progress" style={{display: 'block'}}>
                                        Please wait... 
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {DeleteDigitalModal}

