import React, {FC,useState,useEffect,useContext} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Stats} from './Stats'
import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials'
import {fetchFacebookCampaigns} from '../../helpers/remoteData'
import {ClientContext} from '../../providers/ClientProvider'

export interface SimpleFacebookCampaign {
    id: string
    name: string
}

export interface FacebookCampaign {
    clicks: number
    impressions: number
    ctr: number
    inline_post_engagement: number
    reach: number
    date_start: Date
    date_stop: Date
    ad_id: string
    ad_name: string
    thumbnail_url: string
}

const FacebookWrapper: FC = () => {
    const intl = useIntl()
    const { client } = useContext(ClientContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [simpleCampaigns, setSimpleCampaigns] = useState<SimpleFacebookCampaign[]>([])
    const [selectedCampaign, setSelectedCampaign] = useState<SimpleFacebookCampaign>({
        id: '',
        name: ''
    })

    useEffect(() => {
        if (client.facebook) {
            const _fetchSimpleCampaigns = async () => {
                setLoading(true)
                const campaigns = await fetchFacebookCampaigns(client.facebook.id, client.facebook.secret, client.facebook.adAccountId)
                console.log('SIMPLE CAMPAIGNS', campaigns)
                setSimpleCampaigns(campaigns.campaigns)
                
                if (campaigns.campaigns.length > 0) {
                    setSelectedCampaign(campaigns.campaigns[0])
                }
                
                setLoading(false)
            }
            _fetchSimpleCampaigns()
        }
    }, [client.facebook])

    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className="col-12 my-8">
                    <div className="card shadow">
                        <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="m-0 fs-3">
                                Facebook
                            </h4>
                            
                            <button
                                type='button'
                                className='btn btn-icon btn-color-primary btn-active-light-primary w-initial p-3 px-4'
                                style={{width: 'initial'}}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 me-3' />
                                Campaigns
                            </button>
                            <Dropdown1 campaigns={simpleCampaigns} loading={loading} setCampaign={setSelectedCampaign} campaign={selectedCampaign} />
                        </div>
                    </div>
                </div>
            </div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.FACEBOOK'})}</PageTitle>
            <Stats campaign={selectedCampaign} facebook={client.facebook} />
        </>
    )
}

export {FacebookWrapper}
