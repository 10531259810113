import React, { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { sendResetCode } from '../../../helpers/remoteData'

const requestSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: requestSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        setLoading(true)

        await sendResetCode(values.email)

        setLoading(false)
        resetForm()
        toast.success('Password request sent!')
      }
      catch (e) {
        setStatus('Unknown Error')
        setLoading(false)
        setSubmitting(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_forgot_password_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-white mb-3'>Forgot Password?</h1>
        <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
      </div>

      {formik.status && (
        <div className='mb-lg-8 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-white'>Email</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
            'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container mt-3'>
            <span role='alert' className='text-danger'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          className='btn btn-lg btn-primary fw-bolder w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login' className='btn btn-lg btn-light fw-bolder w-100 mb-5'>
          Cancel
        </Link>
      </div>
    </form>
  )
}
