import { useState, useEffect, useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getTopThreeFromWidgetRef } from '../../helpers/remoteData'
import { ClientContext } from '../../providers/ClientProvider'
import AttachmentsModal from './components/AttachmentsModal'

function SmartsheetSummaryTextMessageWidget({ reference, pageUrl, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const { client } = useContext(ClientContext)

  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<Array<any>>([])
  const [attachments, setAttachments] = useState<Array<any>>([])

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true)

      try {
        const _rows = await getTopThreeFromWidgetRef(reference, 'dropDate')

        if (_rows) {
          setRows(_rows)
        }

        setLoading(false)
      }
      catch (e) {
        console.log('Text Message Error')
        
        setRows([])
        setLoading(false)
      }
    }

    _fetch()
  }, [reference])

  return (
    <div className='col-12 col-md-6 col-xl-4 mb-8 d-inline-block'>
      <div className='card shadow h-100'>
        {
          isAdmin && (
            <div className='card-header justify-content-end'>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveUp(el)}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveDown(el)}
              >
                <ArrowDownwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                data-bs-toggle='modal'
                data-bs-target='#modal_delete_widget'
                onClick={() => setSelectedWidget(el)}
              >
                <DeleteIcon />
              </button>
            </div>
          )
        }
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <div className='card-body'>
              <h4 className='d-flex align-items-center mb-0 fw-boldest'>
                <span className='me-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21.045 3.825H18.99V3.315C18.99 1.89 17.925 0.75 16.62 0.75H7.26002C5.95502 0.75 4.89001 1.905 4.89001 3.315V19.005H5.94001V3.315C5.94001 2.475 6.54002 1.8 7.26002 1.8H16.62C17.355 1.8 17.94 2.475 17.94 3.315V3.825H15.225C14.325 3.825 13.59 4.56 13.59 5.46V11.28C13.59 12.18 14.325 12.915 15.225 12.915H16.41V14.94C16.41 15.15 16.545 15.345 16.74 15.42C16.8 15.45 16.875 15.465 16.935 15.465C17.07 15.465 17.205 15.405 17.31 15.3L17.925 14.655L18.045 14.535L19.605 12.915H21.03C21.93 12.915 22.665 12.18 22.665 11.28V5.46C22.68 4.56 21.945 3.825 21.045 3.825ZM21.63 11.28C21.63 11.61 21.36 11.865 21.045 11.865H19.395C19.26 11.865 19.11 11.925 19.02 12.03L17.475 13.635V12.39C17.475 12.105 17.235 11.865 16.95 11.865H15.24C14.91 11.865 14.655 11.595 14.655 11.28V5.46C14.655 5.13 14.925 4.875 15.24 4.875H21.06C21.39 4.875 21.645 5.145 21.645 5.46L21.63 11.28ZM11.94 19.47C11.295 19.47 10.77 19.995 10.77 20.64C10.77 21.285 11.295 21.81 11.94 21.81C12.585 21.81 13.11 21.285 13.11 20.64C13.125 20.01 12.585 19.47 11.94 19.47ZM11.94 21.075C11.7 21.075 11.52 20.88 11.52 20.655C11.52 20.43 11.715 20.235 11.94 20.235C12.165 20.235 12.36 20.43 12.36 20.655C12.375 20.88 12.18 21.075 11.94 21.075Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M13.035 2.28H10.845C10.635 2.28 10.47 2.445 10.47 2.655C10.47 2.865 10.635 3.03 10.845 3.03H13.02C13.23 3.03 13.395 2.865 13.395 2.655C13.395 2.445 13.245 2.28 13.035 2.28ZM14.28 3.15C14.535 3.15 14.73 2.94 14.73 2.7C14.73 2.46 14.52 2.25 14.28 2.25C14.025 2.25 13.83 2.46 13.83 2.7C13.83 2.94 14.025 3.15 14.28 3.15Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M17.94 15.1951V20.6851C17.94 21.5251 17.34 22.2001 16.62 22.2001H7.26002C6.52502 22.2001 5.94001 21.5251 5.94001 20.6851V18.8401H4.89001V20.6851C4.89001 22.1101 5.95502 23.2501 7.26002 23.2501H16.62C17.925 23.2501 18.99 22.0951 18.99 20.6851V14.1001L18.465 14.6401L17.94 15.1951Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M16.785 18.8549H7.36499C7.15499 18.8549 6.98999 18.6899 6.98999 18.4799V4.00488C6.98999 3.79488 7.15499 3.62988 7.36499 3.62988H16.41C16.62 3.62988 16.785 3.79488 16.785 4.00488C16.785 4.21488 16.62 4.37988 16.41 4.37988H7.73999V18.1199H16.425V15.1349C16.425 14.9249 16.59 14.7599 16.8 14.7599C17.01 14.7599 17.175 14.9249 17.175 15.1349V18.4949C17.16 18.6899 16.995 18.8549 16.785 18.8549Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M16.59 7.62012H19.68C19.89 7.62012 20.055 7.45512 20.055 7.24512C20.055 7.03512 19.89 6.87012 19.68 6.87012H16.59C16.38 6.87012 16.215 7.03512 16.215 7.24512C16.215 7.45512 16.38 7.62012 16.59 7.62012Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M16.59 8.74512H19.68C19.89 8.74512 20.055 8.58012 20.055 8.37012C20.055 8.16012 19.89 7.99512 19.68 7.99512H16.59C16.38 7.99512 16.215 8.16012 16.215 8.37012C16.215 8.58012 16.38 8.74512 16.59 8.74512Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    <path d="M16.59 9.87012H19.68C19.89 9.87012 20.055 9.70512 20.055 9.49512C20.055 9.28512 19.89 9.12012 19.68 9.12012H16.59C16.38 9.12012 16.215 9.28512 16.215 9.49512C16.215 9.70512 16.38 9.87012 16.59 9.87012Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                  </svg>
                </span>
                {
                  pageUrl ? 
                    <Link to={pageUrl} className='text-dark'>Latest Text Campaigns</Link>
                  :
                    <span>Latest Text Campaigns</span>
                }
              </h4>

              <div>
                {
                  rows.length > 0 ?
                    rows.map((r, i) => {
                      return (
                        <div key={`smartsheet-summary-text-message-${i}`} className='mt-8 d-flex align-items-center'>
                          <div className='text-center w-40px'>{r?.dropDate && moment.unix(r?.dropDate.seconds).format('MM/DD')}<br/>{r?.dropDate && moment.unix(r?.dropDate.seconds).format('YYYY')}</div>
                          <div className='ms-6'>
                            <div className='mb-1'>
                              <span
                                className='fw-boldest cursor-pointer text-hover-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#modal_smartsheet_text_message_summary_attachments'
                                onClick={() => {
                                  setAttachments(r?.attachments)
                                }}
                              >
                                {r?.title}
                              </span>
                            </div>
                            <div className='fs-0 fw-bold'>
                              {r?.jobCode && r?.jobCode.split('_')[0]}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  :
                    <div className='fw-bold mt-8'>No recent campaigns.</div>
                }
              </div>
            </div>
        }
      </div>

      <AttachmentsModal widget='smartsheet_text_message_summary' attachments={attachments} />
    </div>
  )
}

export default SmartsheetSummaryTextMessageWidget
