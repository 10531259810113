import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { fetchInvitation } from '../../helpers/remoteData'
import { editThreeColumn, getPageById, removeWidgetFromPage } from '../../helpers/remoteData'
import AddWidgetModel from './AddWidget/AddWidgetModel'
import UploadMediaModal from './UploadMediaModal'
import SmarsheetDigitalAdWidget from './SmartsheetDigitalAdWidget'
import SmartsheetDirectMailWidget from './SmartsheetDirectMailWidget'
import SmartsheetBillboardWidget from './SmartsheetBillboardWidget'
import SmartsheetTVWidget from './SmartsheetTVWidget'
import SmartsheetPrintWidget from './SmartsheetPrintWidget'
import TotalSpendWidget from './TotalSpendWidget'
import PageFilterWidget from './PageFilterWidget'
import MocentricDigitalWidget from './MocentricDigitalWidget'
import SmartsheetTextMessageWidget from './SmartsheetTextMessageWidget'
import InstagramPaidWidget from './InstagramPaidWidget'
import FacebookPaidWidget from './FacebookPaidWidget'
import InstagramSummaryPaidWidget from './InstagramSummaryPaidWidget'
import FacebookSummaryPaidWidget from './FacebookSummaryPaidWidget'
import SmartsheetSummaryDirectMailWidget from './SmartsheetSummaryDirectMailWidget'
import SmartsheetSummaryPrintWidget from './SmartsheetSummaryPrintWidget'
import SmartsheetSummaryBillboardWidget from './SmartsheetSummaryBillboardWdget'
import SmartsheetSummaryTVWidget from './SmartsheetSummaryTVWidget'
import SmartsheetSummaryTextMessageWidget from './SmartsheetSummaryTextMessageWidget'
import MocentricSummaryDigitalWidget from './MocentricSummaryDigitalWidget'
import WelcomeWidget from './WelcomeWidget'
import FacebookOrganicWidget from './FacebookOrganicWidget'
import InstagramOrganicWidget from './InstagramOrganicWidget'
import FacebookSummaryOrganicWidget from './FacebookSummaryOrganicWidget'
import InstagramSummaryOrganicWidget from './InstagramSummaryOrganicWidget'
import MocentricCTRAverageWidget from './MocentricCTRAverageWidget'
import SmartsheetSurveyWidget from './SmartsheetSurveyWidget'
import SmartsheetSummarySurveyWidget from './SmartsheetSummarySurveyWidget'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

type CustomPageParams = {
  pageId: string
}

function CustomPage() {
  const isMounted = useRef(true)
  let { pageId } = useParams<CustomPageParams>()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [, setLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState(null)
  const [pageDetails, setPageDetails] = useState({ name: '', widgets: [], id: '' })
  const [searchText, setSearchText] = useState('')
  const [currentCampaign, setCurrentCampaign] = useState('All')
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').toDate())
  const [endDate, setEndDate] = useState(moment().toDate())


  const fetchThisPage = useCallback(() => {
    setLoading(true)
    getPageById(pageId)
      .then((res: any) => {
        // @ts-ignore

        if (isMounted.current) {
          setPageDetails(res)
          setLoading(false)
        }
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [pageId])

  const fetchThisInvitation = useCallback(async () => {
    if (user.email) {
      const invitation = await fetchInvitation(user.email)
      
      if (invitation && isMounted.current) {
        setIsAdmin(invitation.isAdmin)
      }
    }
  }, [user.email])

  const moveUp = (el) => {
    // @ts-ignore
    const tempArray = [...pageDetails.widgets]
    // @ts-ignore
    const currentIndex = pageDetails?.widgets.indexOf(el)
    if (currentIndex > 0) {
      const tempElement = pageDetails?.widgets[currentIndex - 1]
      // @ts-ignore
      tempArray[currentIndex - 1] = el
      tempArray[currentIndex] = tempElement
      setPageDetails({ ...pageDetails, widgets: tempArray })
      editThreeColumn(pageId, tempArray)
        .then((r) => {
          fetchThisPage()
          toast.success('Widget order updated')
        })
        .catch((e) => {
          toast.error('Error updating Widget order')
        })
    }
  }

  const moveDown = (el) => {
    // @ts-ignore
    const tempArray = [...pageDetails.widgets]
    // @ts-ignore
    const currentIndex = pageDetails?.widgets.indexOf(el)
    if (currentIndex < tempArray.length - 1) {
      const tempElement = pageDetails?.widgets[currentIndex + 1]
      // @ts-ignore
      tempArray[currentIndex + 1] = el
      tempArray[currentIndex] = tempElement
      setPageDetails({ ...pageDetails, widgets: tempArray })
      editThreeColumn(pageId, tempArray)
        .then((r) => {
          fetchThisPage()
          toast.success('Widget order updated')
        })
        .catch((e) => {
          toast.error('Error updating Widget order')
        })
    }
  }

  useEffect(() => {
    isMounted.current = true

    fetchThisPage()
    fetchThisInvitation()

    return () => {
      isMounted.current = false
    }
  }, [pageId, fetchThisPage, fetchThisInvitation])

  return (
    <div>
      <h1 className='display-6 mt-0 mb-8 fw-boldest'>
        <span>{pageDetails.name}</span>
      </h1>
      
      <div className='my-8 row'>
        {pageDetails?.widgets?.map((el: any, index) => (
          <React.Fragment key={index}>
            {el.type === 'Smartsheet Digital Ad Widget' && (
              <SmarsheetDigitalAdWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Direct Mail Widget' && (
              <SmartsheetDirectMailWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                isAdmin={isAdmin}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Print Widget' && (
              <SmartsheetPrintWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                isAdmin={isAdmin}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Billboard Widget' && (
              <SmartsheetBillboardWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                isAdmin={isAdmin}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Smartsheet TV Widget' && (
              <SmartsheetTVWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                isAdmin={isAdmin}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Total Spend Widget' && (
              <TotalSpendWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Page Filter Widget' && (
              <PageFilterWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                setSearchText={setSearchText}
                setCurrentCampaign={setCurrentCampaign}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                key={index}
              />
            )}
            {el.type === 'Mocentric Digital Widget' && (
              <MocentricDigitalWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                dataset={el.dataset}
                table={el.table}
                reference={el.reference}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Text Message Widget' && (
              <SmartsheetTextMessageWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                isAdmin={isAdmin}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Instagram Paid Widget' && (
              <InstagramPaidWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Facebook Paid Widget' && (
              <FacebookPaidWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Instagram Summary Paid Widget' && (
              <InstagramSummaryPaidWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Facebook Summary Paid Widget' && (
              <FacebookSummaryPaidWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Summary Direct Mail Widget' && (
              <SmartsheetSummaryDirectMailWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Summary Print Widget' && (
              <SmartsheetSummaryPrintWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Summary Billboard Widget' && (
              <SmartsheetSummaryBillboardWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Summary TV Widget' && (
              <SmartsheetSummaryTVWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Summary Text Message Widget' && (
              <SmartsheetSummaryTextMessageWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Mocentric Summary Digital Widget' && (
              <MocentricSummaryDigitalWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Welcome Widget' && (
              <WelcomeWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Facebook Organic Widget' && (
              <FacebookOrganicWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Instagram Organic Widget' && (
              <InstagramOrganicWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Facebook Summary Organic Widget' && (
              <FacebookSummaryOrganicWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Instagram Summary Organic Widget' && (
              <InstagramSummaryOrganicWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                accountId={el.accountId}
                accessToken={el.accessToken}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Mocentric CTR Average Widget' && (
              <MocentricCTRAverageWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                dataset={el.dataset}
                table={el.table}
                isAdmin={isAdmin}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Survey Widget' && (
              <SmartsheetSurveyWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                isAdmin={isAdmin}
                searchText={searchText}
                currentCampaign={currentCampaign}
                startDate={startDate}
                endDate={endDate}
                key={index}
              />
            )}
            {el.type === 'Smartsheet Summary Survey Widget' && (
              <SmartsheetSummarySurveyWidget
                moveUp={moveUp}
                moveDown={moveDown}
                setSelectedWidget={setSelectedWidget}
                el={el}
                reference={el.reference}
                pageUrl={el.pageUrl}
                isAdmin={isAdmin}
                key={index}
              />
            )}
          </React.Fragment>
        ))}

        <AddWidgetModel fetchThisPage={fetchThisPage} pageDetails={pageDetails} />
        <DeleteWidgetModel fetchThisPage={fetchThisPage} selectedWidget={selectedWidget} pageId={pageId} pageDetails={pageDetails} />
        <UploadMediaModal />
      </div>
      
      {
        isAdmin && (
          <div className='mb-8 border-dashed border-gray-500 rounded p-8 text-center'>
            <div className='fs-2 fw-bolder text-muted mb-5'>Insert Additional Widgets</div>
            <button
              type='button'
              className='btn btn-primary d-inline-flex align-items-center justify-content-center w-100'
              data-bs-toggle='modal'
              data-bs-target='#modal_add_widget_to_page'
            >
              <AddIcon className='me-2' />
              <span>Add Widget</span>
            </button>
          </div>
        )
      }
    </div>
  )
}

const DeleteWidgetModel = ({ selectedWidget, pageId, fetchThisPage, pageDetails }) => {
  const [loading, setLoading] = useState(false)

  const closeRef = useRef<HTMLDivElement>(null)

  const deleteThisWidget = async () => {
    try {
      setLoading(true)

      await removeWidgetFromPage(pageId, selectedWidget)

      fetchThisPage()
      setLoading(false)
      closeRef.current?.click()
    }
    catch (e) {
      toast.error('Remove widget error')
      setLoading(false)
    }
  }

  return (
    <div className='modal fade' id='modal_delete_widget' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Delete Widget From <span className='fw-boldest'>{pageDetails.name}</span></h2>
            <div id='close_modal_add_page' className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
              <CloseIcon />
            </div>
          </div>

          <div className='modal-body scroll-y py-8'>
            <div className='form-group'>
              <label className='d-flex align-items-center fs-6 fw-bolder mb-1'>
                Widget Name
              </label>
              <div>{selectedWidget?.type}</div>
            </div>
          </div>

          <div className='modal-footer'>
            <button
              type='button'
              className='btn btn-danger px-10 fw-bolder'
              disabled={loading}
              onClick={deleteThisWidget}
            >
              {!loading && <span className='indicator-label'>Delete</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <span className='spinner-border spinner-border-sm align-middle'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomPage
