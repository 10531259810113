import { useState, useEffect } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from 'moment'
import { getDataFromLocalBigQuery, getPaginatedDataFromWidgetRef } from '../../helpers/remoteData'

function MocentricDigitalWidget({ dataset, table, reference, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const [loading, setLoading] = useState(false)
  const [rowsLoading, setRowsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState<Array<any>>([])
  const [referenceRows, setReferenceRows] = useState<any[]>([])

  useEffect(() => {
    const _fetch = async () => {
      const _page = 0
      setPage(_page)

      setLoading(true)
      setRowsLoading(true)

      try {
        const _referenceRows = await getPaginatedDataFromWidgetRef(reference, [], [{ field: 'endDate', direction: 'desc' }])

        console.log('REF ROWS',  reference, _referenceRows)
        if (_referenceRows) {
          setReferenceRows(_referenceRows)
        }

        setLoading(false)
        setRowsLoading(false)
      }
      catch (e) {
        console.log('Mocentric Digital Error')
        
        setReferenceRows([])
        setLoading(false)
        setRowsLoading(false)
      }
    }

    _fetch()
  }, [dataset, table, reference])

  useEffect(() => {
    const _fetch = async () => {
      if (referenceRows.length > 0) {
        setLoading(true)
        setRowsLoading(true)

        try {
          const _referenceRowNames = referenceRows
            .filter((_r) => {
              if (_r.title) {
                return true
              }

              return false
            })
            .filter((_r, _i, _arr) => _arr.indexOf(_r) === _i)          
          const _dedupedReferenceRowNames = _referenceRowNames.map((_r) => `'${_r.title}'`).join(',')
          const _whereIn = `(${_dedupedReferenceRowNames})`
          console.log('ROW NAMES', _dedupedReferenceRowNames)

          const _rows = await getDataFromLocalBigQuery('digital', dataset, table, _whereIn, page)

          if (_rows) {
            console.log('ROWS', _rows)
            setRows(_rows.rows)
          }

          setLoading(false)
          setRowsLoading(false)
        }
        catch (e) {
          console.log('Mocentric Digital BigQuery Error')
        
          setRows([])
          setLoading(false)
          setRowsLoading(false)
        }
      }
    }

    _fetch()
  }, [referenceRows, dataset, table, page])

  const handleChangePage = async (newPage: number, isPrevious: boolean) => {
    setRowsLoading(true)

    // const _rows = await getDataFromLocalBigQuery('digital', dataset, table, newPage)

    // if (_rows) {
    //   setRows(_rows.rows)
    // }

    setRowsLoading(false)
    setPage(newPage)
  }

  return (
    <div className='col-12 mb-8 d-inline-block'>
      <div className='card shadow'>
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <>
              {
                isAdmin && (
                  <div className='text-end px-9 pt-5'>
                    <div className='card-toolbar'>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        onClick={() => moveUp(el)}
                      >
                        <ArrowUpwardIcon />
                      </button>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        onClick={() => moveDown(el)}
                      >
                        <ArrowDownwardIcon />
                      </button>
                      <button
                        type='button'
                        className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                        data-bs-toggle='modal'
                        data-bs-target='#modal_delete_widget'
                        onClick={() => setSelectedWidget(el)}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                )
              }

              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-2'>Digital Campaigns</span>
                </h3>
              </div>
              
              {
                rowsLoading ? 
                  <div className='card-body'>
                    <div className='text-center py-3'>
                      <div className='spinner-border spinner-border-sm' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </div>
                :
                  referenceRows.length > 0 ? 
                    <>
                      <div className='card-body py-3'>
                        <div className='table-responsive'>
                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                              <tr className='fw-bolder text-muted'>
                                <th className='min-w-150px'>Title</th>
                                <th>Job Code</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th className='text-end'>Impressions</th>
                                <th className='text-end'>Clicks</th>
                                <th className='text-end'>CTR</th>
                                <th className='text-end'>Spend</th>
                              </tr>
                            </thead>
                            
                            <tbody className='border-0'>
                              {
                                referenceRows.map((r: any, i: number) => {
                                  const _rowIndex = rows.map((__r) => __r.custom_id).indexOf(r?.title)
                                  console.log('ROW INDEX', _rowIndex)

                                  return (
                                    <tr key={`mocentric-digital-row-${i}`}>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.title}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.jobCode}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.startDate ? moment.unix(r?.startDate.seconds).format('MM/DD/YYYY') : 'No start date'}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.endDate ? moment.unix(r?.endDate.seconds).format('MM/DD/YYYY') : 'No end date'}
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {rows[_rowIndex] && rows[_rowIndex].sum_impressions ? rows[_rowIndex].sum_impressions.toLocaleString('en-US') : null}
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {rows[_rowIndex] && rows[_rowIndex].sum_clicks ? rows[_rowIndex].sum_clicks.toLocaleString('en-US') : null}
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {rows[_rowIndex] && rows[_rowIndex].avg_ctr ? rows[_rowIndex].avg_ctr.toLocaleString('en-US') : null}
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <div className='text-dark fw-bolder d-block fs-6'>
                                          {r?.spend ? r?.spend.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='card-footer d-flex align-items-center justify-content-between'>
                        <div className='text-muted mt-1 fw-bold fs-7'>
                          Page {page + 1}
                        </div>
                        <div className='text-end'>
                          {
                            page > 0 && (
                              <button type='button' className='btn btn-secondary btn-sm me-5 w-125px d-inline-flex align-items-center justify-content-center' onClick={() => handleChangePage(page - 1, true)}>
                                <ArrowBackIcon className='me-3' />
                                <span>
                                  Previous
                                </span>
                              </button>
                            )
                          }
                          {
                            rows.length >= 10 && (
                              <button type='button' className='btn btn-secondary btn-sm w-125px d-inline-flex align-items-center justify-content-center' onClick={() => handleChangePage(page + 1, false)}>
                                <span>
                                  Next
                                </span>
                                <ArrowForwardIcon className='ms-3' />
                              </button>
                            )
                          }
                        </div>
                      </div>
                    </>
                  :
                    <div className='card-body'>
                      <div className='mb-4'>No campaigns exist.</div>
                    </div>
              }
            </>
        }
      </div>
    </div>
  )
}

export default MocentricDigitalWidget
