import React, { useState, useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { getDataFromWidgetRef } from '../../helpers/remoteData'
import { RootState } from '../../../setup'
import { UserModel } from '../../modules/auth/models/UserModel'
import { useSelector, shallowEqual } from 'react-redux'
import { fetchInvitation } from '../../helpers/remoteData'


function TotalSpendWidget({ reference, setSelectedWidget, el, moveUp, moveDown, searchText, currentCampaign, startDate, endDate }) {
  const [loading, setLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [widgetData, setWidgetData] = useState(0)

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  useEffect(() => {
    const _fetch = (r) => {
      // @ts-ignore
      const _widgetData = r.reduce((a, b) => {
        const momentStartDate = moment(startDate)
        const momentEndDate = moment(endDate)

        if (b.spend && parseInt(b.spend) > 0 && (searchText === '' || (b.title && b.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1) || (b.campaignName && b.campaignName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)) && (currentCampaign.toLowerCase() === 'all' || (b.title && b.title.toLowerCase().indexOf(currentCampaign.toLowerCase()) > -1) || (b.campaignName && b.campaignName.toLowerCase().indexOf(currentCampaign.toLowerCase()) > -1)) && ((b.startDate && moment.unix(b.startDate.seconds).isBetween(momentStartDate, momentEndDate)) || (b.endDate && moment.unix(b.endDate.seconds).isBetween(momentStartDate, momentEndDate)) || (b.dropDate && moment.unix(b.dropDate.seconds).isBetween(momentStartDate, momentEndDate)))) {
          return a + b.spend
        }
        
        return a
      }, 0)
      setWidgetData(_widgetData)
      console.log('R TOTAL SPEND', _widgetData)
      MenuComponent.reinitialization()
      setLoading(false)
    }

    getDataFromWidgetRef(reference).then(_fetch)
  }, [reference, searchText, currentCampaign, startDate, endDate])

  useEffect(() => {
    const _fetch = async () => {
      if (user.email) {
        const invitation = await fetchInvitation(user.email)
        
        if (invitation) {
          setIsAdmin(invitation.isAdmin)
        }
      }
    }

    _fetch()
  }, [user])

  return (
    <div className='col-12 col-sm-4 mb-8 d-inline-block'>
      <div className=''>
        <div className=''>
          <div className='card shadow bg-primary'>
            {
              isAdmin ? 
                <div className='card-header border-0'>
                  <div className='w-100 text-end'>
                    <button
                      type='button'
                      className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
                      onClick={() => moveDown(el)}
                    >
                      <KeyboardArrowDownIcon />
                    </button>
                    <button
                      type='button'
                      className='btn btn-secondary btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
                      onClick={() => moveUp(el)}
                    >
                      <KeyboardArrowUpIcon />
                    </button>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm d-inline-flex align-items-center justify-content-between mx-1 my-5'
                      data-bs-toggle='modal'
                      data-bs-target='#modal_delete_widget'
                      onClick={() => setSelectedWidget(el)}
                    >
                      <DeleteIcon className='text-white' />
                    </button>
                  </div>
                </div>
              :
                null
            }
            <div className='card-body'>
              {
                loading ? 
                  <div className='text-center'>
                    <div className="spinner-border spinner-border-sm text-white" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                :
                  <>
                    <h1 className='display-5 fw-boldest text-center text-white'>${widgetData.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h1>
                    <h2 className='mb-0 text-center text-white fw-boldest'>Total Spend</h2>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalSpendWidget
