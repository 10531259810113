import React from 'react'
import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  onClick: (c: string) => void
  icon?: string
  fontIcon?: string
  className?: string
}

const AsideClientItemWithSub = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(({
  children,
  title,
  onClick,
  icon,
  fontIcon,
  className
}, ref) => {
  const {config} = useLayout()
  const {aside} = config

  return (
    <div
      className={`client-menu-main menu-item ${className}`}
      ref={ref}
    >
      <span className='menu-link' onClick={() => onClick(title)}>
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title fw-bolder text-uppercase' style={{userSelect: 'none'}}>{title}</span>
      </span>
      <div className='menu-sub menu-sub-accordion'>
        {children}
      </div>
    </div>
  )
})

export {AsideClientItemWithSub}
