import React, {FC, useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../modules/auth/models/UserModel'
import {RootState} from '../../../setup'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {SimpleFacebookCampaign} from '../facebook/FacebookWrapper'
import {Dropdown1} from '../../../_metronic/partials'
import {KTSVG} from '../../../_metronic/helpers'
import {AddClientWizard} from '../../modules/wizards/AddClientWizard'

export interface CampaignStat {
    aboveClicks: number
    averageClicks: number
    belowClicks: number
    aboveImpressions: number
    averageImpressions: number
    belowImpressions: number
    aboveCtr: number
    averageCtr: number
    belowCtr: number
}

export interface Universe {
    name?: string
    universe?: string
    sum_impressions?: number
    sum_clicks?: number
    avg_ctr?: number
}

export interface PageCampaign {
    name?: string
    impressions?: number
    clicks?: number
    ctr?: number
    universe?: string
    ad?: Array<string>
    date_string?: string
    id?: string
    doc?: any
    createdAt?: number
    updatedAt?: number
    integration_id?: string
}

const CreatePageWrapper: FC = () => {
    const intl = useIntl()
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const { setCurrentPage } = useContext(RemoteDataContext)
    const [archived, setArchived] = useState<boolean>(false)
    const [archivedObj, setArchivedObj] = useState<SimpleFacebookCampaign>({
        id: 'Active',
        name: 'Active'
    })

    return (
        <>
            <div className='row gy-5 g-xl-8'>
                <div className="col-12 my-8">
                    <div className="card shadow">
                        <div className="card-body d-flex flex-wrap justify-content-between align-items-center">
                            <h4 className="m-0 fs-3">
                                Create a Page
                            </h4>

                            <div>
                                <button
                                    type='button'
                                    className='btn btn-icon btn-color-primary btn-active-light-primary w-initial p-3 px-4 me-5'
                                    style={{width: 'initial'}}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 me-3' />
                                    {archivedObj.name}
                                </button>
                                <Dropdown1 campaigns={[
                                    {
                                        id: 'Active',
                                        name: 'Active'
                                    },
                                    {
                                        id: 'Archived',
                                        name: 'Archived'
                                    }
                                ]} loading={false} setCampaign={(obj) => {
                                    setArchivedObj(obj)
                                    setArchived(obj.name === 'Archived' ? true : false)
                                }} campaign={archivedObj} />
                                {
                                    user.isAdmin ?
                                    <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_page_campaign" onClick={() => {
                                        setCurrentPage({})
                                    }}>
                                        Create a Page
                                    </button>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CREATE_PAGE'})}</PageTitle>
        </>
    )
}

export {CreatePageWrapper}
