import React, { Suspense, lazy, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { CreatePageWrapper } from '../pages/ClientPage/CreatePageWrapper'
import { DigitalWrapper } from '../pages/digital/DigitalWrapper'
import { FacebookWrapper } from '../pages/facebook/FacebookWrapper'
import { CableWrapper } from '../pages/cable/CableWrapper'
import { BroadcastWrapper } from '../pages/broadcast/BroadcastWrapper'
import { MailWrapper } from '../pages/mail/MailWrapper'
import { AdminWrapper } from '../pages/admin/AdminWrapper'
import { ManageClientsWrapper } from '../pages/admin/ManageClientsWrapper'
import { NewPassword } from '../modules/auth/components/NewPassword'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { UserModel } from '../modules/auth/models/UserModel'
import { RootState } from '../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import CustomPage from '../pages/CustomPage/CustomPage'
import {ManagePages} from '../pages/ManagePages/ManagePages'
import { ClientContext } from '../providers/ClientProvider'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  const { client } = useContext(ClientContext)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <MasterLayout>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path='/create-password' component={NewPassword} />
            {user.hasPassword && <Redirect to='/create-password' />}
            
            <Route path='/dashboard' component={DashboardWrapper} />
            <Route path='/page' component={CreatePageWrapper} />
            <Route path='/custom-page/:pageId' component={CustomPage} />
            <Route path='/digital' component={DigitalWrapper} />
            <Route path='/facebook' component={FacebookWrapper} />
            <Route path='/cable' component={CableWrapper} />
            <Route path='/broadcast' component={BroadcastWrapper} />
            <Route path='/mail' component={MailWrapper} />

            <Route path='/invitations' component={AdminWrapper} />

            <Route path='/clients/:clientId/pages' component={ManagePages} />
            <Route path='/clients' component={ManageClientsWrapper} />

            <Route path='/builder' component={BuilderPageWrapper} />
            <Route path='/crafted/pages/profile' component={ProfilePage} />
            <Route path='/crafted/pages/wizards' component={WizardsPage} />
            <Route path='/crafted/widgets' component={WidgetsPage} />
            <Route path='/crafted/account' component={AccountPage} />
            <Route path='/apps/chat' component={ChatPage} />
            <Route path='/menu-test' component={MenuTestPage} />

            <Redirect from='/auth' to={client?.pages?.length > 0 ? `/custom-page/${client?.pages[0].id}` : '/'} />
            <Redirect exact from='/' to={client?.pages?.length > 0 ? `/custom-page/${client?.pages[0].id}` : '/'} />
            <Redirect to='error/404' />
          </Switch>
        </Suspense>
      </MasterLayout>
    </>
  )
}
