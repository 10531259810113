/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {SimpleFacebookCampaign} from '../../../../app/pages/facebook/FacebookWrapper'

type Props = {
  campaigns?: Array<SimpleFacebookCampaign>,
  loading?: boolean,
  setCampaign?: (c: SimpleFacebookCampaign) => void
  campaign?: SimpleFacebookCampaign
}

const Dropdown1: FC<Props> = ({campaigns = [], loading = false, setCampaign = () => {}, campaign = {}}) => {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px pb-5' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200 mb-5'></div>

      {
        campaigns.map((c, i) => <p onClick={() => setCampaign(c)} key={`simple-campaign-choice-${i}`} className='px-7 py-5 text-hover-active m-0' style={campaign && campaign.name === c.name ? { fontWeight: 700, cursor: 'pointer', userSelect: 'none' } : { cursor: 'pointer', userSelect: 'none' }}>{c.name}</p>)
      }      
    </div>
  )
}

export {Dropdown1}
