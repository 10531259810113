import React, { FC, createContext, useState, useCallback } from 'react'
import {DigitalCampaign} from '../pages/digital/DigitalWrapper'
import {PageCampaign } from '../pages/ClientPage/CreatePageWrapper'
import {CableCampaign} from '../pages/cable/CableWrapper'
import {BroadcastCampaign} from '../pages/broadcast/BroadcastWrapper'
import {MailCampaign} from '../pages/mail/MailWrapper'
import {Client} from '../pages/admin/ManageClientsWrapper'

interface EditUser {
    email?: string
    displayName?: string
    isAdmin?: boolean
}

interface IRemoteData {
    digitals: Array<DigitalCampaign>
    cables: Array<CableCampaign>
    broadcasts: Array<BroadcastCampaign>
    mails: Array<MailCampaign>
    pages: Array<PageCampaign>
    setDigitals: (d: DigitalCampaign[]) => void
    setCables: (c: CableCampaign[]) => void
    setBroadcasts: (c: BroadcastCampaign[]) => void
    setMails: (m: MailCampaign[]) => void
    setPages: (p: PageCampaign[]) => void
    currentDigital: DigitalCampaign
    currentCable: CableCampaign
    currentBroadcast: BroadcastCampaign
    currentMail: MailCampaign
    currentPage: PageCampaign
    setCurrentDigital: (d: DigitalCampaign) => void
    setCurrentCable: (c: CableCampaign) => void
    setCurrentBroadcast: (c: BroadcastCampaign) => void
    setCurrentMail: (m: MailCampaign) => void
    setCurrentPage: (p:PageCampaign) => void
    currentUser: EditUser
    setCurrentUser: (u: EditUser) => void
    currentClient: Client
    setCurrentClient: (c: Client) => void
}

export const RemoteDataContext = createContext<IRemoteData>({
    digitals: [],
    setDigitals: (d: DigitalCampaign[]) => {},
    cables: [],
    setCables: (c: CableCampaign[]) => {},
    broadcasts: [],
    setBroadcasts: (c: BroadcastCampaign[]) => {},
    mails: [],
    setMails: (m: MailCampaign[]) => {},
    pages: [],
    setPages:(p: PageCampaign[]) => {},
    currentDigital: {},
    setCurrentDigital: (d: DigitalCampaign) => {},
    currentCable: {},
    setCurrentCable: (c: CableCampaign) => {},
    currentBroadcast: {},
    setCurrentBroadcast: (c: BroadcastCampaign) => {},
    currentMail: {},
    setCurrentMail: (m: MailCampaign) => {},
    currentPage: {},
    setCurrentPage: (p: PageCampaign) => {},
    currentUser: {},
    setCurrentUser: (u: EditUser) => {},
    currentClient: {
        name: '',
        logoUrl: ''
    },
    setCurrentClient: (c: Client) => {}
})

export const RemoteDataProvider: FC = ({ children }) => {
    const [digitals, _setDigitals] = useState<DigitalCampaign[]>([])
    const [cables, _setCables] = useState<CableCampaign[]>([])
    const [broadcasts, _setBroadcasts] = useState<BroadcastCampaign[]>([])
    const [mails, _setMails] = useState<MailCampaign[]>([])
    const [pages, _setPages] = useState<PageCampaign[]>([])
    const [currentDigital, _setCurrentDigital] = useState({})
    const [currentPage, _setCurrentPage] = useState({})
    const [currentCable, _setCurrentCable] = useState({
        project_name: '',
        spots: 0,
        area_description: '',
        media: [],
        program_name: '',
        date_string: ''
    })            
    const [currentBroadcast, _setCurrentBroadcast] = useState({})
    const [currentMail, _setCurrentMail] = useState({})
    const [currentUser, _setCurrentUser] = useState({})
    const [currentClient, _setCurrentClient] = useState<Client>({
        name: '',
        logoUrl: ''
    })

    const setDigitals = useCallback((d) => _setDigitals(d), [_setDigitals])
    const setPages = useCallback((p) => _setPages(p), [_setPages])
    const setCables = useCallback((c) => _setCables(c), [_setCables])
    const setBroadcasts = useCallback((c) => _setBroadcasts(c), [_setBroadcasts])
    const setMails = useCallback((m) => _setMails(m), [_setMails])
    const setCurrentDigital = useCallback((d: DigitalCampaign) => _setCurrentDigital(d), [_setCurrentDigital])
    const setCurrentPage = useCallback((p: PageCampaign) => _setCurrentPage(p), [_setCurrentPage])
    const setCurrentCable = useCallback((c: any) => _setCurrentCable(c), [_setCurrentCable])
    const setCurrentBroadcast = useCallback((c: any) => _setCurrentBroadcast(c), [_setCurrentBroadcast])
    const setCurrentMail = useCallback((m: MailCampaign) => _setCurrentMail(m), [_setCurrentMail])
    const setCurrentUser = useCallback((u: EditUser) => _setCurrentUser(u), [_setCurrentUser])
    const setCurrentClient = useCallback((c: Client) => _setCurrentClient(c), [_setCurrentClient])

    return (
        <RemoteDataContext.Provider value={{ digitals, setDigitals, cables, setCables, broadcasts, setBroadcasts, currentBroadcast, setCurrentBroadcast, mails, setMails, pages, setPages, currentPage, setCurrentPage ,currentDigital, setCurrentDigital, currentCable, setCurrentCable, currentMail, setCurrentMail, currentUser, setCurrentUser, currentClient, setCurrentClient }}>
            {children}
        </RemoteDataContext.Provider>
    )
}
