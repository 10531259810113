/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import { ClientContext } from '../../../../app/providers/ClientProvider'
import AddIcon from '@mui/icons-material/Add'

type Props = {
  isAdmin: boolean
}

const AsideDefault: FC<Props> = ({isAdmin}) => {
  const { config, classes } = useLayout()
  const { aside } = config
  const { client } = useContext(ClientContext)

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div
        className='aside-logo flex-column-auto'
        id='kt_aside_logo'
        style={{ justifyContent: 'space-between' }}
      >
        <div className='logo'>
          <Link to={client?.pages?.length > 0 ? `/custom-page/${client?.pages[0].id}` : '/'}>
            <div className='d-flex align-items-center'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/Mighty-M-Logo.png')}
                className='h-40px'
              />
              <div className='text-white text-uppercase fw-bolder ms-5'>
                Dashboard
              </div>
            </div>
          </Link>
        </div>
        
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
      </div>

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>

      {client.id && isAdmin && (
        <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
          <button
            data-bs-toggle='modal'
            data-bs-target='#modal_add_page_to_client'
            className='btn btn-primary w-100 d-flex align-items-center justify-content-center'
            title='Check out the complete documentation with over 100 components'
          >
            <AddIcon className='me-2' />
            <span>Add Page</span>
          </button>
        </div>
      )}
    </div>
  )
}

export { AsideDefault }
