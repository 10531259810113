import React, {FC,useState,useEffect,useContext} from 'react'
import {fetchProjects} from '../../../app/helpers/remoteData'
import {MailCampaign} from './MailWrapper'
import {Stat} from './Stat'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {ClientContext} from '../../providers/ClientProvider'

type Props = {
    archived: boolean
}

const Stats: FC<Props> = ({archived}) => {
    const { setMails, mails } = useContext(RemoteDataContext)
    const { client } = useContext(ClientContext)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const fetchMailProjects = async () => {
            if (client.mail) {
                setIsLoading(true)
                const _projects: MailCampaign[] = await fetchProjects(client.mail, archived)
                console.log('PROJECTS', _projects)

                setMails(_projects)
                setIsLoading(false)
            }
        }
        fetchMailProjects()
    }, [setMails, client.mail, archived])

    return (
        <div className='row gy-5 g-xl-8'>
            {
                isLoading ? 
                <div className="text-center pt-5">
                    <p>Ads are loading...</p>
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <>
                    {
                        mails.length > 0 ?
                            <>
                                {
                                    mails.map((x, i) => <Stat key={`stat-${i}`} data={x} />)
                                }
                            </>
                        :
                        null
                    }
                </>
            }
        </div>
    )
}

export {Stats}
