import { useState, useEffect, useContext } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import { getTopThreeFromWidgetRef } from '../../helpers/remoteData'
import { ClientContext } from '../../providers/ClientProvider'
import AttachmentsModal from './components/AttachmentsModal'

function SmartsheetSummaryBillboardWidget({ reference, pageUrl, isAdmin, setSelectedWidget, el, moveUp, moveDown }) {
  const { client } = useContext(ClientContext)
  
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<Array<any>>([])
  const [attachments, setAttachments] = useState<Array<any>>([])

  useEffect(() => {
    const _fetch = async () => {
      setLoading(true)

      try {
        const _rows = await getTopThreeFromWidgetRef(reference, 'startDate')

        if (_rows) {
          setRows(_rows)
        }

        setLoading(false)
      }
      catch (e) {
        console.log('Billboard Error')
        
        setRows([])
        setLoading(false)
      }
    }

    _fetch()
  }, [reference])

  return (
    <div className='col-12 col-md-6 col-xl-4 mb-8 d-inline-block'>
      <div className='card shadow h-100'>
        {
          isAdmin && (
            <div className='card-header justify-content-end'>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveUp(el)}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                onClick={() => moveDown(el)}
              >
                <ArrowDownwardIcon />
              </button>
              <button
                type='button'
                className='btn btn-link btn-sm btn-color-muted btn-color-active-muted d-inline-flex align-items-center justify-content-between mx-2 my-1'
                data-bs-toggle='modal'
                data-bs-target='#modal_delete_widget'
                onClick={() => setSelectedWidget(el)}
              >
                <DeleteIcon />
              </button>
            </div>
          )
        }
        {
          loading ? 
            <div className='card-body text-center'>
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          :
            <div className='card-body'>
              <h4 className='d-flex align-items-center mb-0 fw-boldest'>
                <span className='me-3'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_1464_320)">
                      <path d="M22.695 4.755H20.43H19.98V3.735H21.78C22.065 3.735 22.305 3.495 22.305 3.21V1.275C22.29 0.99 22.05 0.75 21.765 0.75H19.5H19.275H17.01C16.725 0.75 16.485 0.99 16.47 1.275V3.21C16.47 3.495 16.71 3.735 16.995 3.735H17.655H18.78V4.755H12.675V3.735H14.4C14.685 3.735 14.925 3.495 14.925 3.21V1.275C14.895 0.99 14.67 0.75 14.37 0.75H12.12H11.895H9.63002C9.34502 0.75 9.10502 0.99 9.09002 1.275V3.21C9.09002 3.495 9.33002 3.735 9.61502 3.735H10.275H11.475V4.755H5.26502V3.735H7.05002C7.33502 3.735 7.57502 3.495 7.57502 3.21V1.275C7.56002 0.99 7.32002 0.75 7.03503 0.75H4.77002H4.54502H2.28002C1.99502 0.75 1.75502 0.99 1.74002 1.275V3.21C1.74002 3.495 1.98002 3.735 2.26502 3.735H2.92502H4.06502V4.755H3.70502H1.44002C1.15502 4.755 0.915024 4.995 0.900024 5.28V14.715C0.900024 15 1.14002 15.24 1.42502 15.24H5.41502V23.94H6.61502V15.24H8.08502H16.05H17.43V23.94H18.63V15.24H22.71C22.995 15.24 23.235 15 23.235 14.715V5.28C23.22 4.995 22.98 4.755 22.695 4.755ZM17.535 2.67V1.8H18.975H19.8H21.24V2.67H18.165H19.11H17.535ZM10.14 2.67V1.8H11.58H12.405H13.845V2.67H10.77H11.715H10.14ZM2.80502 2.67V1.8H4.24502H5.07002H6.51003V2.67H3.43502H4.38002H2.80502ZM22.17 14.175H8.59502H15.54H1.96502V5.805H4.23002H19.905H22.17V14.175Z" fill={client?.defaultColor ? client.defaultColor : '#F288B7'}/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1464_320">
                        <rect width="24" height="24" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                {
                  pageUrl ? 
                    <Link to={pageUrl} className='text-dark'>Latest Billboards</Link>
                  :
                    <span>Latest Billboards</span>
                }
              </h4>

              <div>
                {
                  rows.length > 0 ?
                    rows.map((r, i) => {
                      return (
                        <div key={`smartsheet-billboard-summary-${i}`} className='mt-8 d-flex align-items-center'>
                          <div className='ms-4'>
                            <div className='mb-1'>
                              <span
                                className='fw-boldest cursor-pointer text-hover-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#modal_smartsheet_billboard_summary_attachments'
                                onClick={() => {
                                  setAttachments(r?.attachments)
                                }}
                              >
                                {r?.campaignName}
                              </span>
                            </div>
                            <div className='fs-0 fw-bold'>
                              {r?.jobCode && r?.jobCode.split('_')[0]}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  :
                    <div className='fw-bold mt-8'>No recent campaigns.</div>
                }
              </div>
            </div>
        }
      </div>

      <AttachmentsModal widget='smartsheet_billboard_summary' attachments={attachments} />
    </div>
  )
}

export default SmartsheetSummaryBillboardWidget
