/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC,useState,useContext,useEffect,useRef} from 'react'
import {RemoteDataContext} from '../../providers/RemoteDataProvider'
import {NotificationContext} from '../../providers/NotificationProvider'
import {ClientContext} from '../../providers/ClientProvider'
import {MailCampaign} from '../../pages/mail/MailWrapper'
import {deleteProject} from '../../helpers/remoteData'

const DeleteMailModal: FC = () => {
    const { setText } = useContext(NotificationContext)
    const { currentMail, setMails, mails } = useContext(RemoteDataContext)
    const { client } = useContext(ClientContext)
    const [mail, setMail] = useState<MailCampaign>({})
    const [loading, setLoading] = useState<boolean>(false)
    const closeRef = useRef<HTMLDivElement>(null)

    const _deleteProject = async () => {
        console.log('DELETE CAB', mail)
        if (mail.id && client.mail) {
            setLoading(true)
            const index = mails.map((m) => m.id).indexOf(mail.id)
            await deleteProject(client.mail, mail.id)

            let _mails: any[] = Array.from(mails)
            delete _mails[index]
            setMails(_mails)
            setText('Project deleted!')
            setLoading(false)
            closeRef.current?.click()
        }
    }

    useEffect(() => {
        if (Object.keys(currentMail).length > 0) {
            setMail(currentMail)
        }
    }, [currentMail])

    return (
        <div className='modal fade' id='kt_modal_delete_mail_campaign' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' ref={closeRef}>
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Delete Mail Campaign</h1>
                        </div>

                        <p>Are you sure you want to delete this project? This action cannot be undone.</p>

                        <div className="text-center pt-15">
                            <button data-bs-dismiss='modal' className="btn btn-light me-3">Cancel</button>
                            <button className="btn btn-primary" onClick={() => {
                                console.log('CLICKED')
                                _deleteProject()
                            }}>
                                {
                                    !loading ? 
                                    <span className="indicator-label">Delete</span>
                                    :
                                    <span className="indicator-progress" style={{display: 'block'}}>
                                        Please wait... 
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {DeleteMailModal}
