import React, {useState,useContext,useEffect} from 'react'
import {Link,useLocation,useHistory} from 'react-router-dom'
import {resetPassword} from '../../../helpers/remoteData'
import {NotificationContext} from '../../../providers/NotificationProvider'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export function ResetPassword() {
    const history = useHistory()
    const query: any = useQuery()
    const _oobCode = query.get('oobCode')
    const [error, setError] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    // const [codeLoading, setCodeLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const { setText } = useContext(NotificationContext)

    const _resetPassword = async () => {
        if (password !== '' && passwordConfirmation !== '' && password === passwordConfirmation && email !== '' && _oobCode) {
            setLoading(true)
            await resetPassword(_oobCode, password)

            setText('Password reset! Login to continue.')
            setLoading(false)
            history.push('/auth/login')
        }
    }

    useEffect(() => {
        console.log('OOB', _oobCode)
        if (_oobCode) {
            const _verifyOob = async () => {
                // setCodeLoading(true)
                const responseData = await resetPassword(_oobCode)
                if (responseData.email) {
                    setEmail(responseData.email)
                }
                else if (responseData.error) {
                    console.log('query.oobCode could not be verified.', responseData)
                    setError(responseData.error)
                }
                // setCodeLoading(false)
            }
            _verifyOob()
        }
    }, [_oobCode])

    return (
        <>
            <div
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            id='kt_login_password_reset_form'
            >
                {
                    error ? 
                    <>
                        <div className='text-center mb-10'>
                            <h1 className='text-dark mb-3'>Reset Link Invalid</h1>
                            <div className='text-gray-400 fw-bold fs-4'>Your reset link is invalid or expired. Please request a new link through the Forgot Password form.</div>
                        </div>
                    </>
                    :
                    <>
                        {
                            _oobCode ? 
                                <>
                                    <div className='text-center mb-10'>
                                        <h1 className='text-dark mb-3'>Reset Password</h1>
                                        <div className='text-gray-400 fw-bold fs-4'>Enter your new password</div>
                                    </div>

                                    <div className='fv-row mb-10'>
                                        <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                                        <input
                                        type='password'
                                        placeholder=''
                                        autoComplete='off'
                                        className="form-control form-control-lg form-control-solid"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        />
                                    </div>

                                    <div className='fv-row mb-10'>
                                        <label className='form-label fw-bolder text-gray-900 fs-6'>Password Confirmation</label>
                                        <input
                                        type='password'
                                        placeholder=''
                                        autoComplete='off'
                                        className="form-control form-control-lg form-control-solid"
                                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                                        value={passwordConfirmation}
                                        />
                                    </div>

                                    <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                                        <button
                                        type='submit'
                                        id='kt_password_reset_submit'
                                        className='btn btn-lg btn-primary fw-bolder me-4'
                                        onClick={() => _resetPassword()}
                                        >
                                        {
                                            loading ? 
                                            <span className='indicator-progress d-block'>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                            :
                                            <span className='indicator-label'>Submit</span>
                                        }
                                        </button>
                                        <Link to='/auth/login'>
                                        <button
                                            type='button'
                                            id='kt_login_password_reset_form_cancel_button'
                                            className='btn btn-lg btn-light-primary fw-bolder'
                                        >
                                            Cancel
                                        </button>
                                        </Link>
                                    </div>
                                </>
                            :
                                <div className='text-center mb-10'>
                                    <h1 className='text-dark mb-3'>Missing Reset Code</h1>
                                    <div className='text-gray-400 fw-bold fs-4'>The URL you entered is missing a password reset code.</div>
                                </div>
                        }
                    </>
                }
            </div>
        </>
    )
}
