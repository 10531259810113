import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'
import CloseIcon from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'
import { uploadMedia } from '../../helpers/remoteData'


function UploadMediaModal () {
    const fileRef = useRef<any>()
    const [loading, setLoading] = useState(false)
    const [files, setFiles] = useState<any>([])
    const [progress, setProgress] = useState<number[]>([])

    const setProgressAtIndex = (value, index) => {
      console.log('setting progress', index, value)
      let newProgress = [...progress]
      newProgress[index] = value
      setProgress(newProgress)
    }

    const upload = async () => {
      setLoading(true)

      for (let i = 0; i < files.length; i++) {
        await uploadMedia(files[i], setProgressAtIndex, i)
      }

      setLoading(false)
      setFiles([])
      setProgress([])
      fileRef.current.value = ''
      toast.success('Files uploaded successfully!')
    }

    return (
      <div className='modal fade' id='modal_upload_media' aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered mw-700px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2>Upload Media</h2>
              <div
                id='close-modal_remove_widget_from_page'
                className='btn btn-sm btn-icon btn-active-color-primary'
                data-bs-dismiss='modal'
              >
                <CloseIcon />
              </div>
            </div>
            <div className='mx-20 my-10'>
              <label className='fs-5 fw-bold mb-2'>Choose Media</label>
              <input type='file' className='form-control media-file-input' ref={fileRef} multiple onChange={(e) => {
                setFiles((existing) => [...existing, ...Array.from(e.target.files || [])])

                let _progress: number[] = []
                let _length: number = e.target.files?.length || 0
                for (let i = 0; i < _length; i++) {
                  _progress.push(0)
                }

                setProgress(_progress)
              }} />
              <label className='fs-5 fw-bold mt-6 mb-2'>Files</label>
              {
                files.map((f, i) => (
                  <div key={`media-upload-${i}`} className='mb-5'>
                    <p>{f.name}</p>
                    <LinearProgress variant="determinate" color="success" value={progress[i]} />
                  </div>
                ))
              }
            </div>
            <div className='mx-20 my-10'>
              <div className='text-end'>
                <button type='button' data-bs-dismiss='modal' className='btn btn-secondary me-4' disabled={loading}>
                  Close
                </button>
                <button type='button' className='btn btn-primary' onClick={() => upload()} disabled={loading}>
                  {
                    !loading ? 
                      'Begin Upload'
                    :
                      <div className='text-center'>
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default UploadMediaModal
