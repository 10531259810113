import { useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../../helpers/remoteData'
import { useDispatch } from 'react-redux'
import { actions } from './AuthRedux'
import { FirebaseUserModel } from '../models/UserModel'
import { firebaseUserMapper } from '../../../../_metronic/mapper/firebaseUserMapper'

export function FirebaseInitAuth({ children }) {
  const dispatch = useDispatch()
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const mappedUser: FirebaseUserModel = firebaseUserMapper(user)
        dispatch(actions.fulfillUser(mappedUser))
        dispatch(actions.getDBUser())
      } else {
        dispatch(actions.logout())
      }
    })
  }, [dispatch])

  return <>{children}</>
}
