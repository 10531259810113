/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { ClientContext } from '../../../../app/providers/ClientProvider'
import { AsideClientItemWithSub } from './AsideClientItemWithSub'
import { fetchClient, fetchInvitation } from '../../../../app/helpers/remoteData'


export function AsideMenuMain() {
  const history = useHistory()
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { clients, client, setClient } = useContext(ClientContext)
  const [invitation, setInvitation] = useState<any>({})

  const updateClient = async (c: string) => {
    if (c !== '') {
      const index = clients.map((_c) => _c.name).indexOf(c)
      const _currentClient = clients[index]

      console.log('Current Client', _currentClient)
      const _client: any = await fetchClient(_currentClient.id)

      console.log('_CLIENT', _client)
      setClient(_client)
      localStorage.setItem('currentClientName', JSON.stringify(_currentClient))
      history.push(_client?.pages?.length > 0 ? `/custom-page/${_client?.pages[0].id}` : '/')
    }
  }

  useEffect(() => {
    let isMounted = true

    const _fetch = async () => {
      if (user.email !== '') {
        try {
          const _invitation = await fetchInvitation(user.email)

          if (isMounted) {
            setInvitation(_invitation)
          }
        }
        catch (e) {
          console.log('MENU ERROR', e)
        }
      }
    }
    _fetch()

    return () => {
      isMounted = false
    }
  }, [user.email])

  return (
    <>
      <div className='pb-8'>
        {
          clients.map((c, i) => (
            <AsideClientItemWithSub
              key={`client-item-with-sub-${i}`}
              to='/expand-client'
              title={c.name}
              className={client.id === c.id ? 'here show' : ''}
              onClick={updateClient}
            >
              {client?.pages?.map((page, index) => (
                <AsideMenuItem
                  key={`pages_${index}`}
                  to={`/custom-page/${page.id}`}
                  title={page?.name}
                  icon='/media/icons/duotune/files/fil003.svg'
                  fontIcon='bi-app-indicator'
                />
              ))}
            </AsideClientItemWithSub>
          ))
        }
      </div>

      {invitation.isAdmin ? (
        <>
          <AsideMenuItem
            to='/invitations'
            title={intl.formatMessage({ id: 'MENU.ADMIN' })}
            className='text-uppercase'
          />
          <AsideMenuItem
            to='/clients'
            title={intl.formatMessage({ id: 'MENU.MANAGE_CLIENTS' })}
            className='text-uppercase'
          />
        </>
      ) : null}
    </>
  )
}
