import axios from 'axios'

export async function submitContactForm(formData) {
  return new Promise<boolean>(async (resolve, reject) => {
    await axios
      .create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
      .post('https://hooks.zapier.com/hooks/catch/6986406/bemahcy/', formData)
      .then(({ data }) => {
        resolve(true)
      })
      .catch((err) => {
        console.log(err)
        reject('Unknown Error')
      })
  })
}
